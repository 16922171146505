import React,{useEffect,useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Avatar, Grid, Typography, Icon, Slide, ButtonGroup } from '@material-ui/core';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { grey } from '@material-ui/core/colors';

import { connect } from "react-redux";
import { personalData, signUpDialog,restarState } from '../../actions/index';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme)=>({
  leftIconheader: {
    float:'letf',
    backgroundColor:'transparent'
  },
  rightIconHeader:{
    float:'right',
    
  },
  successorRejectIcon: {
    width:"100%",
    backgroundColor:'transparent',
    marginTop:60
  },
  titleHeader:{
    marginTop:60,
    fontSize:15,
    fontWeight:'bolder',
    textAlign:'center'
  },
  marginTextFieldCardNumber:{
    width:'100%',
    '& fieldset': {
      borderRadius: 0,
      fontSize:17
    },
    
  },
  codeConfirm:{
    fontWeight:'bolder',
    color:'gray',
    paddingTop:10
  },
  codeResend:{
    fontWeight:'bolder',
    color:'gray',
    float:'right'
  },

  successText:{
    textAlign:'center',
    color:'gray',
    paddingTop:10
  },
  container:{
    width:'100%',
    alignContent:'center',
    padding:theme.spacing(5,10),
    [theme.breakpoints.down('xs')]:{
      padding:theme.spacing(5,3),
    }
  },
  buttons:{
    width:'50%',
    border:'solid 1px'+grey[500],
    borderRadius:'10px',
   
    
  },

  
}))

const PayButton = withStyles((theme) => ({
  root: {
    marginTop:'40px',
    marginLeft:20,
    marginRight:20,
    marginBottom:20,
    width:'100%',
    height:'40px',
    fontSize:'15px',
    backgroundColor: '#6730e3',
    '&:hover': {
      backgroundColor:'#6730e3',
    },

  },
}))(Button);

 function SignupDialog({openDialog,status,token,OpenSignupDialogHandler,ChangeContext,RestartState}) {
  const classes = useStyles();
  
 
  
  const SendToConsole = () =>{

    if(token !== null || token !==""){
      window.location.href=`https://console.weplink.me/token?${token}`
    }
    
  }

  const PaySuccess = (props)=>{
  
    return(
      <React.Fragment>
        <Grid container component = "main" >
              <Grid item xs={12} sm = {12} md={12}>
                  <Avatar variant="square" className={classes.successorRejectIcon}>{props.statusTrx?<CheckCircleOutlineIcon fontSize="large" style={{ color:'green' }}/>:<HighlightOffOutlinedIcon fontSize="large" style={{ color:'red' }} />} </Avatar>
              </Grid>
              <Grid item xs={12} sm = {12} md={12}>
                  <Typography className={classes.successText}>{props.statusTrx?"¡Su solicitud de link ha sido creada exitosamente, su información será validad por nuestro equipo y estaremos informando en breve acerca de la activación de su link de pagos.":"¡No se pudo registrar el usuario!"}</Typography>              
              </Grid>            
         </Grid>     
      <div className = {classes.container}>
         
      </div>
         
             
    </React.Fragment>
    )
  }


    
  return (
    <React.Fragment>
        
      <Dialog
        open={openDialog}
        onClose={OpenSignupDialogHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth = {'xs'}
        TransitionComponent = {Transition}
        
      >
        <DialogTitle id="alert-dialog-title">
          
          {/* <Grid container component = "main" >
              <Grid item xs={6} sm = {6} md={6}>
                  <Avatar variant="square" className={classes.leftIconheader} src='../img/iconbank.jpg'> </Avatar>
              </Grid>

              <Grid item xs={6} sm = {6} md={6}>
                  <Avatar variant="square" className={classes.rightIconHeader} src='../img/iconmaestro.jpg'></Avatar>
              </Grid>  
          </Grid> */}
        </DialogTitle>
        <DialogContent >
            <PaySuccess statusTrx = {status} /> 
        </DialogContent>
        <DialogActions>
          <PayButton variant="contained" color="primary" onClick={(e)=>{
            OpenSignupDialogHandler() 
            /* ChangeContext() */
            SendToConsole()
            RestartState()
            }} autoFocus>            
                    Finalizar
          </PayButton>
        </DialogActions>
      </Dialog>
      </React.Fragment>
  );
}

const mapStateToProps = (state)=>{
    
    return {
        openDialog:state.Dialogs.openDialog,
        status:state.Dialogs.status,
        token:state.Dialogs.token
    }
  }

const mapDispatchToProps =  (dispatch) =>({
   OpenSignupDialogHandler(){
     return dispatch(signUpDialog({
       openDialog:false,
       status:false,
       token:""
     }))
    },

    /* ChangeContext(){
      return dispatch({
        type:"CHANGE_CONTEXT",
        Context:'personalData'
      })
    }, */
    
      /* RestartState (){
        return dispatch(restarState())
      } */
 
})


export default connect(mapStateToProps, mapDispatchToProps)(SignupDialog);