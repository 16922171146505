import React, { Component } from "react";

import Header from "../components/Header/header";
/* import HeroSection from "../components/HeroSection/HeroSectionSignUp1"; */
import SectionSignup from "../components/HeroSection/SectionSignup";

class SignUp extends Component {
  render() {
    return (
      <React.Fragment>
        {/* <Header bgColor="white"/> */}
        
          
          {/* <HeroSection /> */}
          <SectionSignup/>
          
         
      </React.Fragment>
    );
  }
}

export default SignUp;
