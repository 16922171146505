import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { signUp } from "../../actions/index";
import { Grow, Button   } from "@material-ui/core";
import _data from "../../data";
import ButtonGroup  from '@material-ui/core/ButtonGroup';
import { Context } from "react-responsive";
import PersonalData from "./SubComponents/PersonalData";
import CompanyData from "./SubComponents/CompanyData";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { PERSONAL_DATA } from "../../constants/types";
import SignupDialog from "../Dialogs/SignupDialog";
import { useMediaQuery } from 'react-responsive';
function SectionSignup ({context,sendPersonalData}) {
  
  const isDevice = useMediaQuery({
    query: '(max-device-width: 768px)'
  })
    const [state,setState] = useState({
      signup: {}
    })


    useEffect(() => {
      setState(prevState => ({
          ...prevState,
          signup:_data.signup
      }));
      
    }, [Context]);
    

    return (
        <React.Fragment>
          <SignupDialog/>
          <div className="divLineTop"></div>
          <section >
            
            <div className="container">
            
              
              <div className="row align-items-center justify-content-between pt-0 pt-sm-0 pt-md-0 pt-lg-0">
              
               {!isDevice &&
               
               
              
               <div className="col-sm-0 col-md-7 col-lg-6 DivLeft" >
               
               <div className="DivTitle">
               <img className="imgTitle" src="../../img/ic_plink_signup.png"></img><label className="lblTitle" >Plink</label>
              </div>

               <div className="DivSubTitle">
                 <label className="lblSubtitle" >¡Crear tu link de pagos <br/> <a className="lblSubtitle2">
                  es gratis!.
                 </a> </label>
               </div>

               <div className="divCards row equal">
                   
             {(state.signup.items || []).map(item => {
               return (
                 <div className="col-md-6 col-lg-6" key={item.title}>
                   <div className="single-promo single-promo-hover single-promo-1 rounded white-bg  h-100">
                     <div className="circle-icon mb-5">
                       <img alt="" style={{
                                   backgroundImage: `url(${item.bgImage})`,
                                   backgroundRepeat: "no-repeat",
                                   backgroundPosition: "center center",
                                   backgroundSize: "cover",
                                   height: "170px",
                                   width: "100%",
                                   borderBottomLeftRadius: 0,
                                   borderBottomRightRadius: 0,
                                   borderTopLeftRadius: 25,
                                   borderTopRightRadius: 25
                                 }}></img>
                       
                     </div>
                     <label className="titleTypeLink">{item.title}</label>
                     <p className="DescriptionTypeLink">{item.description}</p>
                   </div>
                 </div>
               );
             })}
           </div>
      </div>
               }

              <div className="col-sm-12 col-md-5 col-lg-5" style={{marginTop: "20px",height:"600px"}}>
                  <div className={isDevice?"card login-signup-card mb-0":"card login-signup-card shadow-lg mb-0"}>
                    <div className="card-body px-md-5 py-5">
                      <div className="mb-5">
                     
                        {isDevice &&
                          <div className="DivTitleMobile">
                            <img className="imgTitle" src="../../img/ic_plink_signup.png"></img>
                          </div>
                        }
                        

                        <h6 className="h3" style={{marginTop:20,fontSize:18,textAlign:"center",fontWeight:"bold"}}>Crear cuenta</h6>

                      </div>    
                      
                        <PersonalData/>
                     

                      <div className="card-footer px-md-5 bg-transparent border-top">
                        <small>Ya tienes una cuenta? </small>
                        <a href="login1" className="small">
                          Iniciar sesión
                        </a>
                    </div>
                    </div>
                   </div> 
               </div>
               
                {/* <div className="col-sm-0 col-md-7 col-lg-6" >
                  <div className="hero-content-left text-black">
                  <h1 className="text-black">#Plink  </h1>
                    <h1 className="text-black">¡Crear tu link de pagos <br/> <h1 className="font-weight-bold text-black">
                     es gratis!.
                    </h1> </h1>
                    
                  </div>
  
                  <div className="row equal">
                      {
                {(state.signup.items || []).map(item => {
                  return (
                    <div className="col-md-6 col-lg-6" key={item.title}>
                      <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg  h-100">
                        <div className="circle-icon mb-5">
                          <img alt="" style={{
                                      backgroundImage: `url(${item.bgImage})`,
                                      backgroundRepeat: "no-repeat",
                                      backgroundPosition: "center center",
                                      backgroundSize: "cover",
                                      height: "120px",
                                      width: "100%",
                                      borderBottomLeftRadius: 0,
                                      borderBottomRightRadius: 0,
                                      borderTopLeftRadius: 25,
                                      borderTopRightRadius: 25
                                    }}></img>
                          
                        </div>
                        <h5>{item.title}</h5>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
                </div> */}
               
                
              
              </div>
            </div>
            
            
          </section>
          
        </React.Fragment>
      );
}

const mapStateToProps = (state)=>{
  
  return {
    context:state.signup.Context
  }
}

const mapDispatchToProps = (dispatch) =>({
  sendPersonalData(){
      dispatch({
        type:"CHANGE_CONTEXT",
        Context:'personalData'
      }) 
  }
}) 



export default connect(mapStateToProps,mapDispatchToProps)(SectionSignup);

