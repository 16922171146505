import {
  SUBSCRIBE,
  SUBMIT_CONTACT,
  POST_PROMO,
  GET_QUOTE,
  POST_LOGIN,
  PERSONAL_DATA,
  COMPANY_DATA,
  RESTART_STATE,
  SIGNUP_DIALOG,
  POST_RESET_PASSWORD,
  POST_CHANGE_PASSWORD,
  GET_SEARCH,
  POST_COMMENT,
  CHANGE_PLINK_COMPONENT,
  GET_VALUES_PLINK,
  CHANGE_PLINK_PERSONAS_COMPONENT,
  GET_BROWSER_AGENT,
} from "../constants/types.js";

export const subscribe = email => {
  return {
    type: SUBSCRIBE,
    email,
  };
};

export const submitContact = contactData => {
  return {
    type: SUBMIT_CONTACT,
    contactData,
  };
};

export const postPromo = promoData => {
  return {
    type: POST_PROMO,
    promoData,
  };
};

export const getQuote = quoteData => {
  return {
    type: GET_QUOTE,
    quoteData,
  };
};

export const login = loginData => {
  return {
    type: POST_LOGIN,
    loginData,
  };
};

export const personalData = personalData => {
  return {
    type: PERSONAL_DATA,
    personalData,
  };
};

export const companyData = companyData =>{
  return {
    type: COMPANY_DATA,
    companyData  
  };
};

export const restarState = () =>{
  return{
    type: RESTART_STATE
  }
}

export const signUpDialog = signUpDialog =>{
  return {
    type: SIGNUP_DIALOG,
    signUpDialog  
  };
};

export const changePlinkComponent = changePlinkComponent =>{
  return{
    type: CHANGE_PLINK_COMPONENT,
    changePlinkComponent
  }
}

export const getValueAmount = getValueAmount =>{
  return{
    type: GET_VALUES_PLINK,
    getValueAmount
  }
}

export const browserAgent = browserAgent =>{
  return {
    type: GET_BROWSER_AGENT,
    browserAgent
  }
}

export const changePlinkPersonasComponent = changePlinkPersonasComponent =>{
  return{
    type:CHANGE_PLINK_PERSONAS_COMPONENT,
    changePlinkPersonasComponent
  }
}

export const resetPassword = resetPasswordData => {
  return {
    type: POST_RESET_PASSWORD,
    resetPasswordData,
  };
};

export const changePassword = changePasswordData => {
  return {
    type: POST_CHANGE_PASSWORD,
    changePasswordData,
  };
};

export const search = searchData => {
  return {
    type: GET_SEARCH,
    searchData,
  };
};

export const comment = commentData => {
  return {
    type: POST_COMMENT,
    commentData,
  };
};
