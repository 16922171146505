import React from 'react'
import SearchUserPlink from './SearchUserPlink'
import { connect } from 'react-redux';
import AmountPlinkPersonasComponent from './AmountPlinkPersonasComponent';
import CheckoutComponent from './CheckoutComponent';
import PhonePasswordComponent from './PhonePasswordComponent';
import FinalizedPayComponent from './FinalizedPayComponent';

function IndexPayComponent({containersComponents}){
    return (
        
        <>
           
            {
                containersComponents ==="search" ? <SearchUserPlink/>:
                containersComponents ==="amount" ? <AmountPlinkPersonasComponent/>:
                containersComponents === "checkout"? <CheckoutComponent/>:
                containersComponents === "authenticate"? <PhonePasswordComponent/>:
                containersComponents === "finalized" ? <FinalizedPayComponent/>:
                
                ""
            }
            
        </>
    )
}
const mapStateToProps = (state)=>{
    return {
        containersComponents : state.plinkReducer.changePlinkPersonasComponentValues[0].containersComponents
        }
}

   
export default connect(mapStateToProps)(IndexPayComponent)