import React,{useEffect,useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Avatar, Grid, Typography, Icon, TextField,Slide, CircularProgress, Portal } from '@material-ui/core';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import FinalizedPay from './FinalizedPay';
import { url, EndPoints, ConstantsCrypto } from '../../../constants/types';
import  CryptoJS from 'crypto-js';
import axios from 'axios'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme)=>({
  leftIconheader: {
    float:'left',
    backgroundColor:'transparent'
  },
  rightIconHeader:{
    float:'right',
    
  },
  successRejectIcon: {
    width:"100%",
    backgroundColor:'transparent'
  },
  titleHeader:{
    marginTop:60,
    fontSize:15,
    fontWeight:'bolder',
    textAlign:'center'
  },
  marginTextFieldCardNumber:{
    width:'100%',
    '& fieldset': {
      borderRadius: 0,
      fontSize:17
    },
    
  },
  codeConfirm:{
    fontWeight:'bolder',
    color:'gray',
    paddingTop:10
  },
  codeResend:{
    fontWeight:'bolder',
    color:'gray',
    float:'right'
  },

  successText:{
    textAlign:'center',
    color:'gray',
    paddingTop:10
  },
  buttonProgress: {
    color: "#000",
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

const PayButton = withStyles((theme) => ({
  root: {
    marginTop:'40px',
    marginLeft:20,
    marginRight:20,
    marginBottom:20,
    width:'100%',
    height:'40px',
    fontSize:'15px',
    backgroundColor: '#000',
    '&:hover': {
      backgroundColor:'#000',
    },

  },
}))(Button);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


 function DebitDialog(props) {
     
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [emptyOtp,setEmptyOtp] = useState(false)
  const [statusTrx,setStatusTrx] = useState("");
  const [loading,setLoading]= useState(false)
  const [cardNumber,setCardNumber] = useState("")
  const [Amount, setAmount] = useState("")
  const [alertOpen,setAlertOpen]=useState(false)
  const [messageError, setMessageError]=useState("")

  const checkoutIcon = (
    <Icon style = {{paddingRight:60}}>
      <img alt="edit" src="../img/iconsrc.png" style ={{"height": "20px",marginBottom:20}} />
    </Icon>
  );
  
  const ConfimrCodeComponent =(props)=>{
    return( 
    <React.Fragment>
      <Typography className={classes.codeConfirm}>Clave telefónica</Typography>
          <form autoComplete="off" component ='form'>
              <TextField
                          autoComplete="off"
                          error = {emptyOtp}
                          variant="outlined"
                          margin="normal"
                          color = "primary"
                          required
                          size='small'
                          id="otp"
                          name="otp"
                          className = {classes.marginTextFieldCardNumber}
                          type='password'
                          //onChange ={e => setOtp(e.target.value)}
                          disabled = {loading}
                          autoFocus
                          onPaste={noPaste}
                          onKeyPress={e=>validate(e)}
                          /*InputProps={{
                    
                            endAdornment:(
                              <InputAdornment position="end" className = {classes.InputAdornmentTypeCards}>
                                  {maestroIcon}
                              </InputAdornment>
                            )
                          }}
                          */
                          inputProps={{
                            
                            maxLength: 4,
                            inputMode:'tel'
                            
                          
                          }}
                          
                          
                      /> 
          </form>
      {/* <Typography className={classes.codeResend}>Reenviar código</Typography> */}
    </React.Fragment>
    )
  }

  useEffect(() => {
     const  handleOpen = ()=>{
        setOpen(props.open)
      };
      handleOpen()
    
  });
  
  const CustomAlert = (props)=>{
      return(
        <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="error">
          {props.message}
        </Alert>
      </Snackbar>
      )
  }
  
  const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setAlertOpen(false);
  };  
  
  const handleClose = ()=>{
    props.updateModal(false,'success')
    //setOpen2(true)
  }



  function openmodalsuccess(openmodal,status){
    setStatusTrx(status)
    setOpen2(openmodal)
  }
  const payDebitCard =(...prop)=>{
    const Url = url+EndPoints.crearPagoCheckout
    let cardnumber = CryptoJS.AES.encrypt(prop[0].toString(), ConstantsCrypto.MasterKeyCrypto);
    setCardNumber(cardnumber.toString())
    let cvvNumber = CryptoJS.AES.encrypt(prop[1].toString(), ConstantsCrypto.MasterKeyCrypto);
    let cedulaNumber = prop[2].toString();
    let otp =  CryptoJS.AES.encrypt(prop[3].toString(), ConstantsCrypto.MasterKeyCrypto);
    let account = prop[4].toString()
    let rif =prop[5].toString()
    let id_commerce = prop[6]
    let jwt = prop[7].toString()
    let amount = prop[8].toString()
    setAmount(amount)
    setLoading(true)
    
    axios.post(Url, {
      cvv:cvvNumber.toString(),
      exped :"2055/07",
      cardholderId:cedulaNumber.toString(),
      id_commerce:id_commerce.toString(),
      amount:amount.toString().replace(",","."), 
      cardnumber:cardnumber.toString(),
      rif:rif.toString(), //este dato lo capturamos x get url 
      tipo_tx:"tdd",
      tipo_cuenta:account.toString(),
      twofactor_auth:otp.toString(),
      
      browserAgent:{
        ipaddress:props.PayTrxProps[8].getBrowserAgent[0].ipAddress,
        agent: props.PayTrxProps[8].getBrowserAgent[0].agent,
        manufacturer: props.PayTrxProps[8].getBrowserAgent[0].manufacturer,
        model:props.PayTrxProps[8].getBrowserAgent[0].model,
        os_version:props.PayTrxProps[8].getBrowserAgent[0].os_version,
        lat:props.PayTrxProps[8].getBrowserAgent[0].lat.toString(),
        lng:props.PayTrxProps[8].getBrowserAgent[0].lng.toString(),
  
      }
    },{
      timeout:30000, 
      headers: { 
       
        "Authorization":jwt
        
      }
    })
    .then(function(data){
      setLoading(false)
     
      if(data.data.exito){
       
        
        openmodalsuccess(true,"success")
      }else{
      
       
        openmodalsuccess(true,"rejected")
      }

    })
    .catch(function(err){
      
      setLoading(false)
      let code = err.code
      if(code === "ECONNABORTED"){
        setAlertOpen(true)
        setMessageError("Error de timeout")
      }else{
        openmodalsuccess(true,"rejected")
      }
      
      //window.top.location.href = urlCurrent; 
      
    })
  }
  const payDebitCard1 = (...prop)=>{
    const Url = url+EndPoints.crearPagoTDDBoton
    //return 
    let cardnumber = CryptoJS.AES.encrypt("5018782000"+prop[0].toString(), ConstantsCrypto.MasterKeyCrypto);
    setCardNumber(cardnumber.toString())
    let cvvNumber = CryptoJS.AES.encrypt(prop[1].toString(), ConstantsCrypto.MasterKeyCrypto);
    let cedulaNumber = prop[2].toString();
    let otp =  CryptoJS.AES.encrypt(prop[3].toString(), ConstantsCrypto.MasterKeyCrypto);
    let account = prop[4].toString()
    let rif =prop[5].toString()
    let id_commerce = prop[6]
    let jwt = prop[7].toString()
    let amount = prop[8].toString()
    setAmount(amount)
    setLoading(true)
    
    axios.post(Url,{
        cardnumber:cardnumber.toString(),//"U2FsdGVkX18Zepnvg6WR+jCo+eAaELSyYOA51ws7yPit3DLVlTQyzoSB18WX+LCO",//,
        Cardholder: "aaaaaaaaaaaaaaaaaaaaaaaaa",
        cardholderId: "V"+cedulaNumber.toString(),
        Cvv: cvvNumber.toString(),
        Otp: otp.toString(),
        Rif: rif.toString(),
        Account: account.toString(),
        exped: "2055/07",
        Amount: amount,
        Longitude:"10.000",
        Latitude:"-66.000",
        id_commerce:id_commerce,
        browserAgent:props.PayTrxProps[8].getBrowserAgent[0]
    },{
        timeout:30000,
        headers: { 
            "Authorization":jwt
          }
    })
    .then(function(data){
        setLoading(false)
        
        if(data.data.exito){
            props.updateModal(false,'success')
            openmodalsuccess(true,"success")
        }else{
            props.updateModal(false,'success')
            openmodalsuccess(true,"rejected")
           
        }

      })
      .catch(function(err){
        setLoading(false)
        let code = err.code
        if(code === "ECONNABORTED"){
            
            setAlertOpen(true)
            setMessageError("Error de timeout")
        }else{
            console.log()
            setAlertOpen(true)
            setMessageError("Hubo un problema con el servidor intente más tarde ")
        }
        })
  }


 function handleClikPay(){
    
    let field = document.getElementById("otp")
    let cardNumber = props.PayTrxProps[0]
    let cvvNumber = props.PayTrxProps[1]
    let cedulaNumber = props.PayTrxProps[2]
    let typeAccount = props.PayTrxProps[3]
    let isOtpEmpty = isEmpty(field.value)
    let isOtpIncorrect = isFieldIncorrect(field.value)
    let rif = props.PayTrxProps[4]
    let id_commerce = props.PayTrxProps[5]
    let jwt = props.PayTrxProps[6]
    let amount = props.PayTrxProps[7].toString().replace(/,/g,"")
    
    

    if(isOtpEmpty || isOtpIncorrect){
      return
    }
  
    payDebitCard(cardNumber,cvvNumber,"V"+cedulaNumber,field.value,typeAccount,rif,id_commerce,jwt,amount)

    
    
    setEmptyOtp(false)
  }

  /*const handleCloseSuccess= ()=>{
    setOpen2(false)
  }*/
  

  function openmodalfinalized(openModal){
    setOpen2(openModal)
  }

  const openMod = (e)=>{  
        openmodalfinalized(e)
  }

  const DebitTransactionFinish = (e) =>{
    props.isDebitTransactionFinish()
    //setOpen2(true)
}

  function isEmpty(field){
    if(field ===""){
      setEmptyOtp(true)
      return true
    }else{
      return false
    }
  }
  
  function isFieldIncorrect(field){
    
      if(field.length <4){
        setEmptyOtp(true)
        return true
      }else{
        if(isNumber(field)){
          setEmptyOtp(false)
          return false
        }else{
          setEmptyOtp(true)
          return true
        }
        
      }
    
  }

  const noPaste = (e)=>{
    e.preventDefault(); 
    return false
  }


  const isNumber=(digit)=>{
    var numbers = /^[0-9]+$/;
        if(digit.match(numbers))
          {
          return true
          }
            else
            {
              return false
            }
  }

  function validate(e){
    var key = window.event ? e.which : e.keyCode;
    /*if(e.target.value.length===4 || e.target.value.length===8 ||e.target.value.length===12  ){
      e.target.value = e.oldValue + e.newValue
    }*/
    if (key < 48 || key > 57) {
      
      e.preventDefault();
    }
  }
  return (
    
    <React.Fragment>
      <CustomAlert message ={messageError} />
      <FinalizedPay  open = {open2} updateModal = {openMod} isDebitTransactionFinish = {DebitTransactionFinish} statusTrx = {statusTrx} trxData={[cardNumber.toString(),Amount.toString()]}/>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth = {'xs'}
        TransitionComponent={Transition}
        
      >
        <DialogTitle id="alert-dialog-title">
          
          <Grid container component = "main" >
              <Grid item xs={6} sm = {6} md={6}>
                  <Avatar variant="square" className={classes.leftIconheader} src='../img/iconbank.jpg'> </Avatar>
              </Grid>

              <Grid item xs={6} sm = {6} md={6}>
                  <Avatar variant="square" className={classes.rightIconHeader} src='../img/iconmaestro.jpg'></Avatar>
              </Grid>

            
                <Grid item xs={12} sm = {12} md={12} >
                  <Typography className={classes.titleHeader}>AUTENTICAR COMPRA</Typography>
              </Grid>
          
              
          </Grid>
            
           
           
        </DialogTitle>
        <DialogContent >
          <DialogContentText id="alert-dialog-description" >
                <Typography component = {'span'} style={{textAlign:'center'}}>
                Introduzca la clave telefónica de su tarjeta de Débito Mercantil, para autorizar su compra en línea.
                </Typography>    
          </DialogContentText>
         
          <ConfimrCodeComponent/>
      
        </DialogContent>
        <DialogActions>
            
          <PayButton variant="contained" color="primary" startIcon ={loading?"":checkoutIcon} onClick={handleClikPay} disabled = {loading} autoFocus>
          {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
                    Confirmar Compra
          </PayButton>
        </DialogActions>
      </Dialog>




      
      </React.Fragment>
  );
}

export default DebitDialog