module.exports = {
  hero: {
    title: "Brainstorming for desired perfect Usability",
    description:
      "",
    bgImage: "img/app-hero-bg.jpg"
  },
  promo: {
    title: "#Plink ofrece a tu comercio las formas de pagos más reconocidas y seguras.",
    description:
      "Acepta las marcas de pago estándar a nivel mundial.",
    items: [
      {
        title: "Comercios",
        description:
          "Vende alrededor del mundo y recibe pagos en línea, con sólo compartir tu link.",
          icon: "ti-vector text-white",
          bgImage: "img/ic_comercio.png"
      },
      {
        title: "Delivery",
        description:
          "El tiempo apremia en las entregas, comparte tu plink y llegas más rápido.",
        icon: "ti-lock text-white",
        bgImage: "img/image-delivery@2x.png"
      },
      {
        title: "Freelance",
        description:
          "Ideal para pagos de servicios o facturas de profesionales.",
        icon: "ti-eye text-white",
        bgImage: "img/image-freelance@2x.png"
      }
    ]

  },
  signup: {
    items: [
      {
        title: "Personas",
        description:
          "Envía y recibes pagos de amigos, familiares o profesionales.",
          icon: "ti-vector text-white",
          bgImage: "img/ic_persona.png"
      },
      {
        title: "Negocios",
        description:
          "Vende alrededor del mundo y recibe pagos en línea.",
        icon: "ti-lock text-white",
        bgImage: "img/ic_comercio.png"
      },
    ],

    estados : [
      {
        value: "",
        label : "--Ubicación--"
      },
      {
        value: "Amazonas",
        label : "Amazonas"
      },
      {
        value: "Anzoategui",
        label : "Anzoátegui"
      },
      {
        value: "Apure",
        label : "Apure"
      },
      {
        value: "Aragua",
        label : "Aragua"
      },
      {
        value: "Barinas",
        label : "Barinas"
      },
      {
        value: "Bolivar",
        label : "Bolívar"
      },
      {
        value: "Carabobo",
        label : "Carabobo"
      },
      {
        value: "Cojedes",
        label : "Cojedes"
      },
      {
        value: "Delta Amacuro",
        label : "Delta Amacuro"
      },
      {
        value: "Dtto Capital",
        label : "Dtto Capital"
      },
      {
        value: "Falcon",
        label : "Falcón"
      },
      {
        value: "Guarico",
        label : "Guárico"
      },
      {
        value: "Lara",
        label : "Lara"
      },
      {
        value: "Merida",
        label : "Merida"
      },
      {
        value: "Miranda",
        label : "Miranda"
      },
      {
        value: "Monagas",
        label : "Monagas"
      },
      {
        value: "Nueva Esparta",
        label : "Nueva Esparta"
      },
      {
        value: "Portuguesa",
        label : "Portuguesa"
      },
      {
        value: "Sucre",
        label : "Sucre"
      },
      {
        value: "Tachira",
        label : "Táchira"
      },
      {
        value: "Trujillo",
        label : "Trujillo"
      },
      {
        value: "Vargas",
        label : "Vargas"
      },
      {
        value: "Yaracuy",
        label : "Yaracuy"
      },
      {
        value: "Zulia",
        label : "Zulia"
      }

    ]

  },
  aboutUs: {
    title: {
      bold: "Use your android or ios device",
      normal: ""
    },
    description:
      "Following reasons show advantages of adding AgencyCo to your lead pages, demos and checkouts",
    items: [
      {
        title: "Clean Design",
        description:
          "Increase sales by showing true dynamics of your website",
        icon: "ti-vector text-white"
      },
      {
        title: "Secure Data",
        description:
          "Build your online store’s trust using Social Proof & Urgency",
        icon: "ti-lock text-white"
      },
      {
        title: "Retina Ready",
        description:
          "Realize importance of social proof in customer’s purchase decision",
        icon: "ti-eye text-white"
      }
    ]
  },
  price: {
    title: "Pricing Packages",
    description:
      "Uniquely maintain efficient expertise whereas cross functional deliverables. Continually leverage other's competitive mind share whereas enabled channels. ",
    packages: [
      {
        price: "$29",
        license: "Basic",
        isFeatured: false,
        features: [
          "Push Notifications",
          "Data Transfer",
          "SQL Database",
          "Search & SEO Analytics",
          "24/7 Phone Support",
          "2 months technical support",
          "2+ profitable keyword"
        ]
      },
      {
        price: "$50",
        license: "Popular",
        isFeatured: true,
        features: [
          "Push Notifications",
          "Data Transfer",
          "SQL Database",
          "Search & SEO Analytics",
          "24/7 Phone Support",
          "6 months technical support",
          "30+ profitable keyword"
        ]
      },
      {
        price: "$120",
        license: "Extended",
        isFeatured: false,
        features: [
          "Push Notifications",
          "Data Transfer",
          "SQL Database",
          "Search & SEO Analytics",
          "24/7 Phone Support",
          "1 year technical support",
          "70+ profitable keyword"
        ]
      }
    ]
  },
  testimonial: {
    title: "What customer say about us",
    description:
      'Rapidiously morph transparent internal or "organic" sources whereas resource sucking e-business. Conveniently innovate compelling internal.',
    comments: [
      {
        name: "Arabella Ora",
        company: "AgencyCo",
        image: "img/client-2.jpg",
        comment:
          "Completely build backend ROI whereas cross-media metrics. Collaboratively deploy customer directed web-readiness via global testing procedures. Appropriately reinvent distributed innovation without proactive catalysts for change. Distinctively."
      },
      {
        name: "John Charles",
        company: "ThemeTags",
        image: "img/client-1.jpg",
        comment:
          "If you can design one thing you can design everything with Front. Just believe it. Collaboratively repurpose performance based e-commerce without cost. It's beautiful and the coding is done quickly and seamlessly."
      },
      {
        name: "Jeremy Jere",
        company: "WorldCheck",
        image: "img/client-3.jpg",
        comment:
          "The template is really nice and offers quite a large set of options. It's beautiful and the coding is done. If you can design one thing you can design everything with Front. Just believe it."
      },
      {
        name: "Ciaran Kierce",
        company: "AppCo",
        image: "img/client-4.jpg",
        comment:
          "I just wanted to share a quick note and let you know that you guys do a really good job. I’m glad I decided to work with you. It’s really great how easy your websites are to update and manage. I never have any problem at all."
      }
    ]
  },
  contact: {
    title: "Contáctanos",
    description:
      "Si deseas conocer más acerca de plink, no dudes en escribirnos.",

    office: "Head Office",
    address: "Calle Ernesto Blohm, Caracas 1060, Miranda",
    phone: "+58 212 959 6483",
    email: "info@weplink.me"
  },
  trustedCompany: [
    "img/client-1-color.png",
    "img/client-6-color.png",
    "img/client-3-color.png",
    "img/client-4-color.png",
    "img/client-5-color.png"
  ],
  trustedCompanyGray: [
    "img/client-1-gray.png",
    "img/client-2-gray.png",
    "img/client-3-gray.png",
    "img/client-4-gray.png",
    "img/client-5-gray.png"
  ],
  trustedCompanyAboutUs: {
    title: "Partners ",
    description: "Una red colaborativa formada entre nuestros aliados tecnológicos, adquirentes y las marcas en pro de crear soluciones disruptivas en el ecosistema de pagos digitales.",
    companies: [
      "img/partner-mercantil.jpg",
      "img/partner-visa.jpg",
      "img/partner-mastercard.jpg",
      "img/partner-aws.jpg",
      "img/partner-nextpos.jpg"
    ]
  },
  teamMember: {
    members: [
      {
        name: "Edna Mason",
        designation: "Senior Designer",
        image: "img/team-4.jpg"
      },
      {
        name: "Arabella Ora",
        designation: "Senior Designer",
        image: "img/team-1.jpg"
      },
      {
        name: "John Charles",
        designation: "Senior Designer",
        image: "img/team-2.jpg"
      },
      {
        name: "Jeremy Jere",
        designation: "Senior Designer",
        image: "img/team-3.jpg"
      },
    ]
  },
  video: {
    link: "https://weplink.me"
  }
};
