import React,{useEffect,useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Avatar, Grid, Typography, Icon, Slide, ButtonGroup } from '@material-ui/core';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { grey } from '@material-ui/core/colors';
import  CryptoJS from 'crypto-js';
import {ConstantsCrypto } from '../../../constants/types';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme)=>({
  leftIconheader: {
    float:'letf',
    backgroundColor:'transparent'
  },
  rightIconHeader:{
    float:'right',
    
  },
  successorRejectIcon: {
    width:"100%",
    backgroundColor:'transparent',
    marginTop:60
  },
  titleHeader:{
    marginTop:60,
    fontSize:15,
    fontWeight:'bolder',
    textAlign:'center'
  },
  marginTextFieldCardNumber:{
    width:'100%',
    '& fieldset': {
      borderRadius: 0,
      fontSize:17
    },
    
  },
  codeConfirm:{
    fontWeight:'bolder',
    color:'gray',
    paddingTop:10
  },
  codeResend:{
    fontWeight:'bolder',
    color:'gray',
    float:'right'
  },

  successText:{
    textAlign:'center',
    color:'gray',
    paddingTop:10
  },
  container:{
    width:'100%',
    alignContent:'center',
    padding:theme.spacing(5,10),
    [theme.breakpoints.down('xs')]:{
      padding:theme.spacing(5,3),
    }
  },
  buttons:{
    width:'50%',
    border:'solid 1px'+grey[500],
    borderRadius:'10px',
   
    
  },

  
}))

const PayButton = withStyles((theme) => ({
  root: {
    marginTop:'40px',
    marginLeft:20,
    marginRight:20,
    marginBottom:20,
    width:'100%',
    height:'40px',
    fontSize:'15px',
    backgroundColor: '#000',
    '&:hover': {
      backgroundColor:'#000',
    },

  },
}))(Button);

 function FinalizedPay(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [cardNumber,setCardNumber]= useState("");
 
  const checkoutIcon = (
    <Icon style = {{paddingRight:60}}>
      <img alt="edit" src="../img/iconsrc.png" style ={{"height": "20px",marginBottom:20}} />
    </Icon>
  );
  const creditIcons =(
    <Icon style = {{paddingRight:50}}>
      <img alt="edit" src="../img/visa.png" style ={{"height": "20px", paddingLeft:10,marginBottom:20}} />
    </Icon>
  );

  const PaySuccess = (props)=>{
   
    return(
      <React.Fragment>
        <Grid container component = "main" >
              <Grid item xs={12} sm = {12} md={12}>
                  <Avatar variant="square" className={classes.successorRejectIcon}>{props.statusTrx ==="success"?<CheckCircleOutlineIcon fontSize="large" style={{ color:'green' }}/>:<HighlightOffOutlinedIcon fontSize="large" style={{ color:'red' }} />} </Avatar>
              </Grid>
              <Grid item xs={12} sm = {12} md={12}>
                  <Typography className={classes.successText}>{props.statusTrx==="success"?"¡Pago exitoso!":"¡Pago rechazado"}</Typography>              
              </Grid>            
         </Grid>     
      <div className = {classes.container}>
        <ButtonGroup disableElevation  variant="contained"  style={{width:'100%'}}>
                      
                     <Button className={classes.buttons} disabled >Ref: {props.trxData[2]}</Button>
                      <Button className={classes.buttons} disabled>{props.trxData[1]} Bs</Button>
         </ButtonGroup> 
      </div>
         
             
    </React.Fragment>
    )
  }
    useEffect(() => {
     const  handleOpen = ()=>{
        setOpen(props.open)
      };
      handleOpen()
      let cardDecrypt =CryptoJS.AES.decrypt(props.trxData[0].toString(),ConstantsCrypto.MasterKeyCrypto ).toString(CryptoJS.enc.Utf8); 
      setCardNumber(cardDecrypt.substr(-4))
    });

    const HandleClose = (e)=>{
      props.updateModal(false)
      props.isDebitTransactionFinish()
    }

  
  return (
    
    <React.Fragment>
      <Dialog
        open={open}
        onClose={HandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth = {'xs'}
        TransitionComponent = {Transition}
        
      >
        <DialogTitle id="alert-dialog-title">
          
          <Grid container component = "main" >
              <Grid item xs={6} sm = {6} md={6}>
                  <Avatar variant="square" className={classes.leftIconheader} src='../img/iconbank.jpg'> </Avatar>
              </Grid>

              <Grid item xs={6} sm = {6} md={6}>
                {
                  props.tipo_operacion === "c2p" ? "":<Avatar variant="square" className={classes.rightIconHeader} src='../img/iconmaestro.jpg'></Avatar>
                }
                  
              </Grid>  
          </Grid>
        </DialogTitle>
        <DialogContent >
            <PaySuccess statusTrx = {props.statusTrx} trxData={props.trxData}/> 
        </DialogContent>
        <DialogActions>
          <PayButton variant="contained" color="primary" startIcon ={checkoutIcon} onClick={HandleClose} autoFocus>            
                    Finalizar compra
          </PayButton>
        </DialogActions>
      </Dialog>
      </React.Fragment>
  );
}

export default FinalizedPay