import  React  from 'react';
import { connect } from 'react-redux';
import FinalizedPayComponent from './../payComponents/FinalizedPayComponent';

function IndexBanksComponents(){
    return(
        <React.Fragment>
            <FinalizedPayComponent/>
        </React.Fragment>
    )
}
export default IndexBanksComponents