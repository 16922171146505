import React from "react";

class Faq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section className="promo-section ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-9">
                <div className="section-heading mb-5">
                  <h2>Preguntas frecuentes</h2>
                  <p className="lead">
                    Encuentra aquí respuestas a tus preguntas con respecto a #plink.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div id="accordion-1" className="accordion accordion-faq">

                <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-1-1"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-1-0"
                      aria-expanded="false"
                      aria-controls="collapse-1-0"
                    >
                      <h6 className="lbl-freemium">
                        <span  className="ti-receipt mr-3"></span> ¿COMO RESERVAR MI LINK?
                      </h6>
                    </div>
                    <div
                      id="collapse-1-0"
                      className="collapse"
                      aria-labelledby="heading-1-1"
                      data-parent="#accordion-1"
                    >
                      <div className="card-body">
                        <p>
                        Deberás realizar la solicitud vía correo electrónico info@weplink.me y enviar los siguientes datos:</p>
                        <p>- Nombre del comercio y rif</p>
                        <p>- Correo electrónico</p>
                        <p>- Teléfono (WhatsApp)</p>
                        <p>- @Instagram (Si posee)</p>
                        <p>- Numero de cuenta jurídica de cualquier Banco Nacional</p>
                        
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-1-1"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-1-1"
                      aria-expanded="false"
                      aria-controls="collapse-1-1"
                    >
                      <h6 className="mb-0">
                        <span className="ti-receipt mr-3"></span> Qué es plink?
                      </h6>
                    </div>
                    <div
                      id="collapse-1-1"
                      className="collapse"
                      aria-labelledby="heading-1-1"
                      data-parent="#accordion-1"
                    >
                      <div className="card-body">
                        <p>
                         Es un enlace de pagos. Los vendedores podrán compartirlo con sus clientes para recibir dinero. Los compradores a través de plink pueden pagar productos o servicios a vendedores en redes sociales, tiendas en línea o simplemente a través de Whatsapp Business. 
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-1-1"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-1-2"
                      aria-expanded="false"
                      aria-controls="collapse-1-2"
                    >
                      <h6 className="mb-0">
                        <span className="ti-receipt mr-3"></span> Requisítos para suscribirme a plink.
                      </h6>
                    </div>
                    <div
                      id="collapse-1-2"
                      className="collapse"
                      aria-labelledby="heading-1-1"
                      data-parent="#accordion-1"
                    >
                      <div className="card-body">
                        <p>
                         Poseer una cuenta bancaria jurídica o firma personal en cualquier Banco .
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-1-1"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-1-3"
                      aria-expanded="false"
                      aria-controls="collapse-1-3"
                    >
                      <h6 className="mb-0">
                        <span className="ti-receipt mr-3"></span> ¿Puedo adquirir Plink si soy persona natural?.
                      </h6>
                    </div>
                    <div
                      id="collapse-1-3"
                      className="collapse"
                      aria-labelledby="heading-1-1"
                      data-parent="#accordion-1"
                    >
                      <div className="card-body">
                        <p>
                         Proximante estaremos activando Plink Persona. 
                        </p>
                        {/* Si, de igual forma podrás solicitar tu apertura de cuenta online el servicio y deberás realizar la solicitud escribiendos al correo b2b@weplink.me */}
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-1-2"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-1-4"
                      aria-expanded="false"
                      aria-controls="collapse-1-4"
                    >
                      <h6 className="mb-0">
                        <span className="ti-link mr-3"></span> ¿Cómo suscribirme a plink y obtener mi enlace de pago weplink.me/mitienda?
                      </h6>
                    </div>
                    <div
                      id="collapse-1-4"
                      className="collapse"
                      aria-labelledby="heading-1-2"
                      data-parent="#accordion-1"
                    >
                      <div className="card-body">
                        <p>
                           Escribenos a info@weplink.me  con los requisitos solicitados para activar tu link de pago.  
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-1-3"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-1-5"
                      aria-expanded="false"
                      aria-controls="collapse-1-5"
                    >
                      <h6 className="mb-0">
                        <span className="ti-money mr-3"></span> Cuanto cuesta usar plink?
                      </h6>
                    </div>
                    <div
                      id="collapse-1-5"
                      className="collapse"
                      aria-labelledby="heading-1-3"
                      data-parent="#accordion-1"
                    >
                      <div className="card-body">
                        <p>Usar plink para realizar pagos no tiene costo.</p>
                        <p>Usar plink para recibir pagos si tiene costo y se cobra un porcentaje para cada transacción exitosa. Débito 2.9% + IVA | Crédito 3.5% + IVA.</p>
                        <p>Sin comisiones mensuales</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div id="accordion-2" className="accordion accordion-faq">
                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-2-1"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-2-1"
                      aria-expanded="false"
                      aria-controls="collapse-2-1"
                    >
                      <h6 className="mb-0">
                        <span className="ti-credit-card mr-3"></span> Cuales tarjetas pueden procesarse con plink?
                      </h6>
                    </div>
                    <div
                      id="collapse-2-1"
                      className="collapse"
                      aria-labelledby="heading-2-1"
                      data-parent="#accordion-2"
                    >
                      <div className="card-body">
                        <ul>
                          <li>Tarjetas de débito maestro (Banco Mercantil)</li>
                          <li>Tarjetas de crédito Visa/Mastercard (Todos los bancos a nivel nacional)</li>
                          <li>Tarjeta Diners Club (Banco Mercantil)</li>
                          <li>Tarjetas Internacionales Visa/Mastercard en divisas USD - EUR</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  
                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-2-3"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-2-3"
                      aria-expanded="false"
                      aria-controls="collapse-2-3"
                    >
                      <h6 className="mb-0">
                        <span className="ti-mobile mr-3"></span> Puedo usar plink en mi dispositivo móvil?
                      </h6>
                    </div>
                    <div
                      id="collapse-2-3"
                      className="collapse"
                      aria-labelledby="heading-2-3"
                      data-parent="#accordion-2"
                    >
                      <div className="card-body">
                        <p>
                          Si, plink se adapta al dispositivo que estés utilizando (Smartphone, tablet, laptop).
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-2-3"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-2-4"
                      aria-expanded="false"
                      aria-controls="collapse-2-4"
                    >
                      <h6 className="mb-0">
                        <span className="ti-lock mr-3"></span> Es seguro usar plink?
                      </h6>
                    </div>
                    <div
                      id="collapse-2-4"
                      className="collapse"
                      aria-labelledby="heading-2-3"
                      data-parent="#accordion-2"
                    >
                      <div className="card-body">
                        <p>
                          Si, toda la información del comercio y datos de tarjetas están encriptados con lo más altos estandares de seguridad TLS. Nuestra arquitectura garantiza privacidad de los datos sensibles y que no sean manipulados por terceros. NextPOS ® es PCI compliance DSS.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-2-3"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-2-5"
                      aria-expanded="false"
                      aria-controls="collapse-2-5"
                    >
                      <h6 className="mb-0">
                        <span className="ti-exchange-vertical mr-3"></span> Si un cliente paga con una tarjeta internacional, recibiré Bs o la divisa del pais origen de la tarjeta?
                      </h6>
                    </div>
                    <div
                      id="collapse-2-5"
                      className="collapse"
                      aria-labelledby="heading-2-3"
                      data-parent="#accordion-2"
                    >
                      <div className="card-body">
                        <p>
                          Recibiras Bolívares en tu cuenta bancaria. Las operaciones con tarjetas internacionales requieren de un cambio de moneda, regido por la tasa de cambio ofical del país donde se encuentre el comercio. En el caso Venezuela la tasa de cambio la establece el Banco Central de Venezuela (BCV).
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-2-3"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-2-6"
                      aria-expanded="false"
                      aria-controls="collapse-2-6"
                    >
                      <h6 className="mb-0">
                        <span className="ti-exchange-vertical mr-3"></span>¿En cuanto tiempo recibo el dinero en mi cuenta?
                      </h6>
                    </div>
                    <div
                      id="collapse-2-6"
                      className="collapse"
                      aria-labelledby="heading-2-3"
                      data-parent="#accordion-2"
                    >
                      <div className="card-body">
                      <ul> <li>
                          Si posee cuenta del Banco Mercantil en  24 hrs hábiles, otros bancos en 48 hrs .</li>
                       </ul>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </section>

      </React.Fragment>
    );
  }
}

export default Faq;
