import React from 'react'
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Slide, Typography } from '@material-ui/core';

const useStyle = makeStyles((theme)=>({
    body:{
        flexGrow: 1,
        height: '100vh',
        paddingTop:"20%" 
    },
    title:{
        marginTop:"20%",
        width:"100%",
        textAlign:"center"
    },
    paper:{
        marginTop:"20%",
        width:"100%",
        textAlign:"center"
    }
}))

function ReceivedPayComponent(){
    const classes = useStyle()
    return(
        <Slide direction="right" in = {true} mountOnEnter unmountOnExit >
            <div className={classes.body}> 
                <Grid container>
                    <Grid item xs={12}>
                        <div className ={classes.title}>
                            <Typography style ={{fontSize:20}}>Comienza a recibir pagos</Typography>
                            <Typography style ={{fontSize:10}}>Comparte tu link con socios, amigos o familiares</Typography>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <div className ={classes.paper}>
                            <Paper elevation={3} style={{width:"40%",margin:"auto"}}>
                                <img src = "../../img/savemoney.svg" style={{width:50,height:50,marginTop:10,marginBottom:20}}/>
                                <Typography style ={{fontSize:15, color:"#9329E4",marginTop:10}}>weplink.me/lysa</Typography>
                            </Paper>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </Slide>    
    )
}
export default ReceivedPayComponent/* connect()(ReceivedPayComponent) */