export const SUBSCRIBE = 'SUBSCRIBE';
export const SUBMIT_CONTACT = 'SUBMIT_CONTACT';
export const POST_PROMO = 'POST_PROMO';
export const GET_QUOTE = 'GET_QUOTE';
export const POST_LOGIN = 'POST_LOGIN';
export const PERSONAL_DATA = 'PERSONAL_DATA';
export const COMPANY_DATA = 'COMPANY_DATA';
export const CHANGE_CONTEXT = 'CHANGE_CONTEXT';
export const SIGNUP_DIALOG = 'SIGNUP_DIALOG';
export const RESTART_STATE = 'RESTART_STATE'
export const POST_RESET_PASSWORD = 'POST_RESET_PASSWORD';
export const POST_CHANGE_PASSWORD = 'POST_CHANGE_PASSWORD';
export const GET_SEARCH = 'GET_SEARCH';
export const POST_COMMENT = 'POST_COMMENT';
export const CHANGE_PLINK_COMPONENT = "CHANGE_PLINK_COMPONENT";
export const GET_VALUES_PLINK = "GET_VALUES_PLINK";
export const CHANGE_PLINK_PERSONAS_COMPONENT = "CHANGE_PLINK_PERSONAS_COMPONENT";
export const GET_BROWSER_AGENT = "GET_BROWSER_AGENT"
export const url ='https://api.nextpos.us/Usuarios/'//"http://localhost:3000/Usuarios/" //'http://10.0.0.7:3000/Usuarios/' //https://api.nextpos.us/Usuarios/' ////
export const EndPoints = {getJWT:'getJWT',crearPagoCheckout:'crearPagoCheckout',crearPagoTdcBoton:'crearPagoTdcBoton',crearPagoTDDBoton:'crearDebitBotonPagoMercantil',getPlinkUser:'getPlinkUser',addPlinkUser:'addPlinkUser',crearPagoC2PPlink:'crearC2PPlink'}
export const ConstantsCrypto = {MasterKeyCrypto:'myPassword',MasterKeyCryptoC2P:'p46011ou17',JSON_MASTERKEY :'7ETxCx/xXF2q+z7dLGqF2evF9L', MasterKeyAddUser : "l061nu53r"}

// PROD
export const CaptchSiteKey =  "6LcDYc8ZAAAAAJ0HuWvIO4F4j4OzhnnYnt0NVzuQ"
// SANDBOX
//export const CaptchSiteKey =  "6LcJqsoZAAAAABaA25ECsi2_LchP4z01KDiec9Mo"
export const CREDENTIALS_MASTERKEY = "rxfloñmnjuklgjhnbbklkihlllll"
export const DataUploadS3 = {bucketName:"identityprofile", region:"us-east-1", AccessKeyID:"AKIAXKQL5MS56GAFPLTN",SecretAccessKey:"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"}
 