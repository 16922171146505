import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import{ Grid, Avatar, TextField, Slide } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({


    //Containter styles
    container:{
      minHeight: "100%"
    },
    //Containter styles
    body:{
  
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    //bar styles
    root: {
      display: 'flex',
      flexDirection: 'column',
      
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    appBar:{
      zIndex: theme.zIndex.drawer + 1,
      backgroundImage: "linear-gradient(to right, rgb(102, 48, 194), #6730E3, rgba(83, 49, 177, 1), rgba(114, 48, 205, 1), rgba(150, 41, 230, 1))",
    },
  
    //CARD STYLE
    rootCard: {
      minWidth: 275,
      margin: theme.spacing(0,0,0,0),
      height:"100vh",
      alignItems:'center',
      [theme.breakpoints.down("xs")]:{
        margin: theme.spacing(0,0,0,0),
      }
    },
   
    titleCard: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  
    fab: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      color: theme.palette.common.white,
      backgroundColor: "#25d366",
  
    },
    contentAvatar:{
      width:'100%',
      backgroundImage:`url(${"../img/bg_icons_market-web.png"})`,
      textAlign:'center'
    },
    contentBodyCard:{
      width:'100%',
      
      textAlign:'center'
    },
    Avatar:{
      margin:'auto',
      height:70,
      width:70
    },
   
    buttonGoPage:{
      margin:theme.spacing(8,0,0,0),
      borderRadius:'20px',
      color: "red",
    },
    dividerLeft:{
        
      float:"left",
      marginTop:'8px',
      /*[theme.breakpoints.down("xs")]: {
        width:'23%',
      },*/
      width:'30%',
      borderTop: 'solid 1px #D6D6D6',
      [theme.breakpoints.down("xs")]:{
        width:'25%',
      }
  
  },
  dividerRight:{
    float:"right",
      /*[theme.breakpoints.down("xs")]: {
        width:'25%',
      },*/
      marginTop:'8px',
      width:'30%',
      borderTop: 'solid 1px #D6D6D6',
      [theme.breakpoints.down("xs")]:{
        width:'25%',
      }
  },
  marginTextFieldCardNumber:{
    width:'100%',
    textAlign: "center",
    fontSize:40,
    marginTop:20,
    '& fieldset': {
      borderRadius: 0,
      border:'solid 0px transparent',
      
    },
    "&::placeholder": {
      textAlign: "center",
      fontSize:40,
      
    },
    
   
    
  },
  
  buttonNext:{
    background:"#000",
    borderRadius:'20px',
    marginTop:30,
    width:150,
   
  },
  footer:{
      textAlign:'center',
      bottom:0,
      right:0,
      position: "absolute",
      width: "100%",
      fontSize:'10px',
      marginBottom:"2.3%"
  }
    
  }));


export default function UserNotFound(props){
    const classes = useStyles();

    const handleClose = ()=>{
        props.updateNextComponent("checkout")
        //setOpen2(true)
    }
    return(
        
        <Slide direction="right" in={true} mountOnEnter unmountOnExit>
        <div className={classes.body}>
              <Card className={classes.rootCard}>
                <CardContent>
                  <Grid item xs={12}  >
                    <div className = {classes.contentAvatar} >
                      <Avatar alt="A" src="" className = {classes.Avatar}></Avatar>
                      <Button variant="contained"  className={classes.buttonGoPage} disabled={true} >
                         
                      </Button>
                    
                    </div>
                    </Grid>
                  
                  <Grid container component = "main">
                  <div className = {classes.contentBodyCard} >
                    
                    <Grid item xs={12} style={{marginTop:40}} >
                      <div className={classes.dividerLeft}/>
                       Usuario no encontrado
                      <div className={classes.dividerRight}/>
                    </Grid>  

                    <Grid item xs = {12}>
                            
                            
                    </Grid>
                   
                    
                    </div>
                  </Grid>
                    
                
                </CardContent>
                
              </Card>
            </div>
            </Slide>
    )
}



