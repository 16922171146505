import React,{useState,useEffect} from "react";
import { makeStyles, ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { connect } from "react-redux";
import CssBaseline from '@material-ui/core/CssBaseline';
import Fab from '@material-ui/core/Fab';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { Grid, CircularProgress, Zoom, Typography, Avatar, Fade } from "@material-ui/core";
import AmountComponent from './../PlinkComponents/AmountComponent';
import CheckoutComponent from './../PlinkComponents/CheckoutComponent';
import  Card  from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import axios from 'axios';
import { CREDENTIALS_MASTERKEY, EndPoints, url } from '../../../constants/types';
import UserNotFound from "./../PlinkComponents/UserNotFound";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useMediaQuery } from 'react-responsive'
import SFProDisplay from '../../../Assets/fonts/Sf-Pro-Display.ttf'
import  CryptoJS  from 'crypto-js';
import IconButton from '@material-ui/core/IconButton';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
/* import drawing from '../img/drawing-1.svg' */
/* import money from './../img/money.svg' */
import { changePlinkComponent, getValueAmount, changePlinkPersonasComponent } from './../../../actions/index';
import LoginComponent from "./../PlinkComponents/LoginComponent";
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import VerticalDivider from "../../Plink/Layouts/VerticalDivider";
import HorizontalDivider from "../../Plink/Layouts/HorizontalDivider";
import Balance from "./PlinkPersonasComponents/Balance";
import SearchUserPlink from "./PlinkPersonasComponents/payComponents/SearchUserPlink";
import ReceivedPayComponent from "./PlinkPersonasComponents/receivedPayComponents/ReceivedPayComponent";
import IndexPayComponent from "./PlinkPersonasComponents/payComponents/IndexPayComponent";
import IndexBanksComponents from "./PlinkPersonasComponents/bankComponents/IndexBanksComponent";
const sf = {
  fontFamily: 'SF Pro Display',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('SF Pro Display'),
    
    url(${SFProDisplay}) format('woff')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const theme = createMuiTheme({
  typography: {
    fontFamily: 'SF Pro Display',
  },
  MuiAppBar: {
    background: 'transparent',
    boxShadow: 'none'
  },
  overrides: {
   MuiButton:{
      root:{
        '&:focus':{
          outline:'none'
        }
      }
   },
   mixins: {
    fixedToolbar: {
      minHeight: 70
    }
  },
   MuiCssBaseline: {
    '@global': {
      '@font-face': [sf],
    },
  },
   MuiFab:{
    root:{
      '&:focus':{
        outline:'none'
      }
    }
   },
    MuiOutlinedInput: {
      root: {
          '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
              borderColor: '#000',
          },
          '&$focused $notchedOutline': {
              borderColor: 'transparent',
              borderWidth: 1,
          },
      },
     
    },
  },
});

const useStyles = makeStyles((theme) => ({


  //Containter styles
  container:{
    minHeight: "100vh",
    overflow:'auto',

   
  },
  //Containter styles
  body:{
    border:"1px solid black",
    backgroundColor:"red",
    flexGrow: 1,
    height: '100vh',
    overflow:'auto',
    
    
  },

  //bar styles
  root: {
    display: 'flex',
    flexDirection: 'column',
   
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBar:{
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "transparent",//"#6630c2ff",
    backgroundImage:  "url('../img/drawing-2.svg')",
 /* backgroundSize:"contain", */
    //backgroundRepeat:"no-repeat",
    

    //backgroundImage: "linear-gradient(to right, rgb(102, 48, 194), #6730E3, rgba(83, 49, 177, 1), rgba(114, 48, 205, 1), rgba(150, 41, 230, 1))",
  },
  menuApp:{
    zIndex: 1201,
    position:"fixed",
    backgroundImage:  "url('../img/drawing-2.svg')",
    width:"100%",
    height:120,
    top:45,

    display:"flex",

  },
  appBarTitle:{
    fontFamily: "Sf Pro Display",
    fontSize:20,
    marginLeft:20
  },
  //CARD STYLE
  rootCard: {
    //minWidth: 275,
    width:"100%",
    /* margin: theme.spacing(0,45,0,45), */
    overflowY:'scroll',
    
   
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '& -ms-overflow-style': "none", 
    '& scrollbar-width': 'none',
      height:"100vh",
      alignItems:'center', 
      [theme.breakpoints.up("md")]:{
        /* margin: theme.spacing(0,45,0,45), */
      },
      [theme.breakpoints.down("md")]:{
        /* margin: theme.spacing(0,30,0,30), */
      },
      [theme.breakpoints.between("sm","md")]:{
        /* margin: theme.spacing(0,30,0,30), */
      },
      [theme.breakpoints.down("xs")]:{
        /* margin: theme.spacing(0,0,0,0), */
      }
  },
  
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  titleCard: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },

  fab: {
   
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    color: theme.palette.common.white,
    backgroundColor: "#25d366",
    position: 'fixed',
  },

  fabNext:{
    backgroundColor:"white",
    top:"35%",
    left:"-5%",
    width:50,
    height:50,
    color:"#8000FF",
    border:"1px solid #bababa"
  },
  fabBack:{
    backgroundColor:"white",
    top:"35%",
    width:50,
    zIndex: theme.zIndex.drawer + 1,
    height:50,
    left:"95%",
    color:"#8000FF",
    
    border:"1px solid #bababa"
  },

  contentAvatar:{
    width:'100%',
    
    textAlign:'center'
  },
  contentBodyCard:{
    width:'100%',
    
    textAlign:'center'
  },
  Avatar:{
    margin:"auto",
    height:60,
    width:60
  },
  AvatarMenu:{
    margin:"auto",
    height:50,
    width:50,
    backgroundColor:"white"
  },
  WelcomeFont:{
    textAlign:"center",
    fontFamily:"Comfortaa Light",
    fontSize:20
  },
  MenuTitle:{
    textAlign:"center",
    fontFamily:"Comfortaa Light",
    fontSize:15,
   
  },
  buttonGoPage:{
    margin:theme.spacing(8,0,0,0),
    borderRadius:'20px',
    backgroundImage: "linear-gradient(to right, rgb(102, 48, 194), #6730E3, rgba(83, 49, 177, 1), rgba(114, 48, 205, 1), rgba(150, 41, 230, 1))",
  },
  
  dividerLeft:{
      
    float:"left",
    marginTop:'8px',
    /*[theme.breakpoints.down("xs")]: {
      width:'23%',
    },*/
    width:'40%',
    borderTop: 'solid 1px #D6D6D6'

},
dividerRight:{
  float:"right",
    /*[theme.breakpoints.down("xs")]: {
      width:'25%',
    },*/
    marginTop:'8px',
    width:'40%',
    borderTop: 'solid 1px #D6D6D6'

},
marginTextFieldCardNumber:{
  width:'100%',
  textAlign: "center",
  fontSize:40,
  marginTop:20,
  '& fieldset': {
    borderRadius: 0,
    border:'solid 0px transparent',
    
  },
  "&::placeholder": {
    textAlign: "center",
    fontSize:40,
 
  },
  
  
  
},

buttonNext:{
  background:"#000",
  borderRadius:'20px',
  marginTop:80
},

toolbarIconTitle:{
  width:120,
  height:35,
  margin:theme.spacing(0,10),
  [theme.breakpoints.down("xs")]:{
    width:110,
    height:35,
    margin:theme.spacing(0,3),
  }
},
loadContainer:{
  //backgroundColor: "rgba(0, 0, 0, 0.1)",
  width: "100%",
  height: "100vh",
  textAlign: "center"
},
loader:{
  margin:'48%',
  [theme.breakpoints.down("xs")]:{
    margin:'45%'
  }
},

offset:theme.mixins.toolbar  // esto sirve para espaciar el contenido con el navbar

}));


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

 function PlinkPersonas(props) {
  

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1024px)'
  })
  //query: '(min-device-width: 1224px)'

  const isMobile = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })

  const classes = useStyles();
  const [nextComponenet,setNextComponent] = useState("amount") 
  const [loading,setLoading]=useState(false)
  const [phoneNumber, setPhoneNumber]=useState("")
  const [urlImage,setUrlImage]=useState("")
  const [userName,setUserName]=useState("")
  const [idCompany,setIdCompany]=useState()
  const [statusUser,setStatusUser]=useState(false)
  const [nameCompany,setNameCompany]=useState("")
  const [rif,setRif]=useState("")
  const [identificatorCommerce, setIdentificatorCommerce] = useState()
  const [masterKey,setMasterKey]=useState("")
  const [token,setToken] = useState("")
  const [exito, setExito] = useState(false)
  const [Amount, setAmount] = useState("")
  const [web_url,setWebUrl] = useState("")
  const [alertOpen,setAlertOpen]=useState(false)
  const [messageError, setMessageError]=useState()
  const [value, setValue] = React.useState(0);

  const handleChange = (event) => {
    //setValue(newValue);
    let dataPlink
    
    switch(event.target.id){
        case "balance":
            setValue(0)
            dataPlink = {
                changePlinkPersonasComponentValues:[{
                    nextComponent: "balance",
                    enabledBack:false,
                    enabledForw:false,
                    checkedBalance: true,
                    checkedPagar: false,
                    checkedRecibir: false,
                    checkedBanco: false,
                    containersComponents:""
                        
                  }]
                    
                }

            break;
        case "pagar":
            setValue(1)
            dataPlink = {
                changePlinkPersonasComponentValues:[{
                    nextComponent: "pagar",
                    enabledBack:false,
                    enabledForw:false,
                    checkedBalance: false,
                    checkedPagar: true,
                    checkedRecibir: false,
                    checkedBanco: false,
                    containersComponents:"search"    
                  }]
                    
                }
            break    
        case "recibir":
            setValue(2)
            dataPlink = {
                changePlinkPersonasComponentValues:[{
                    nextComponent: "recibir",
                    enabledBack:false,
                    enabledForw:false,
                    checkedBalance: false,
                    checkedPagar: false,
                    checkedRecibir: true,
                    checkedBanco: false,
                    containersComponents:"" 
                  }]
                    
                }
            break;  
        case "banco":
            setValue(3)
            dataPlink = {
                changePlinkPersonasComponentValues:[{
                    nextComponent: "banco",
                    enabledBack:false,
                    enabledForw:false,
                    checkedBalance: false,
                    checkedPagar: false,
                    checkedRecibir: false,
                    checkedBanco: true,
                    containersComponents:"bankData" 
                  }]
                    
                }
            break;
            
    }
      props.sendChangeComponent(dataPlink) 
      props.sendAmountValue(resetValuePlink())
  };

  useEffect(()=>{
    let id = props.match.params.id
    getPlinkUser(id)
    
  },[])


  const getPlinkUser = (props)=>{
    const Url = url+EndPoints.getPlinkUser
    
    setLoading(true)
    axios.post(Url,{
      UserPlink:props
    },{
      timeout:30000, 
    
    })
    .then(function(data){
      setLoading(false)
      if(data.data.Exito){
        setExito(true)
        data.data.Datos.map(Datos =>{
          let number_phone = Datos.numero_whatsapp
          
          if(Datos.numero_whatsapp.includes("-")){
            number_phone = number_phone.replace("-","")
            
          }

          if(Datos.numero_whatsapp.charAt(0)==='0'){
            number_phone = number_phone.replace("0","58")
            setPhoneNumber(number_phone) 
          }else{
            setPhoneNumber(Datos.numero_whatsapp)
          }
          setUrlImage(Datos.url_image)
          setUserName(Datos.nombre_usuario)
          setIdCompany(Datos.id_empresa)
          setStatusUser(Datos.status_usuario)
          setNameCompany(Datos.razon_social)
          setRif(Datos.rif)
          setIdentificatorCommerce(parseInt(CryptoJS.AES.decrypt(Datos.icmm, CREDENTIALS_MASTERKEY).toString(CryptoJS.enc.Utf8)))
          setMasterKey(CryptoJS.AES.decrypt( Datos.mtky, CREDENTIALS_MASTERKEY).toString(CryptoJS.enc.Utf8))
          setWebUrl(Datos.web_user)
          
        })
        setToken(data.data.Token)
        
      }else{
        return setExito(false)
      }
      
      //let token = data.data.token
      //setJwt(token)
      return
    })
    .catch(function(err){
      setLoading(false)
      
      let code = err.code
      if(code === "ECONNABORTED"){
        setAlertOpen(true)
        setMessageError("Error de timeout")
      }else{
       setAlertOpen(true)
       setMessageError("No se pudo encontrar al usuario por favor intente de nuevo "+err)
      }
      
      //window.top.location.href = urlCurrent; 
      
    })
  }

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };

  const CustomAlert = (props)=>{
    return(
      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleCloseAlert}>
      <Alert onClose={handleCloseAlert} severity="error">
        {props.message}
      </Alert>
    </Snackbar>
    )
  };

  const handleButtonsEvents =(event,component,type)=>{
    event.preventDefault()
    
    let comp;
    let dataPlink
    switch(component){
        case 1:
           if(type ==="forw"){
            dataPlink = {
                changePlinkPersonasComponentValues:[{
                    nextComponent: "pagar",
                    enabledBack:true,
                    enabledForw:false,
                    checkedBalance: false,
                    checkedPagar: true,
                    checkedRecibir: false,
                    checkedBanco: false,
                    containersComponents:"checkout"
                        
                  }]
                    
                }
           }else if(type==="back"){
            dataPlink = {
                changePlinkPersonasComponentValues:[{
                    nextComponent: "pagar",
                    enabledBack:false,
                    enabledForw:false,
                    checkedBalance: false,
                    checkedPagar: true,
                    checkedRecibir: false,
                    checkedBanco: false,
                    containersComponents:"search"
                        
                  }]
                    
                }
           }    
        break;
        case 3:
            comp = "banco"
            dataPlink = {
                changePlinkPersonasComponentValues:[{
                    nextComponent: comp,
                    enabledBack:true,
                    enabledForw:false,
                    checkedBalance: false,
                    checkedPagar: false,
                    checkedRecibir: false,
                    checkedBanco: true
                        
                  }]
                    
                }
        break;
    }
    
  
    return dataPlink
  }
  
  const resetValuePlink = ()=>{
    let amountValue = {
      getValuesPlink:[{
        amount:"",
        currency:"",
        dataUserPay:[],
        dataTrx:[]    
      }]
        
    }
  
    return amountValue
  }
  
    return (
      <ThemeProvider theme={theme}>
      <React.Fragment>
            <Grid container component = "main" className={classes.container,"container-plink"} >
                    <CssBaseline/>
                    <CustomAlert message ={messageError} />
                    
                    {isDesktopOrLaptop &&

                    <Grid item xs={12}  >
                            <div className={classes.root}>
                                <AppBar position="fixed" className={classes.appBar} elevation={0} >
                                    <Toolbar>
                                    <Typography className = {classes.appBarTitle}>Plink</Typography>
                                    <Typography className = {classes.title}></Typography>
                                    <NotificationsNoneOutlinedIcon fontSize={"large"} style = {{marginRight:20}}/>
                                    <ExitToAppOutlinedIcon fontSize={"large"}/>
                                    {/* <img src ="../img/logo-white-1x.png" className = { classes.toolbarIconTitle } alt="Plink"/> */}
                                    
                                    </Toolbar>
                                    
                                    <div  className = {classes.menuApp}>
                                        <Grid item xs = {3} >
                                            <Avatar alt="A"  className = {classes.Avatar}>{/* <Avatar alt="A" src={props.companyProps[0]} className={classes.large}  /> */}</Avatar>
                                            <Typography className = {classes.WelcomeFont}>¡Hola Lysa!</Typography>
                                        </Grid>
                                    
                                                
                                        <Grid item xs = {2} >
                                            
                                            
                                            <Avatar alt="A"  className = {classes.AvatarMenu}   onClick = {(e)=>{handleChange(e);props.sendAmountValue(resetValuePlink())}}  ><img alt = "balance" src="../img/money.svg" style = {{height:40,width:40}} id="balance"/></Avatar>
                                                <Typography className = {classes.MenuTitle}>Balance</Typography>

                                        
                                                
                                                <Fade in={value === 0 } mountOnEnter unmountOnExit timeout ={500}>
                                                    <div>
                                                        <HorizontalDivider/>
                                                    </div>
                                                </Fade>
                                                
                                                
                                            
                                            
                                        </Grid>
                                        <VerticalDivider/>

                                        <Grid item xs = {2}>
                                            <Avatar alt="A"  className = {classes.AvatarMenu}   onClick = {(e)=>{handleChange(e);props.sendAmountValue(resetValuePlink())}}><img alt = "balance" src="../img/pay.svg" style = {{height:40,width:40}} id="pagar"/></Avatar>
                                            <Typography className = {classes.MenuTitle}>Pagar</Typography>
                                            <Fade in={value === 1 } mountOnEnter unmountOnExit timeout ={500}>
                                                    <div>
                                                        <HorizontalDivider/>
                                                    </div>
                                            </Fade>
                                            
                                        </Grid>
                                        <VerticalDivider/>
                                        <Grid item xs = {2}>
                                            <Avatar alt="A"  className = {classes.AvatarMenu} onClick = {(e)=>{handleChange(e);props.sendAmountValue(resetValuePlink())}}><img alt = "balance" src="../img/dolar.svg" style = {{height:40,width:40}} id="recibir"/></Avatar>
                                            <Typography className = {classes.MenuTitle}>Recibir</Typography>    
                                            <Fade in={value === 2 } mountOnEnter unmountOnExit timeout ={500}>
                                                    <div>
                                                        <HorizontalDivider/>
                                                    </div>
                                            </Fade>                
                                        </Grid>
                                        <VerticalDivider/>
                                        <Grid item xs = {2}>
                                            <Avatar alt="A"  className = {classes.AvatarMenu} onClick = {(e)=>{handleChange(e);props.sendAmountValue(resetValuePlink())}}><img alt = "balance" src="../img/iconbank.jpg" style = {{height:40,width:40}} id="banco"/></Avatar>
                                            <Typography className = {classes.MenuTitle}>Cuenta bancaria</Typography>
                                            <Fade in={value === 3 } mountOnEnter unmountOnExit timeout ={500}>
                                                    <div>
                                                        <HorizontalDivider/>
                                                    </div>
                                            </Fade>
                                        </Grid>
                                                        
                                    </div>
                                </AppBar>
                            
                            </div>
                        
                        </Grid>
                        
                    }

                    <div className={classes.offset}></div>

                    {/*Card Principal junto con sus flechas */}
                    
                    
                    <Grid item xs = {props.changePlinkPersonasComponentValues[0].nextComponent === "balance" ? 3:4} /* style={{backgroundColor:"red"}} */>
                        {
                        
                        /* props.changePlinkComponentValues[0].enabledBack && */
                        <Zoom in = {props.changePlinkPersonasComponentValues[0].enabledBack}>
                            <Fab color={classes.fabGreen} aria-label="add" className={classes.fabBack} onClick ={(e)=>{
                            props.sendChangeComponent(handleButtonsEvents(e,value,"back"))
                            props.sendAmountValue(resetValuePlink())
                            }}>
                                <NavigateBeforeIcon />
                                <NavigateBeforeIcon style={{marginLeft:-15}} />
                            </Fab>
                        </Zoom>
                        
                        }
                    </Grid>
                        
                    <Grid item xs = {props.changePlinkPersonasComponentValues[0].nextComponent === "balance" ? 6:4} /* style={{backgroundColor:"green",}} */>
                        <Card className={classes.rootCard}>
            
            
        
                            <CardContent className={classes.cardcontent}>
                            
                            {loading && 
                                <div className={classes.loadContainer}>
                            
                                
                                <CircularProgress className={classes.loader} />
                            
                                </div>
                            }
                            {/* <CheckoutComponent updateNextComponent = {nextComponent} propsTrx={[rif,identificatorCommerce,token,Amount]} companyProps ={[urlImage,userName,web_url]}/> */}
                           {/*  {props.changePlinkComponentValues[0].nextComponent==="checkout" ?
                                        
                                <LoginComponent/>        
                                
                                        : 
                            props.changePlinkComponentValues[0].nextComponent==="amount" && exito?
                                    
                                        <AmountComponent updateNextComponent ={nextComponent} companyProps={[urlImage,userName,web_url]}  />
                                    
                                        
                                    :
                            <UserNotFound/>} */}
                                {
                                    props.changePlinkPersonasComponentValues[0].nextComponent === "balance" ? 
                                    <Balance/> :
                                    props.changePlinkPersonasComponentValues[0].nextComponent === "pagar" ?
                                    <IndexPayComponent/>:
                                    props.changePlinkPersonasComponentValues[0].nextComponent === "recibir" ?
                                    <ReceivedPayComponent/>:
                                    props.changePlinkPersonasComponentValues[0].nextComponent === "banco" ?
                                    <IndexBanksComponents/>:
                                    ""
                                }
                                
                            </CardContent>
                
                        </Card> 
                    </Grid>
                    
                    <Grid item xs = {props.changePlinkPersonasComponentValues[0].nextComponent === "balance" ? 3:4} /* style={{backgroundColor:"blue"}} */>
                    {
                    
                        /* props.changePlinkComponentValues[0].enabledForw && props.getValuesPlink[0].amount !=="" && props.getValuesPlink[0].amount !==0 && */
                        <Zoom in = {props.changePlinkPersonasComponentValues[0].enabledForw && props.getValuesPlink[0].amount !=="" && props.getValuesPlink[0].amount !==0}>
                        <Fab color={classes.fabGreen} aria-label="add" className={classes.fabNext} onClick ={(e)=>props.sendChangeComponent(handleButtonsEvents(e,value,"forw"))} >
                            
                            <NavigateNextIcon style={{marginRight:-15}}/>
                            <NavigateNextIcon />
                        
                        </Fab>
                        </Zoom>
                        
                    }
                    
                    </Grid>    
                {/* 
                    <Fab color={classes.fabGreen} aria-label="add" className={classes.fab}>
                    <a href ={`https://wa.me/${phoneNumber}`} style={{color:'white'}}><WhatsAppIcon fontSize="large"/></a>
                    </Fab> */}
            </Grid>
      
      </React.Fragment>
      </ThemeProvider>
    );
  }

  const mapStateToProps = (state)=>{
    return {
            changePlinkPersonasComponentValues : state.plinkReducer.changePlinkPersonasComponentValues,
            getValuesPlink: state.plinkReducer.getValuesPlink
        }
  }
  
  const mapDispatchToProps = (dispatch) =>({
    sendChangeComponent(component){
       
        /* dispatch(changePlinkComponent(component))  */
        dispatch(changePlinkPersonasComponent(component))
        
    },
    sendAmountValue(monto){
        dispatch(getValueAmount(monto))
    }
  }) 


  export default connect(mapStateToProps,mapDispatchToProps)(PlinkPersonas);
