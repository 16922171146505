import React from "react";
import { makeStyles} from '@material-ui/core/styles';

const useStyle = makeStyles((theme)=>({

    divider:{
        height:50,
        border:"1px solid #ffffffff"
    }

}))

function VerticalDivider (props){
    const classes = useStyle()

    return(
        <div className = {classes.divider}>
            {props.children}
        </div>
    )
}

export default VerticalDivider
