import { CHANGE_PLINK_COMPONENT, GET_VALUES_PLINK,CHANGE_PLINK_PERSONAS_COMPONENT, GET_BROWSER_AGENT } from './../../constants/types';

const initialState = {
    changePlinkComponentValues:[
        {
            nextComponent: "amount",
            enabledBack: false,
            enabledForw:true,
            checkedAmount:true,
            checkedLogin:false,
        }
    ],
    changePlinkPersonasComponentValues:[
        {
            nextComponent:"balance",
            enabledBack:false,
            enabledForw:false,
            checkedBalance:true,
            checkedPagar:false,
            checkedRecibir:false,
            checkedBanco:false,
            containersComponents: ""
        }
    ],
    getValuesPlink:[
        {
            amount:"",
            currency:"",
            dataUserPay:[],
            dataTrx:[],
            statusTrx:[]
        }
    ],
    
    getDataUserPlink:[
        {
            balance:[],
            bankAccount:[]
        }
    ],

    getBrowserAgent:[
        {
            ipaddress:"",
            agent: "",
            manufacturer: "",
            model:"",
            os_version:"",
            lat:"",
            lng:"",
        }  
    ],
    
}

const plinkReducer = (state = initialState, action) => {
   
    
    
    
    switch (action.type) {
        case CHANGE_PLINK_COMPONENT:
                state = {
                     ...state, 
                    changePlinkComponentValues: action.changePlinkComponent.changePlinkComponentValues

                };
               
            break;

        case GET_VALUES_PLINK:
            
            state = {
                ...state,
                getValuesPlink:action.getValueAmount.getValuesPlink

            }
            break;   
        
        case CHANGE_PLINK_PERSONAS_COMPONENT:
            state = {
                ...state,
                changePlinkPersonasComponentValues:action.changePlinkPersonasComponent.changePlinkPersonasComponentValues
            }
            break;  
            
        case GET_BROWSER_AGENT:
            
            state = {
                ...state,
                getBrowserAgent: action.browserAgent.getBrowserAgent
            }
            break;  
        default:
            break;
    }
    return state
};


export default plinkReducer;