import React,{useState} from "react";
import { makeStyles , withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import{ Grid, Avatar, Slide, Box, InputBase, NativeSelect } from "@material-ui/core";

import CurrencyInput from 'react-currency-input';
import { connect } from 'react-redux';
import { getValueAmount } from "../../../actions";


const useStyles = makeStyles((theme) => ({


    //Containter styles
    container:{
      minHeight: "100%"
    },
    //Containter styles
    body:{
  
      flexGrow: 1,
      height: '100vh',
      paddingTop:"5%"
      
    },
    //bar styles
    root: {
      display: 'flex',
      flexDirection: 'column',
      
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    appBar:{
      zIndex: theme.zIndex.drawer + 1,
      backgroundImage: "linear-gradient(to right, rgb(102, 48, 194), #6730E3, rgba(83, 49, 177, 1), rgba(114, 48, 205, 1), rgba(150, 41, 230, 1))",
    },
  
 
    //CARD STYLE
    rootCard: {
      minWidth: 275,
      margin: theme.spacing(0,0,0,0),
      height:"100%",
      alignItems:'center',
      [theme.breakpoints.down("xs")]:{
        margin: theme.spacing(0,0,0,0),
      }
    },
   
    titleCard: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  
    fab: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      color: theme.palette.common.white,
      backgroundColor: "#25d366",
  
    },
    contentAvatar:{
      width:'100%',
      //backgroundImage:`url(${"../img/bg_icons_market-web.png"})`,
      textAlign:'center',
      [theme.breakpoints.up("lg")]:{
        marginTop:"10%"
      },
      
    },
    NativeSelect:{
      [theme.breakpoints.up("lg")]:{
        marginTop:"15%"
      },
    },
    contentBodyCard:{
      width:'100%',
      
      textAlign:'center'
    },
    Avatar:{
      margin:'auto',
      height:70,
      width:70,
      boxShadow: "1px 2px 3px #888888",
      backgroundColor:"#ededed"
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    /* buttonGoPage:{
      margin:theme.spacing(8,0,0,0),
      borderRadius:'20px',
      backgroundImage: "linear-gradient(to right, rgb(102, 48, 194), #6730E3, rgba(83, 49, 177, 1), rgba(114, 48, 205, 1), rgba(150, 41, 230, 1))",
    }, */
    Username:{
      margin:theme.spacing(4,0,0,0),
      fontFamily: 'Comfortaa Light',
      fontSize:25,
      color:"#8000FF"
    },
    
    dividerLeft:{
        
      float:"left",
      marginTop:'8px',
      /*[theme.breakpoints.down("xs")]: {
        width:'23%',
      },*/
      width:'30%',
      borderTop: 'solid 1px #D6D6D6',
      [theme.breakpoints.down("xs")]:{
        width:'25%',
      }
  
  },
  dividerRight:{
    float:"right",
      /*[theme.breakpoints.down("xs")]: {
        width:'25%',
      },*/
      marginTop:'8px',
      width:'30%',
      borderTop: 'solid 1px #D6D6D6',
      [theme.breakpoints.down("xs")]:{
        width:'25%',
      }
  },
  marginTextFieldCardNumber:{
    width:'100%',
    textAlign: "center",
    fontSize:50,
    marginTop:20,
    borderRadius: 0,
    color:"grey",
    border:'solid 0px transparent',
    fontFamily: 'Comfortaa Light',
    
    "&::placeholder": {
      textAlign: "center",
      fontSize:50,
    },

    '&:focus':{
        outline:'none'
      }

  },
  
  buttonNext:{
    background:"#000",
    borderRadius:'20px',
    marginTop:30,
    width:150,
   
  },
  footer:{
    textAlign:'center',
    bottom:0,
    right:0,
    position: "absolute",
    width: "100%",
    fontSize:'10px',
    
  },
  footerFont:{
    fontFamily: 'Comfortaa Light',
    color:"#8000FF"
  }
  
  }));

const CustomSelect =  withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3)
      }
    },
    input: {
      borderRadius: 6,
      position: "relative",
      //backgroundColor: theme.palette.background.paper,
      border: "2px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      width:70,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      
      fontFamily: [
        "Comfortaa Light"
      ].join(","),
      "&:focus": {
        color:"#8000FF",
        borderRadius: 6,
        borderColor: "#8000FF",
        boxShadow: "0 0 0 0.2rem rgba(128,0,255,.25)"
      }
    },
    icon:{
      right:20
    }
  }))(InputBase);

function AmountComponent(props){
    const classes = useStyles();
    const [amount,setAmount] = useState("")
    const [currency, setCurrency] = useState(1)
    
    
    function validate(e){
        var key = window.event ? e.which : e.keyCode;
       
        if (key < 48 || key > 57) {
          e.preventDefault();
        }
        if(e.target.value.length === 13){
          e.preventDefault();
        }
           
    }

    function handleChange(event, maskedvalue, floatvalue){
        setAmount(maskedvalue);  
        props.sendAmountValue(handleValue(maskedvalue,currency))
    }

    function handleChangeCurrency(e){
      let value = e.target.value
      setCurrency(value)
      props.sendAmountValue(handleValue(amount,value))

    }

    const handleValue =(amount,currency)=>{
  
      let amountValue = {
        getValuesPlink:[{
          amount: amount,
          currency:currency,  
        }]
          
      }
    
      return amountValue
    }


    const noPaste = (e)=>{
      e.preventDefault(); 
      return false
    }

  
    

    return(
        
        <Slide direction="right" in={props.changePlinkComponentValues[0].checkedAmount} mountOnEnter unmountOnExit  >
          
            <div className={classes.body}>
        
                  <Grid item xs={12}  >
                    <div className = {classes.contentAvatar} >
                      <Avatar alt="A"  className = {classes.Avatar}><Avatar alt="A" src={props.companyProps[0]} className={classes.large}  /></Avatar>
                      <Typography className ={classes.Username}> Lysa Fox</Typography>
                     {/*  <Button variant="contained" color="primary" className={classes.buttonGoPage}  >
                        <a href={props.companyProps[2] } style={{color:'white'}}>Ir a la web</a>
                      </Button> */}
                    
                    </div>
                    </Grid>
                   
                  <Grid container component = "main">
                 
                      <div className = {classes.contentBodyCard} >
                 
                    {/* <Grid item xs={12} style={{marginTop:40}} >
                   
                      <div className={classes.dividerLeft}/>
                        weplink.me/{props.companyProps[1]}
                      <div className={classes.dividerRight}/>
                    </Grid>  */} 

                    <Grid item xs = {12}>
                            <form autoComplete="off" component ='form'>
                          
                                <CurrencyInput 
                                          
                                          variant="outlined"
                                          placeholder ="0,00"
                                          margin="normal"
                                          value={amount}
                                          required
                                          size='small'
                                          id="amount"
                                          name="amount"
                                          className = {classes.marginTextFieldCardNumber}
                                          onChange ={handleChange}
                                          //disabled = {loader}
                                          autoFocus
                                          onPaste={noPaste}
                                          inputType = 'tel'
                                          onKeyPress={validate}
                                          autoComplete="off"
                                          decimalSeparator="," 
                                          thousandSeparator="."
                                                
                                          
                                          
                                      /> 
                                      <NativeSelect
                                      className={classes.NativeSelect}
                                      id="demo-customized-select-native"
                                      //value={age}
                                      onChange={handleChangeCurrency}
                                      
                                      input={<CustomSelect />}
                                    
                                    >
                                       
                                        <option value={1}>USD</option>
                                        <option value={2}>BS</option>
                                       
                                    </NativeSelect>
                                   

                            </form>  
                           {/*  <Button variant="contained"  color="primary" className={classes.buttonNext} onClick={handleClose} >
                         Siguiente
                      </Button> */}
                    </Grid>
                   
                   
                    </div>
                  
                  </Grid>
                 
                  <Box mt={1} mb ={2}  className = {classes.footer}>
                        <div >
                            <Typography className = {classes.footerFont} >El destinatario recibirá <strong>bs </strong>a tasa de <strong>BCV</strong></Typography>
                        </div>
                        
                  </Box > 
                  
            </div>
        
         </Slide> 
    )
}
const mapStateToProps = (state)=>{
  return {
    changePlinkComponentValues:state.plinkReducer.changePlinkComponentValues
       /*  checkedAmount:state.plinkReducer.changePlinkComponentValues[0].checkedAmount,
        checkedLogin:state.plinkReducer.changePlinkComponentValues[0].checkedLogin */
      }
}
const mapDispatchToProps = (dispatch)=>({
    sendAmountValue(monto){
      dispatch(getValueAmount(monto))
    }
})
export default  connect(mapStateToProps,mapDispatchToProps)(AmountComponent);



