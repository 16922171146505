import { SIGNUP_DIALOG } from '../../constants/types.js';


const initialState = {
    openDialog: false,
    status: false
}

const signUpDialog = (state = initialState, action) => {
   
    
    
    switch (action.type) {
        case SIGNUP_DIALOG:
                state = {
                    ...state, 
                    openDialog: action.signUpDialog.openDialog,
                    status: action.signUpDialog.status,
                    token: action.signUpDialog.token
                };
               
            break;
            
        default:
            break;
    }
    return state
};


export default signUpDialog;