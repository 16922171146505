import { Button, Grid, makeStyles, Slide, Typography } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import { changePlinkPersonasComponent, getValueAmount } from './../../../../../actions/index';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import { withStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme)=>({
    body:{
        flexGrow: 1,
        height: '100vh',
      /*   paddingTop:"20%"  */  
    },
    message:{
        fontSize :24,
        fontFamily:"Sf Pro Display",
        textAlign:"center",
        color:"#707070"
    },
    ref:{
        fontSize :24,
        fontFamily:"Sf Pro Display",
        textAlign:"center",
        color:"#707070",
        marginTop:"10%",
        
    },
    titleContainer:{
        width:"100%",
        height:"50%",
        marginTop:30,
        
        
    },
    iconContainer:{
        width:"100%",
        textAlign:"center",
        marginTop:"45%",
    },
    buttonContainer:{
        display: "flex",
        justifyContent:"center",
        alignItems:"center",
        /* align-items: center; */
        
    }
    
}))

const PayButton =  withStyles((theme) => ({
    root: {
      width:'60%',
      height:'40px',
      fontSize:'15px',
      
      backgroundColor: '#6730E3',
      '&:hover': {
        backgroundColor:'#6730E3',
      },
  
    },
  }))(Button);

  function FinalizedPayComponent ({getValuesPlink, sendAmountValue,sendChangeComponent}){
    const classes = useStyle()


    const handlerFinalizedPayPlink = ()=>{
          let amountValue = {
            getValuesPlink:[{
              amount:"",
              currency:"",
              dataUserPay:[],
              dataTrx:[],
              statusTrx:"" 
            }]
              
          }
        
          return amountValue
    }
    
    const handlerNextComponentFinalizedPayPlink = ()  =>{
        let dataPlink = {
             changePlinkPersonasComponentValues:[{
                 nextComponent: "pagar",
                 enabledBack:false,
                 enabledForw:false,
                 checkedBalance: false,
                 checkedPagar: true,
                 checkedRecibir: false,
                 checkedBanco: false,
                 containersComponents:"search"
                     
             }]
             
         }
             return dataPlink
     }

      const FinalizedPayment = ()=>{
          sendAmountValue(handlerFinalizedPayPlink())
          sendChangeComponent(handlerNextComponentFinalizedPayPlink())
      }

    return(

        <React.Fragment>
            <Slide direction="left" in={true} mountOnEnter unmountOnExit>

                <div className={classes.body}>
                
                    <Grid container>
                        <Grid item xs={12}>
                            <div className={classes.iconContainer}>
                                {
                                    getValuesPlink[0].statusTrx.exito?
                                    <CheckCircleOutlineRoundedIcon  style={{textAlign:"center",color:"#47DB87",height:50,width:50}}/>:
                                    <HighlightOffRoundedIcon  style={{textAlign:"center",color:"#eb4a31",height:50,width:50}}/>


                                } 
                            </div>
                            <div className={classes.titleContainer}>
                                {
                                    getValuesPlink[0].statusTrx.exito?
                                    <Typography className ={classes.message}>{`¡Haz pagado ${getValuesPlink[0].amount} ${getValuesPlink[0].currency===1? "$":"Bs"} a Elena!`}</Typography>:
                                    <Typography className ={classes.message}>¡Pago rechazado!</Typography>


                                }
                                <Typography className ={classes.ref}><strong>{`Ref N°: ${getValuesPlink[0].statusTrx.ref}`}</strong> </Typography>           
                            </div>
                            <div className={classes.buttonContainer}>
                                <PayButton variant="contained" color="primary" onClick = {FinalizedPayment}>Realizar nuevo pago</PayButton>
                            </div>
                            
                        </Grid>
                    </Grid>
                </div>
            </Slide>
        </React.Fragment>
    )
}
const mapStateToProps = (state)=>{
    
    return {
        changePlinkPersonasComponentValues : state.plinkReducer.changePlinkPersonasComponentValues,
        getValuesPlink:state.plinkReducer.getValuesPlink
        }
}

const mapDispatchToProps = (dispatch) =>({
    sendChangeComponent(component){
       
        /* dispatch(changePlinkComponent(component))  */
        
        dispatch(changePlinkPersonasComponent(component))
        /* dispatch(getValueAmount()) */
        
    },
    sendAmountValue(data){
    
        dispatch(getValueAmount(data))
    }
})
export default connect(mapStateToProps,mapDispatchToProps)(FinalizedPayComponent) 