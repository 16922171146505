import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { personalData } from "../../../actions/index";
import { Grow, Button, Input   } from "@material-ui/core";
import _data from "../../../data";
import ButtonGroup  from '@material-ui/core/ButtonGroup';
import { Context } from "react-responsive";
import CryptoJS from 'crypto-js'
import { ConstantsCrypto, EndPoints } from "../../../constants/types";
import { url } from './../../../constants/types';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { signUpDialog } from './../../../actions/index';
import {adicion} from 'appinventors3uploader'

function PersonalData ({personal,sendPersonalData,OpenSignupDialogHandler}) {
    
    const [state,setState] = useState({
        user: "",
        email: "",
        password: "",
        accountType:"personal",
        Context:"",
    })

    const [isValid, setIsValid]= useState(true)

    const [formValid, setFormValid] = useState({
        userValid:0,
        emailValid:0,
        passwordValid:0,
        
    })

    const [isUserExist, setUserExist] = useState()

    const [isMailExist, setMailExist] =useState()

    const [isErrorServer, setErrorServer] = useState({
      userExistError:false,
      mailExistError:false
    })

    const[isLoading, setLoading] = useState({
      loadingUserExist:false,
      loadingMailExist:false
    })

    const [isLoadingSignup, setLoadingSignup]= useState(false)

    const [isVisiblePass, setVisiblePass] =useState(false)

    const changeBtnText = btnText => {
        setState(prevState => ({
            ...prevState,
            btnText: btnText
        }));
       /*  setState(...state,{ btnText: btnText }); */
    };

    function handleFormValueChange(inputName, event) {
        let stateValue ;
        stateValue =
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value;
            
            setState((prevState) => ({
                ...prevState,
               [inputName] : stateValue
            }));
            
         /* setState(stateValue); */
    }
    
    const changeContext = Context =>{
        
        setState((prevState) => ({
            ...prevState,
            Context: Context
        }));
    }

    function handleSubmit(e) {
      e.preventDefault()
    
        for(const property in formValid){
          if(formValid[property] === 0){
            setFormValid((prevState) => ({
              ...prevState,
              [property]: 2
            }));
          }
        }
      
       if(formValid.userValid === 1 && formValid.emailValid === 1 && formValid.passwordValid ===1 && !isUserExist && !isMailExist && !isErrorServer.userExistError && !isErrorServer.mailExistError){
         return true
       } 
       else{
         return false
       }
    }
      
    function handleFileUpload(event){
        document.getElementById("file").click();  
    }
    
    function handleClickGroup(type,event){
         
        if(type==="personal"){
          setState((prevState)=>({
                ...prevState,
                accountType:"personal"
            }))
        }else {
            setState((prevState)=>({
                ...prevState,
                accountType:"negocio"
            }))
        }
      
    }

    const handlerFormFields = (e, name) =>{
      
      let field = e.target.value
      let correct;
      let matcher;
      let match;
      switch(name){
        case "userValid":
          match = matchUser(field)
          
          correct = (
            field ==="" || field.length < 3? 2:
            match ===false ? 2:
            1 
          )
          break;
        case "emailValid":
            matcher = matchEmail(field)
            
            correct = (
              field ==="" ? 2:
              matcher ===false ? 2:
              1 
            )
          break;
        case "passwordValid" :
          
          correct = (
            field ==="" ? 2:
            field !== "" && field.length < 6 ? 2:
            1 
          )
        break;
        case "imageValid" :
          /* let fieldFile = document.getElementById("file")
          let file = fieldFile.files[0]
         
          var reader = new FileReader();
          reader.onload = function(event) {
            // El texto del archivo se mostrará por consola aquí
            
          };

          reader.readAsText(file) */
          correct = (
            field ==="" ? 2:
            1 
          )
        break;

      }
       
      setFormValid((prevState) => ({
        ...prevState,
        [name]: correct
      }));
      
    }

    const matchUser = (user)=>{
      var regex = new RegExp("^[a-zA-Z0-9]+$")
      if(regex.test(user)){
        return true
      }else{
        return false
      }
    }
    
    const matchEmail = (email) =>{
      let pattern = /^([\da-z_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/
      if(pattern.exec(email)){
        return true
      }else{
        return false
      }
    }

   
    const buildData = () =>{
     
      let personalData = [{
        user: CryptoJS.AES.encrypt(state.user,ConstantsCrypto.MasterKeyAddUser).toString(),
        email :CryptoJS.AES.encrypt(state.email,ConstantsCrypto.MasterKeyAddUser).toString(),
        password :CryptoJS.AES.encrypt(state.password,ConstantsCrypto.MasterKeyAddUser).toString(),
        accountType :state.accountType,
      }]

      let companyData = [{
          razon_social: null,
          rif :null,
          cuenta_bancaria:null,
          foto:null,
          foto_rif:null,
          referencia_bancaria:null,
          location :null,
          whatsapp :null,
          isAgreed: null, 
        }]
      

      return [personalData,companyData]
    } 

    const getUserPlinkExist = (e)=>{
      
        
        const Url =process.env.REACT_APP_URL_BASE+process.env.REACT_APP_GET_PLINK_USER //url+EndPoints.getPlinkUser
        setLoading((prevState)=>({
          ...prevState,
          loadingUserExist:true
        }))
        //setLoading(true)
        axios.post(Url,{
          UserPlink:e.target.value,
          UserAvalailable:true
        },{
          timeout:30000, 
        
        })
        .then(function(data){
          setLoading((prevState)=>({
            ...prevState,
            loadingUserExist:false
          }))

          if(isErrorServer.userExistError){
            setErrorServer((prevState)=>({
              ...prevState,
              userExistError:false
            }))
          }

          if(data.data.Exito){
            setUserExist(true)
          }else{
            return setUserExist(false)
          }
          
          //let token = data.data.token
          //setJwt(token)
          return
        })
        .catch(function(err){
          setLoading((prevState)=>({
            ...prevState,
            loadingUserExist:false
          }))
          
          setErrorServer((prevState)=>({
            ...prevState,
            userExistError:true
          }))
          //window.top.location.href = urlCurrent; 
          
        })
      
    }

    const getMailPlinkExist = (e)=>{
      const Url =process.env.REACT_APP_URL_BASE+process.env.REACT_APP_GET_PLINK_MAIL //url+EndPoints.getPlinkUser
        
      setLoading((prevState)=>({
        ...prevState,
        loadingMailExist:true
      }))
        axios.post(Url,{
          MailPlink:e.target.value,
          
        },{
          timeout:30000, 
        
        })
        .then(function(data){
          setLoading((prevState)=>({
            ...prevState,
            loadingMailExist:false
          }))

          if(isErrorServer.mailExistError){
            setErrorServer((prevState)=>({
              ...prevState,
              mailExistError:false
            }))
          }
          
          if(data.data.Exito){
            setMailExist(true)
          }else{
            return setMailExist(false)
          }
          
          //let token = data.data.token
          //setJwt(token)
          return
        })
        .catch(function(err){
          setLoading((prevState)=>({
            ...prevState,
            loadingMailExist:false
          }))
          
          setErrorServer((prevState)=>({
            ...prevState,
            mailExistError:true
          }))
          
          //window.top.location.href = urlCurrent; 
          
        })
    }

    const handleVisibilityPass = (e)=>{
      
      if(isVisiblePass){
        setVisiblePass(false)
      }else{
        setVisiblePass(true)
      }
    }

    useEffect(() => {
      setState(prevState => ({
          ...prevState,
          signup:_data.signup
      }));
      
      changeContext("personalData")
     /* setState({signup: _data.signup}) */
   }, [Context]);

   const addNewUserPlink= async(e)=>{ 
      e.preventDefault()
    
   
        
          setLoadingSignup(true)
        
        const Url =process.env.REACT_APP_URL_BASE+process.env.REACT_APP_ENDPOINT_ADDPLINK_USER // url+EndPoints.addPlinkUser //process.env.REACT_APP_URL_BASE+process.env.REACT_APP_ENDPOINT_ADDPLINK_USER

        let builder = buildData()
        let json = {
          companyData:builder[1],
          personalData:builder[0]
        }
        try{
          const response = await axios.post(Url,json)
          if(response.status ===200){
            setLoadingSignup(false)
            if(response.data.exito){
              
              let userArray = [{
                user:builder[0].user,
                password:builder[0].password
              }]
              let token = CryptoJS.AES.encrypt(JSON.stringify(userArray[0]),process.env.REACT_APP_MASTER_KEY_ADD_USER).toString() //proccess.env.REACT_APP_MASTER_KEY_ADD_USER
              
              OpenSignupDialogHandler(true,token)
            }else{
             
              OpenSignupDialogHandler(false,null)
            }
          }
          
        }catch(error){
          
          setLoadingSignup(false)
          OpenSignupDialogHandler(false,null)
        }
        
        return
   
   
   
    
}

    

    return (
        <React.Fragment>
        
                <Grow   in={true} mountOnEnter unmountOnExit>
                      <form
                        className="login-signup-form"
                        method="post"
                        style={{marginTop:30}}
                        autoComplete="off"
                      >
                        <div className="form-group">
                        <label style={{marginBottom:5}}>Usuario</label>
                          <div className="input-group input-group-merge">
                            

                            
                            <div className="input-icon">
                              <span className="ti-user color-primary"></span>
                              
                            </div>
                            
                          {
                            isLoading.loadingUserExist &&
                            <div className="input-end-icon" onClick = {handleVisibilityPass}>
                             
                             <CircularProgress  style={{marginTop:10,marginBottom:10, height:30,width:30}} />
                             </div>
                          }
                            
                            <input
                             
                              onChange={e =>
                                handleFormValueChange("user", e)
                              }
                              type="text"
                              name="name"
                              className={
                                formValid.userValid === 0 || isLoading.loadingUserExist ? "form-control": 
                                formValid.userValid === 1 && !isUserExist?"form-control is-valid":
                              
                                "form-control is-invalid"
                                }
                              placeholder={state.accountType === "personal"?"weplink.me/pedro":"weplink.me/tienda999"}
                              onBlur ={(e)=>{
                                getUserPlinkExist(e)
                                handlerFormFields(e,"userValid")
                              }
                              }
                              onKeyPress = {(e)=>{handlerFormFields(e,"userValid")}}
                              required
                              readOnly = {isLoading.loadingUserExist || isLoading.loadingMailExist}
                              
                            
                            />


                            <div className="valid-feedback">
                            {
                                isUserExist !== undefined && !isUserExist? "Usuario disponible!":"Formato Correcto!."
                            }
                                  
                            </div>
                            <div className="invalid-feedback">
                              {
                                isUserExist !== undefined && isUserExist? "Usuario existente!":
                                isErrorServer.userExistError ?"Error en el servidor":
                                "Formato incorrecto !Por favor verifique el campo."
                              }
                                  
                            </div>

                            
                          </div>
                        </div>
                        
                        <div className="form-group">
                          <label style={{marginBottom:10}}>Correo electrónico</label>
                          <div className="input-group input-group-merge">
                            <div className="input-icon">
                              <span className="ti-email color-primary"></span>
                            </div>
                           
                            {
                            isLoading.loadingMailExist &&
                            <div className="input-end-icon" onClick = {handleVisibilityPass}>
                             
                             <CircularProgress  style={{marginTop:10,marginBottom:10, height:30,width:30}} />
                             </div>
                          }
                           
                            <input
                              value={state.email}
                              onChange={e =>
                                handleFormValueChange("email", e)
                              }
                              type="email"
                              name="email"
                              className={
                                formValid.emailValid === 0 || isLoading.loadingMailExist ? "form-control": 
                                formValid.emailValid === 1 && !isMailExist ?"form-control is-valid":
                                "form-control is-invalid"
                                }
                              placeholder="nombre@correo.com"
                              onBlur ={(e)=>{
                                getMailPlinkExist(e)
                                handlerFormFields(e,"emailValid")
                              }}
                              onKeyPress = {(e)=>{handlerFormFields(e,"emailValid")}}
                              required
                              readOnly={isLoading.loadingUserExist || isLoading.loadingMailExist}
                            />
                            <div className="valid-feedback">
                                  Formato Correcto!
                            </div>
                            <div className="invalid-feedback">
                              {
                                  isMailExist !== undefined && isMailExist? "Correo ya registrado!":
                                  isErrorServer.mailExistError ?"Error en el servidor":
                                  "Formato incorrecto !Por favor verifique el campo."
                              }
                            </div>
                          </div>
                        </div>
  
                        <div className="form-group">
                          <label style={{marginBottom:10}}>Password</label>
                          
                          <div className="input-group input-group-merge">
                            <div className="input-icon">
          
                              <span className="ti-lock color-primary"></span>
                            </div>
                           
                            <div className="input-end-icon" onClick = {handleVisibilityPass}>
                             
                            {isVisiblePass  ?<VisibilityOutlinedIcon className = "color-primary" style = {{marginTop:15, marginBottom:15}} />:
                              <VisibilityOffOutlinedIcon className = "color-primary" style = {{marginTop:15, marginBottom:15}} />
                            }  
                            </div>
                            
                            <input
                              value={state.password}
                              onChange={e =>
                                handleFormValueChange("password", e)
                              }
                              type={isVisiblePass ? "text":"password"}
                              name="password"
                              className={
                                        formValid.passwordValid === 0 ? "form-control": 
                                        formValid.passwordValid === 1 ?"form-control is-valid":
                                        "form-control is-invalid"
                                        }
                              placeholder="Ingresa tu contraseña"
                              onBlur ={(e)=>{handlerFormFields(e,"passwordValid")}}
                              onKeyPress = {(e)=>{handlerFormFields(e,"passwordValid")}}
                              required
                              readOnly = {isLoading.loadingUserExist || isLoading.loadingMailExist}
                              id= "input-pwd"
                            />


                            <div className="valid-feedback">
                                  Formato Correcto!
                            </div>
                            <div className="invalid-feedback">
                                  Formato incorrecto !Por favor verifique el campo.
                            </div>
                            
                          </div>
                        </div>
                        
                        

                        <div className="form-group">
                          <label style={{marginBottom:10}}>Tipo de Cuenta</label>
                          <ButtonGroup  variant="contained" color="primary" style={{width:'100%'}} >
                        
                              <Button 
                                      style={
                                        state.accountType ==="personal" || state.accountType ==="" ?
                                                {fontSize:'13px', height:'40px',width:'100%',border:"1px solid #000",borderRadius:"0%",backgroundColor:"#6730e3"}:
                                                {fontSize:'13px', height:'40px',width:'100%',border:"1px solid #000",borderRadius:"0%",backgroundColor:"#fff",color:"#000"}
                                      }         
                                      id="personal" 
                                      onClick={(event)=> handleClickGroup("personal",event)}>Personal</Button>
                          
                              <Button 
                                              style={
                                                state.accountType ==="negocio"?
                                                {fontSize:'13px', height:'40px',width:'100%',border:"1px solid #000",borderRadius:"0%",backgroundColor:"#6730e3"}:
                                                {fontSize:'13px', height:'40px',width:'100%',border:"1px solid #000",borderRadius:"0%",backgroundColor:"#fff",color:"#000"}
                                              }
                                               
                                      id="debito"
                                      onClick={(event)=> handleClickGroup("negocio",event)}>Negocio</Button>
                          </ButtonGroup>
                        </div>
                        
                        <button
                          className="btn btn-lg btn-block solid-btn border-radius mt-4 mb-3"
                          id="signIn"
                          //disabled={this.state.disableBtn}
                          onClick={(e) => {
                            if(handleSubmit(e)){
                              addNewUserPlink(e)
                            }
                          }} 
                        >
                        {/* {this.state.btnText} */}
                        {isLoadingSignup?
                          <CircularProgress  style={{color:'white',height:25,width:25}} />
                        :  
                          "Crear cuenta"
                        }
                        </button>

                      </form>
                      
                      </Grow  >
        </React.Fragment>
      );
}

const mapStateToProps = (state)=>{
  return {
    personal:state.signup.personalData
  }
} 

const mapDispatchToProps = (dispatch) =>({
    /* sendPersonalData(data){
        dispatch(personalData(data)) 
    }, */
    OpenSignupDialogHandler(status,token){
      return dispatch(signUpDialog({
        openDialog:true,
        status:status,
        token:token
      }))
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(PersonalData);

