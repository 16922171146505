import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Grow, Button   } from "@material-ui/core";
import _data from "../../../data";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import BrandingWatermarkOutlinedIcon from '@material-ui/icons/BrandingWatermarkOutlined';
import { Context } from "react-responsive";
import { companyData,signUpDialog } from './../../../actions/index';
import axios from 'axios';
import { DataUploadS3, url } from "../../../constants/types";
import { EndPoints } from './../../../constants/types';
import CircularProgress from '@material-ui/core/CircularProgress';
import S3FileUpload from 'react-s3';
import S3 from 'aws-s3';
import uniqid, { process }  from 'uniqid'

/* import env from 'react-dotenv'; */

function CompanyData ({sendCompanyData, personalData,companyData,OpenSignupDialogHandler}) {
    
    const [state,setState] = useState({
        razon_social: "",
        Nombre:"",
        Apellidos:"",
        Cedula:"",
        Cuenta_bancaria:"",
        rif: "",
        foto:"",
        foto_cedula:"",
        foto_rif:"",
        referencia_bancaria:"",
        location: "",
        whatsapp: "",
        isAgreed: false,
        Context:"",
    })
    
    const [formValid, setFormValid] = useState({
        razonSocialValid:0,
        nombreValid:0,
        apellidosValid:0,
        cedulaValid:0,
        rifValid:0,
        cuentabancariaValid:0,
        fotoValid:0,
        fotoCedulaValid:0,
        fotoRifValid:0,
        referenciabancariaValid:0,
        locationValid:0,
        whatsappValid:0,
        isAgreedValid:0,

    })

    const [send,setSend] = useState(false)

    const [dataState,setDataState] = useState({
        signup:{}
    })

    const [loading,setLoading] = useState(false)
   
    const [isUploading, setUploading] = useState({
      uploadPicture:false,
      uploadIdentification:false,
      uploadRefBanks:false
    })

    const [ImagesUploadName, setImagesUploadName]= useState({
      photo: "",
      identiification:"",
      ref:""
    })

    const [PhotoToUploadName, setPhotoToUploadName]= useState({
      photo: null,
    })
    const [IdentificationToUploadName, setIdentificationToUploadName] = useState({
      identification:null
    })
    const [RefToUploadName, setRefToUpload] = useState({
      ref:null
    })

    const [ReadOnly, setReadOnly] = useState({
      photoReadOnly: false,
      identiificationReadOnly:false,
      refReadOnly:false
    })

    const upLoadImage = async (file, dirName, typeUpload )=>{
        const config = {
          bucketName: process.env.REACT_APP_AWS_BUCKET, /* "identityprofile",   *///process.env.REACT_APP_AWS_BUCKET,
          dirName: dirName, /* optional */
          region: process.env.REACT_APP_AWS_REGION, /* "us-east-1" */ //process.env.REACT_APP_AWS_REGION,
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID, /* "AKIAXKQL5MS56GAFPLTN", */ //process.env.REACT_APP_AWS_ACCESS_KEY_ID,
          secretAccessKey:process.env.REACT_APP_AWS_SECRET_KEY, /* "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" , *///process.env.REACT_APP_AWS_SECRET_KEY,
          s3Url:process.env.REACT_APP_S3_URL/* "https://identityprofile.s3.amazonaws.com" */
        }
       
        try{
          setUploading((prevState) => ({
            ...prevState,
           [typeUpload] : true
          }));
          
          
          
         // let res = await S3FileUpload.uploadFile(file,config)
          const S3Client = new S3(config);
          const newFileName = uniqid();
          
         
          let res = await S3Client.uploadFile(file,newFileName)
         
          if(res.status === 204){
            setUploading((prevState) => ({
              ...prevState,
             [typeUpload] : false
            }));
            
            switch(typeUpload){
              case "uploadPicture":
                
                setState((prevState) => ({
                  ...prevState,
                 foto: res.location
                }))
                setReadOnly((prevState)=>({
                  ...prevState,
                  photoReadOnly:true
                }))
                setImagesUploadName((prevState)=>({
                  ...prevState,
                  photo:file.name
                }))
                break;
              case "uploadIdentification":
                if (personalData[0].accountType === "personal") {
                  setState((prevState) => ({
                    ...prevState,
                   foto_cedula: res.location
                  }))
                } else {
                  setState((prevState) => ({
                    ...prevState,
                   foto_rif: res.location
                  }))
                }
                setReadOnly((prevState)=>({
                  ...prevState,
                  identiificationReadOnly:true
                }))
                setImagesUploadName((prevState)=>({
                  ...prevState,
                  identiification:file.name
                }))
                break; 
              case "uploadRefBanks":
                setState((prevState) => ({
                  ...prevState,
                 referencia_bancaria: res.location
                }))
                setReadOnly((prevState)=>({
                  ...prevState,
                  refReadOnly:true
                }))
                setImagesUploadName((prevState)=>({
                  ...prevState,
                  ref:file.name
                }))
                break;
              }

          
          
          }
            
        }catch(err){
          setUploading((prevState) => ({
            ...prevState,
           [typeUpload] : false
          }));
          
        }

    }

    const deleteImage = async (fileName, dirName)=>{
      const config = {
        bucketName: 'identityprofile',  //process.env.REACT_APP_AWS_BUCKET,
        dirName: 'images', /* optional */
        region: 'us-east-1', //process.env.REACT_APP_AWS_REGION,
        accessKeyId: 'AKIAXKQL5MS56GAFPLTN', //process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: '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' ,//process.env.REACT_APP_AWS_SECRET_KEY,
        s3Url:'https://identityprofile.s3.amazonaws.com'
      }
    
     
      try{
        /* setUploading((prevState) => ({
          ...prevState,
         [typeUpload] : true
        })); */
        
        
        
       // let res = await S3FileUpload.uploadFile(file,config)
        const S3Client = new S3(config);
        const FileName = "klgp8qbf.jpeg";
        let res = await S3Client.deleteFile(FileName)
       
        if(res.status === 204){
          /* setUploading((prevState) => ({
            ...prevState,
           [typeUpload] : false
          })); */
          
          

        
        
        }
          
      }catch(err){
        /* setUploading((prevState) => ({
          ...prevState,
         [typeUpload] : false
        })); */
        
      }
    }
    /* const deleteImageUploaded = async (file) */

    function handleFormValueChange(inputName, event) {
        let stateValue ;
        
        stateValue =
          event.target.type === "checkbox"? event.target.checked: 
          event.target.type === "file"? event.target.files[0].name:
          /* inputName === "rif" || inputName ==="whatsapp" ? maskField(inputName,event.target.value) : */
            event.target.value;
            
            setState((prevState) => ({
                ...prevState,
               [inputName] : stateValue
            }));

            if(event.target.type === "file"){
              
              switch(inputName){
                case "foto":
                  setPhotoToUploadName({
                    photo: event.target.files[0]
                  }
                  )
                 /*  const reader = new FileReader();
                  const file = event.target.files[0];
                  reader.onloadend = () => {
                    
                    reader.readAsDataURL(file);
                    setImagesToUploadName((prevState)=>({
                      ...prevState,
                      photo: reader.result
                    }))
                  }; */
                  
                  
                  //upLoadImage(event.target.files[0],"images","uploadPicture")
                  break;
                case "foto_cedula":
                case "foto_rif":
                  setIdentificationToUploadName({
                    identification:event.target.files[0]
                  })
                  /* const reader2 = new FileReader();
                  const file2 = event.target.files[0];
                  reader2.onloadend = () => {
                    
                    reader2.readAsDataURL(file2);
                    setImagesToUploadName((prevState)=>({
                      ...prevState,
                      identiification: reader2.result
                    }))
                  }; */
                  
                  
                  //upLoadImage(event.target.files[0],"identificacion","uploadIdentification")
                  break;
                case "referencia_bancaria":
                  setRefToUpload({
                    ref: event.target.files[0]
                  })  
                /* const reader3 = new FileReader();
                  const file3 = event.target.files[0];
                  reader3.onloadend = () => {
                    
                    reader3.readAsDataURL(file3);
                    setImagesToUploadName((prevState)=>({
                      ...prevState,
                      ref: reader3.result
                    }))
                  }; */
                 
                  //upLoadImage(event.target.files[0],"pdf","uploadRefBanks")
                  break;  

              }
              
            }
            
         /* setState(stateValue); */
    }
    
    function handleFileUpload(event){
      //console.log(event.target.files[0])
      switch(event.target.name){
        case "file":
          if(ReadOnly.photoReadOnly){
            return
          }else{
            document.getElementById(event.target.name).click();
          }
        break;
        case "fileIdentification":
          if(ReadOnly.identiificationReadOnly){
            return
          }else{
            document.getElementById(event.target.name).click();
          }
          break;
        case "fileReferenciaBancaria":
          if(ReadOnly.refReadOnly){
            return
          }else{
            document.getElementById(event.target.name).click();
          }
        break;  
      }    
    }

    function maskField(inputName,e){
      let rx_live
      switch(inputName){
        case "rif":
              rx_live = /^[0-9]?\d*$/;
              
              if(e.target !==undefined){
              
                let reg = rx_live.test(e.target.value.replace(/J|-/g,''))
                if(!reg){
                  e.preventDefault()
                }
               
              
            
              if(e.target.value.length ===0){
                return e.target.value+= "J-"+e.target.value
              }
              if(e.target.value.length ===1){
                return e.target.value += "-"
              }
              if(e.target.value.length ===10){
                return e.target.value+= "-"
              }
              
            }
       
         break;
        case "whatsapp":
                 rx_live = /^[0-9]?\d*$/;
            
                if(e.target !==undefined){
                
                  let reg = rx_live.test(e.target.value.replace(/\+|\-|\(|\)/g,''))
                  if(!reg){
                    e.preventDefault()
                  }
                
                  if(e.target.value.length ===0){
                    return e.target.value+= "+58("+e.target.value
                  }
                  if(e.target.value.length ===3){
                    return e.target.value+= "("
                  }
                  if(e.target.value.length ===7){
                    return e.target.value += ")"
                  }
                  if(e.target.value.length ===11 ||e.target.value.length ===14){
                    return e.target.value+= "-"
                  }
                
                }

           break;
        case "cedula":
          rx_live = /^[0-9]?\d*$/;
       
              if(e.target !==undefined){
              
                let reg = rx_live.test(e.target.value.replace(/V|E|P|-/g,''))
                if(!reg){
                  e.preventDefault()
                }
               
              
            
              if(e.target.value.length ===0){
                if(e.target.value === "V" || e.target.value === "v"){
                  return e.target.value+= "V-"+e.target.value
                }

                else if(e.target.value === "E" || e.target.value === "e"){
                  return e.target.value+= "E-"+e.target.value
                }
                else if(e.target.value === "P" || e.target.value === "p"){
                  return e.target.value+= "P-"+e.target.value
                }else if(new RegExp('[0-9]').test(e.target.value)){
                  return e.target.value+= "V-"+e.target.value
                }
               
                
              }
              if(e.target.value.length ===1){
                return e.target.value += "-"
              }
              
            }
          break;        
        case "cuenta_bancaria":
          rx_live = /^[0-9]?\d*$/;
       
              if(e.target !==undefined){
              
                let reg = rx_live.test(e.target.value)
                if(!reg){
                  e.preventDefault()
                }
              
            }
          break;    
        }      
    }

    function handleSubmit(e) {
      e.preventDefault()
    
        for(const property in formValid){
          if(formValid[property] === 0){
            setFormValid((prevState) => ({
              ...prevState,
              [property]: 2
            }));
          }
        }
      
       switch(personalData[0].accountType) {
         case "personal":
            if(formValid.nombreValid === 1 && formValid.apellidosValid === 1 && formValid.cedulaValid === 1 && formValid.cuentabancariaValid === 1 && formValid.fotoValid === 1 && formValid.fotoCedulaValid === 1 && formValid.referenciabancariaValid === 1&& formValid.locationValid ===1 && formValid.whatsappValid ===1 && formValid.isAgreedValid ===1){
              return true
            } 
            else{
              return false
            }
           
         case  "negocio":
            if(formValid.razonSocialValid === 1 && formValid.rifValid === 1 && formValid.cuentabancariaValid === 1 && formValid.fotoValid === 1 && formValid.fotoRifValid === 1 && formValid.referenciabancariaValid === 1&& formValid.locationValid ===1 && formValid.whatsappValid ===1 && formValid.isAgreedValid ===1){
              return true
            } 
            else{
              return false
            }
       }
      
    }

    const handlerFormFields = (e, name) =>{
      
        let field = e.target.value
        let correct;
        let matcher;
        let match;
        let allowedExtensions;
        switch(name){
          case "razonSocialValid":
            correct = (
              field ==="" ? 2:
              match ===false ? 2:
              1 
            )
            break;
          case "nombreValid":
              correct = (
                field ==="" ? 2:
                match ===false ? 2:
                1 
              )
              break;  
          case "apellidosValid":
                correct = (
                  field ==="" ? 2:
                  match ===false ? 2:
                  1 
                )
          break; 
          case "cedulaValid":

              /* matcher = /^[0-9]?\d$/.test(field.replace(/\+|\-|\(|\)/g,''))
              
              
              correct = (
                field ==="" || field.length <12 ? 2:
                matcher ===false ? 2:
                1 
              )
            break;
          case "locationValid" :
            
            correct = (
              field ==="" ? 2:
              1 
            )
          break;
          case "whatsappValid" :
            /* matcher = /^[0-9]?\d*$/.test(field.replace(/\+|\-|\(|\)/g,'')) */
          
            correct = (
              field ==="" || field.length <10 ? 2:
              matcher ===false ? 2:
              1 
            )
            break;
          case "rifValid":

              /* matcher = /^[0-9]?\d$/.test(field.replace(/\+|\-|\(|\)/g,''))
              
              
              correct = (
                field ==="" || field.length <12 ? 2:
                matcher ===false ? 2:
                1 
              )
            break;
          case "locationValid" :
            
            correct = (
              field ==="" ? 2:
              1 
            )
          break;
          case "whatsappValid" :
            /* matcher = /^[0-9]?\d*$/.test(field.replace(/\+|\-|\(|\)/g,'')) */
          
            correct = (
              field ==="" || field.length <12  ? 2:
              matcher ===false ? 2:
              1 
            )
          break;
          case "cuentabancariaValid":
          
            correct = (
              field ==="" || field.length <20  ? 2:
              matcher ===false ? 2:
              1 
            )
          break;
          case "fotoValid":
            allowedExtensions = /(.jpg|.jpeg|.png)$/i;
            correct = (
              field ==="" || !allowedExtensions.exec(field) ? 2:
              1 
            )
          break;
          case "fotoCedulaValid":
            allowedExtensions = /(.jpg|.jpeg|.png)$/i;
            correct = (
              field ==="" || !allowedExtensions.exec(field)  ? 2:
              1 
            )
          break;
          case "fotoRifValid":
            allowedExtensions = /(.jpg|.jpeg|.png)$/i;
            correct = (
              field ==="" || !allowedExtensions.exec(field) ? 2:
              1 
            )
          break;
          case "referenciabancariaValid":
            allowedExtensions = /(.pdf)$/i;
            correct = (
              field ==="" || !allowedExtensions.exec(field) ?  2:
              1 
            )
            break;
          case "locationValid":
            correct = (
              field ==="" ? 2:
              1 
            )
            break;
          case "whatsappValid":
            correct = (
              field ==="" || field.length <17 ? 2:
              matcher ===false ? 2:
              1 
            )  
          break;
          case "isAgreedValid" :
            
            correct = (
              e.target.checked ===false ? 2:
              1 
            )
          break;
  
        }
         
        setFormValid((prevState) => ({
          ...prevState,
          [name]: correct
        }));
        
    }

    const changeContext = Context =>{
        
        setState((prevState) => ({
            ...prevState,
            Context: Context
        }));
    }
    
    const buildData = (e) =>{
      e.preventDefault()
      changeContext("companyData")
      /* if(personalData) */
      let companyData;
      /* if(personalData[0].accountType ==="personal"){
         companyData = {
          razon_social: state.Nombre+ " "+state.Apellidos,
          cedula:state.Cedula.replace(/-/g,''),
          cuenta_bancaria:state.Cuenta_bancaria,
          foto:state.foto,
          foto_cedula:state.foto_cedula,
          referencia_bancaria:state.referencia_bancaria,
          location :state.location,
          whatsapp :state.whatsapp.replace(/\-|\(|\)/g,''),
          isAgreed: state.isAgreed,
          Context:"companyData"
        }
      } */
       companyData = {
          razon_social: personalData[0].accountType ==="personal" ?state.Nombre+ " "+state.Apellidos: state.razon_social,
          rif :personalData[0].accountType ==="personal" ?state.Cedula.replace(/-/g,''):state.rif.replace(/-/g,''),
          cuenta_bancaria:state.Cuenta_bancaria,
          foto:state.foto,
          foto_rif:personalData[0].accountType ==="personal" ?state.foto_cedula: state.foto_rif,
          referencia_bancaria:state.referencia_bancaria,
          location :state.location,
          whatsapp :state.whatsapp.replace(/\-|\(|\)/g,''),
          isAgreed: state.isAgreed,
          Context:"companyData"
        }
      

      
      

      return companyData
    } 

    const addNewUserPlink= async()=>{ 
                //e.preventDefault()
                
               if(companyData.length>0){
                    if(!send){
                      setLoading(true)
                        setSend(true)
                    }
                    const Url = url+EndPoints.addPlinkUser
                    let json = {
                      companyData:companyData,
                      personalData:personalData
                    }
                    try{
                      const response = await axios.post(Url,json)
                      if(response.status ===200){
                        setLoading(false)
                        if(response.data.exito){
                          
                          OpenSignupDialogHandler(true)
                        }else{
                          OpenSignupDialogHandler(false)
                        }
                      }
                      
                    }catch(error){
                      setLoading(false)
                      OpenSignupDialogHandler(false)
                    }
                    
                    return
               } 
               
               return
                
    }

    useEffect(() => {
      
      deleteImage("","")
    setDataState(prevState => ({
        ...prevState,
        signup:_data.signup
    }));

    //changeContext("personalData")
     /* setState({signup: _data.signup}) */
   }, [Context]);
    
    useEffect(()=>{
        addNewUserPlink() 
   },[companyData])
   
    const assi = async (e) =>{
            
            await sendCompanyData(buildData(e)) 
    }

    return (
        <React.Fragment>
                
                <Grow   in={true} mountOnEnter unmountOnExit>
                      <form
                        className="login-signup-form"
                        method="post"
                        //onSubmit ={(e)=>{addNewUserPlink(e)}}
                        style={{marginTop:40}}
                        autoComplete="off"
                      >
                          
                        <div className="form-group">
                        <label style={{marginBottom:10}}>{personalData[0].accountType === "personal" ? "Nombre":"Razon social"}</label>
                          <div className="input-group input-group-merge">
                            <div className="input-icon">
                              <span className="ti-user color-primary"></span>
                            </div>
  
                            <input
                              value={personalData[0].accountType === "personal" ?state.Nombre:state.razon_social}
                              onChange={(e) =>{
                                personalData[0].accountType === "personal" ? handleFormValueChange("Nombre", e):handleFormValueChange("razon_social", e)
                              }
                                
                              }
                              type="text"
                              name={personalData[0].accountType === "personal"? "nombre":"razon_social"}
                              className={
                                personalData[0].accountType === "personal"?
                                formValid.nombreValid === 0 ? "form-control": 
                                formValid.nombreValid === 1 ?"form-control is-valid":
                                "form-control is-invalid"
                                :
                                formValid.razonSocialValid === 0 ? "form-control": 
                                formValid.razonSocialValid === 1 ?"form-control is-valid":
                                "form-control is-invalid"
                                }
                              placeholder={personalData[0].accountType === "personal" ?"Pedro":"TIENDAS 999 C.A."}
                              onBlur ={(e)=>{personalData[0].accountType === "personal"?handlerFormFields(e,"nombreValid"):handlerFormFields(e,"razonSocialValid")}}
                              onKeyPress = {(e)=>{personalData[0].accountType === "personal"?handlerFormFields(e,"nombreValid"):handlerFormFields(e,"razonSocialValid")}}
                              required
                            />
                                <div className="valid-feedback">
                                  Formato Correcto!
                                </div>
                                
                                <div className="invalid-feedback">
                                  Formato incorrecto !Por favor verifique el campo.
                                </div>

                          </div>
                          
                        </div>
                        {
                          personalData[0].accountType === "personal" && 
                          <div className="form-group">
                          <label style={{marginBottom:10}}>{"Apellidos"}</label>
                            <div className="input-group input-group-merge">
                              <div className="input-icon">
                                <span className="ti-user color-primary"></span>
                              </div>
    
                              <input
                                value={state.Apellidos}
                                onChange={(e) =>{
                                   handleFormValueChange("Apellidos", e)
                                }}
                                type="text"
                                name="apellidos"
                                className={
                                  formValid.apellidosValid === 0 ? "form-control": 
                                  formValid.apellidosValid === 1 ?"form-control is-valid":
                                  "form-control is-invalid"
                                  }
                                placeholder={"González"}
                                onBlur ={(e)=>{handlerFormFields(e,"apellidosValid")}}
                                onKeyPress = {(e)=>{handlerFormFields(e,"apellidosValid")}}
                                required
                              />
                                  <div class="valid-feedback">
                                    Formato Correcto!
                                  </div>
                                  
                                  <div className="invalid-feedback">
                                    Formato incorrecto !Por favor verifique el campo.
                                  </div>
  
                            </div>
                            
                          </div>
                          
                          } 
                        
                        {
                          personalData[0].accountType === "personal" ? 
                          <div className="form-group">
                          <label style={{marginBottom:10}}>Cédula</label>
                          <div className="input-group input-group-merge">
                            <div className="input-icon">
                              <span className="ti-id-badge color-primary"></span>
                            </div>
                            <input
                              
                                onChange={e =>
                                    handleFormValueChange("Cedula",e)
                              } 
                              type="text"
                              name="cedula"
                              className={
                                formValid.cedulaValid === 0 ? "form-control": 
                                formValid.cedulaValid === 1 ?"form-control is-valid":
                                "form-control is-invalid"
                                }
                              placeholder="V-400000000"
                              onKeyPress = { (e)=>{
                                handlerFormFields(e,"cedulaValid")
                                maskField("cedula",e)
                              }
                              }
                              onBlur ={(e)=>{handlerFormFields(e,"cedulaValid")}}
                              maxLength='10'  
                              required
                             
                            />
                            <div class="valid-feedback">
                                  Formato Correcto!
                                </div>
                                <div className="invalid-feedback">
                                  Formato incorrecto !Por favor verifique el campo.
                                </div>

                          </div>
                        </div>:
                          
                          <div className="form-group">
                        <label style={{marginBottom:10}}>Rif - Empresa</label>
                        <div className="input-group input-group-merge">
                          <div className="input-icon">
                            <span className="ti-id-badge color-primary"></span>
                          </div>
                          <input
                            
                              onChange={e =>
                                  handleFormValueChange("rif",e)
                            } 
                            type="tel"
                            name="rif"
                            className={
                              formValid.rifValid === 0 ? "form-control": 
                              formValid.rifValid === 1 ?"form-control is-valid":
                              "form-control is-invalid"
                              }
                            placeholder="J-400000000-1"
                            onKeyPress = { (e)=>{
                              handlerFormFields(e,"rifValid")
                              maskField("rif",e)
                            }
                            }
                            onBlur ={(e)=>{handlerFormFields(e,"rifValid")}}
                            maxLength='12'  
                            required
                           
                          />
                          <div class="valid-feedback">
                                Formato Correcto!
                              </div>
                              <div className="invalid-feedback">
                                Formato incorrecto !Por favor verifique el campo.
                              </div>

                        </div>
                      </div>
                        }

                        
                        <div className="form-group">
                          <label style={{marginBottom:10}}>Cuenta Bancaria</label>
                          <div className="input-group input-group-merge">
                            <div className="input-icon">
                              <span className="color-primary"> <AccountBalanceOutlinedIcon  className="color-primary"  style ={{marginBottom:25}} /></span> 
                             
                            </div>
                            <input
                            
                              onChange={e =>
                                handleFormValueChange("Cuenta_bancaria", e)
                              }
                              type="tel"
                              name="whatsapp"
                              className={
                                formValid.cuentabancariaValid === 0 ? "form-control": 
                                formValid.cuentabancariaValid === 1 ?"form-control is-valid":
                                "form-control is-invalid"
                              } 
                              placeholder="01050000000000000000"
                              onKeyPress = {(e)=>{
                                handlerFormFields(e,"cuentabancariaValid")
                                maskField("cuenta_bancaria",e)
                              }
                              }
                              onBlur ={(e)=>{handlerFormFields(e,"cuentabancariaValid")}}
                              maxLength='20'
                              required
                            />
                            <div class="valid-feedback">
                                  Formato Correcto!
                                </div>
                                <div className="invalid-feedback">
                                  Formato incorrecto !Por favor verifique el campo.
                                </div>
                          </div>
                        </div>
  
                        
                        <div className="form-group">
                        <label style={{marginBottom:10}}>{personalData[0].accountType === "personal"?"Foto":"Logo"}</label>
                          <div className="input-group input-group-merge">
                            <div className="input-icon">
                              <span className="ti-link color-primary"></span>
                            </div>
                            {
                            isUploading.uploadPicture &&
                            <div className="input-end-icon">
                             
                             <CircularProgress  style={{marginTop:10,marginBottom:10, height:30,width:30}} />
                             </div>
                          }
                            <input
                              value={state.foto}
                              onChange={(e) =>{
                                
                                handleFormValueChange("foto", e)
                              }
                              }
                              onClick={e=>handleFileUpload(e)}
                              type="text"
                              name="file"
                              className={
                                formValid.fotoValid === 0 ? "form-control": 
                                formValid.fotoValid === 1 ?"form-control is-valid":
                                "form-control is-invalid"
                                }
                              placeholder="Adjunta tu imagen"
                              readOnly={true}
                              onBlur = {
                                e =>  handlerFormFields(e,"fotoValid")
                              }
                              required
                            />
  
                            
                            <input
                              
                              onChange={e =>
                                /* handlerFormFields(e,"imageValid") */
                                handleFormValueChange("foto", e)
                              }
                              
                              type="file"
                              name="file"
                              id = "file"
                              
                              style={{display:'none'}}
                              placeholder="Adjunta tu imagen"
                              onBlur = {
                                e =>  handlerFormFields(e,"fotoValid")
                              }
                              required
                             
                            />
                            <div className="valid-feedback">
                                  Formato Correcto!
                            </div>
                            <div className="invalid-feedback">
                                  Formato incorrecto !Por favor verifique el campo.
                            </div>
                          
                          </div>
                        </div>
                         


                        <div className="form-group">
                        <label style={{marginBottom:10}}>{personalData[0].accountType === "personal"? "Foto Cédula":"Foto Rif"}</label>
                          <div className="input-group input-group-merge">
                            <div className="input-icon">
                              <span className="ti-link color-primary"></span>
                            </div>
                            
                            <input
                              value={personalData[0].accountType === "personal"?state.foto_cedula:state.foto_rif}
                              onChange={(e) =>{
                                personalData[0].accountType === "personal"?
                                handleFormValueChange("foto_cedula", e):
                                handleFormValueChange("foto_rif", e)
                              }
                              }
                              onClick={e=>handleFileUpload(e)}
                              type="text"
                              name="fileIdentification"
                              className={
                                personalData[0].accountType === "personal"?
                                  formValid.fotoCedulaValid === 0 ? "form-control": 
                                  formValid.fotoCedulaValid === 1 ?"form-control is-valid":
                                  "form-control is-invalid"
                                  :
                                  formValid.fotoRifValid === 0 ? "form-control": 
                                  formValid.fotoRifValid === 1 ?"form-control is-valid":
                                  "form-control is-invalid"

                                }
                              placeholder="Adjunta tu imagen"
                              readOnly={true}
                              onBlur = {
                               (e) => { 
                                  personalData[0].accountType === "personal"?
                                  handlerFormFields(e,"fotoCedulaValid")
                                  :
                                  handlerFormFields(e,"fotoRifValid")
                                }
                              }
                              required
                            />
  
                            
                            <input
                              
                              onChange={(e) =>{
                                personalData[0].accountType === "personal"?
                                handleFormValueChange("foto_cedula", e):
                                handleFormValueChange("foto_rif", e)
                              }
                              }
                              
                              type="file"
                              name="fileIdentification"
                              id = "fileIdentification"
                              
                              style={{display:'none'}}
                              placeholder="Adjunta tu imagen"
                              onBlur = {
                                (e) => { 
                                  personalData[0].accountType === "personal"?
                                  handlerFormFields(e,"fotoCedulaValid")
                                  :
                                  handlerFormFields(e,"fotoRifValid")
                                }
                              }
                              required
                            />
                            <div className="valid-feedback">
                                  Formato Correcto!
                            </div>
                            <div className="invalid-feedback">
                                  Formato incorrecto !Por favor verifique el campo.
                            </div>
                          
                          </div>
                        </div>    

                        <div className="form-group">
                        <label style={{marginBottom:10}}>Referencia Bancaria</label>
                          <div className="input-group input-group-merge">
                            <div className="input-icon">
                              <span className="ti-link color-primary"></span>
                            </div>
                            
                            <input
                              value={state.referencia_bancaria}
                              onChange={(e) =>{
                                
                                handleFormValueChange("referencia_bancaria", e)
                              }
                              }
                              onClick={e=>handleFileUpload(e)}
                              type="text"
                              name="fileReferenciaBancaria"
                              className={
                                formValid.referenciabancariaValid === 0 ? "form-control": 
                                formValid.referenciabancariaValid === 1 ?"form-control is-valid":
                                "form-control is-invalid"
                                }
                              placeholder="Adjunta tu imagen"
                              readOnly={true}
                              onBlur = {
                                e =>  handlerFormFields(e,"referenciabancariaValid")
                              }
                              required
                            />
  
                            
                            <input
                              
                              onChange={e =>
                                /* handlerFormFields(e,"imageValid") */
                                handleFormValueChange("referencia_bancaria", e)
                              }
                              
                              type="file"
                              name="fileReferenciaBancaria"
                              id = "fileReferenciaBancaria"
                              
                              style={{display:'none'}}
                              placeholder="Adjunta tu imagen"
                              onBlur = {
                                e =>  handlerFormFields(e,"referenciabancariaValid")
                              }
                              required
                            />
                            <div className="valid-feedback">
                                  Formato Correcto!
                            </div>
                            <div className="invalid-feedback">
                                  Formato incorrecto !Por favor verifique el campo.
                            </div>
                          
                          </div>
                        </div> 

                        <div className="form-group">
                          <label style={{marginBottom:10}}>¿Dónde estás ubicado?</label>
                          <div className="input-group input-group-merge">
                            <div className="input-icon">
                              <span className="ti-location-pin color-primary"></span>
                            </div>
                            
                            <select  className={
                                      formValid.locationValid === 0 ? "form-control": 
                                      formValid.locationValid === 1 ?"form-control is-valid":
                                      "form-control is-invalid"
                                    }  
                                    onChange={e =>
                                         handleFormValueChange("location", e)
                                    } 
                                    onBlur ={(e)=>{handlerFormFields(e,"locationValid")}}
                            >
                                {(dataState.signup.estados || []).map(
                                    (item,index) => {
                                       
                                        return (
                                        <option key = {index} value = {item.value}>{item.label}</option>
                                        );
                                      }
                                )}
                            </select>
                            <div class="valid-feedback">
                                  Correcto!
                                </div>
                                <div className="invalid-feedback">
                                  Debe elegir una ubicación!.
                                </div>
                          </div>
                        </div>
                        
                        <div className="form-group">
                          <label style={{marginBottom:10}}>Teléfono (Whatsapp)</label>
                          <div className="input-group input-group-merge">
                            <div className="input-icon">
                              <span className="color-primary"> <WhatsAppIcon  className="color-primary"  style ={{marginBottom:25}} /></span> 
                             
                            </div>
                            <input
                            
                              onChange={e =>
                                handleFormValueChange("whatsapp", e)
                              }
                              type="tel"
                              name="whatsapp"
                              className={
                                formValid.whatsappValid === 0 ? "form-control": 
                                formValid.whatsappValid === 1 ?"form-control is-valid":
                                "form-control is-invalid"
                              } 
                              placeholder="+58(424)000-22-00"
                              onKeyPress = {(e)=>{
                                handlerFormFields(e,"whatsappValid")
                                maskField("whatsapp",e)
                              }
                              }
                              onBlur ={(e)=>{handlerFormFields(e,"whatsappValid")}}
                              maxLength='17'
                              required
                            />
                            <div class="valid-feedback">
                                  Formato Correcto!
                                </div>
                                <div className="invalid-feedback">
                                  Formato incorrecto !Por favor verifique el campo.
                                </div>
                          </div>
                        </div>
  
  
                        <div className="my-4">
                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              checked={state.isAgreed}
                              onChange={(e) =>{
                                handlerFormFields(e,"isAgreedValid")
                                handleFormValueChange("isAgreed", e)
                              }
                              }
                              type="checkbox"
                              name="isAgreed"
                              className={
                                formValid.isAgreedValid === 0 ? "custom-control-input": 
                                formValid.isAgreedValid === 1 ?"custom-control-input is-valid":
                                "custom-control-input is-invalid"
                              } 
                              
                              id="ckbAgree"
                            />
                            <label className="custom-control-label" htmlFor="ckbAgree">
                              Acepto los <a href="/#">términos y condiciones</a>
                            </label>
                          </div>
                        </div>
                        
                        <button
                          className="btn btn-lg btn-block solid-btn border-radius mt-4 mb-3"
                          id="signIn"
                          disabled={send}
                          onClick={(e) => {
                            if(handleSubmit(e)){
                              
                              assi(e)
                            } 
                            
                           
                          }} 
                          
                          
                        >
                        {/* {this.state.btnText} */}
                        {loading?
                          <CircularProgress  style={{color:'white',height:25,width:25}} />
                        :  
                          "Crear cuenta"
                        }
                        
                        
                        
                        </button>

                      </form>
                      
                      </Grow  >
                      
                   
                   
              
                
        </React.Fragment>
      );
}


const mapStateToProps = (state)=>{
     return {
       personalData:state.signup.personalData,
       companyData:state.signup.companyData
     }
   }

const mapDispatchToProps =  (dispatch) =>({
    async sendCompanyData(data){
      if(data.isAgreed){
        return await  dispatch(companyData(data))
      }
       
    },
    OpenSignupDialogHandler(status){
      return dispatch(signUpDialog({
        openDialog:true,
        status:status
      }))
    }

})


export default connect(mapStateToProps, mapDispatchToProps)(CompanyData);

