import { PERSONAL_DATA,COMPANY_DATA,CHANGE_CONTEXT,RESTART_STATE } from '../constants/types.js';

const initialState = {
    Context : "personalData",
    personalData:[],
    companyData:[]
}

const signUp = (state = initialState, action) => {
   
    switch (action.type) {
        case PERSONAL_DATA:
                state = {
                    ...state, 
                    personalData: [action.personalData],
                    Context: action.personalData["Context"]
                };
            break;
        case COMPANY_DATA:
                state = {
                    ...state,
                    companyData : state.companyData.concat(action.companyData),
                    Context:action.companyData["Context"]
                }
            break;
        case CHANGE_CONTEXT:
            state = {
                ...state,
                Context:action.Context
            }
            break;
        case RESTART_STATE:
            state = {
                ...state,
                personalData:[],
                companyData:[]
            }
            break;           
        default:
            break;
    }
    return state
};


export default signUp;