import React from "react";
import { makeStyles} from '@material-ui/core/styles';

const useStyle = makeStyles((theme)=>({

    divider:{
        height:3,
        width:"40%",
        margin:"auto",
        backgroundImage: "linear-gradient(to left, rgb(255, 255, 255), #ffffff,#ffffff, rgba(189, 0, 245, 1), rgba(189, 0, 245, 1), rgba(189, 0, 245, 1))",

    }

}))

function HorizontalDivider (props){
    const classes = useStyle()

    return(
        <div className = {classes.divider}>
            {props.children}
        </div>
    )
}

export default HorizontalDivider
