import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// importing all the themes
import Theme1 from "../themes/theme1";
import Faq from "../themes/faq";
import NotFound from "../themes/404";
/* import plink from "../components/PlinkApp/plink"; */
import plink from "../components/Plink/plink";
import signup1 from "../themes/signup1"

import PlinkPersonas from './../components/PlinkApp/PlinkPersonas/PlinkPersonas';

export default class Routes extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route exact path="/" component={Theme1} />
            
            <Route exact path="/faq" component={Faq} />
            <Route exact path="/signup" component={signup1}/>
            <Route exact path="/:id" component={plink} />
            {/* <Route exact path="/:id" component={plink} /> */}
            {/* <Route exact path="/console/personas" component={PlinkPersonas}/> */}
            <Route component={NotFound} />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}
