import React from 'react'
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Divider, Grid, Slide, Typography } from '@material-ui/core';
import  Button  from '@material-ui/core/Button';


const useStyle = makeStyles((theme)=>({
    body:{
  
        flexGrow: 1,
        height: '100vh',
        paddingTop:"20%"
        
      },
      buttonTitle:{
         
    
          backgroundColor:"#EBEBEB",
          borderRadius:50,
          fontFamily:"Comfortaa Light",
          
      },
      Recibidos:{
        textAlign:"center",
        marginTop:20,  
        fontFamily:"Comfortaa Light"
      },
      Enviados:{
        textAlign:"center",
        marginTop:20,  
        fontFamily:"Comfortaa Light"
      },
      NameList:{
        textAlign:"center",
        
        fontSize:10, 
        fontFamily:"Comfortaa Light"
      },
      UserNameList:{
        textAlign:"center",
        fontSize:9, 
        color:"#528D72",
        fontFamily:"Comfortaa Light"
      },
      ListLayout:{
          paddingTop:8,
          marginTop:20,
          marginLeft:10,
          borderRadius:50,
          width: "95%",
          height:40,
          backgroundColor:"#EBEBEB"
      },
      AmountRecibidos:{
        marginTop:5,
        textAlign:"center",
        fontSize:15, 
        color:"#14CE54",
        fontFamily:"Comfortaa Light"
      },
      AmountEnviados:{
        marginTop:5,
        textAlign:"center",
        fontSize:15, 
        color:"#F74C00",
        fontFamily:"Comfortaa Light"
      }

}))

function Balance ({balance}){
    const classes = useStyle()
    return (
      
            <Slide direction="right" in = {true} mountOnEnter unmountOnExit >
                <div className={classes.body}>
                    <Grid container >
                        <Grid item xs = {12} style ={{textAlign:"center"}}>
                            <Button  className = {classes.buttonTitle}>
                                Balance
                            </Button>
                        </Grid>
                        <Grid item xs = {12}>
                            <Divider style={{marginTop:20}}/>
                        </Grid>
                        <Grid item xs = {6}>
                            <Typography className = {classes.Recibidos}>Recibidos</Typography>
                            <Divider style={{marginTop:20, height:5,backgroundColor:"#4D2DA2"}}/>
                            {/*aqui va  la iteracion de la data*/}
                            <div className = {classes.ListLayout}>
                                <Grid container>
                                    <Grid item xs = {6}>
                                        <Typography className = {classes.NameList}>Juan Luis Guerra</Typography>
                                        <Typography className = {classes.UserNameList}>weplink/jguerra</Typography>
                                    </Grid>
                                    <Grid item xs = {1}>
                                        <img src="../img/mastercardicon.svg" />
                                        
                                    </Grid>
                                    <Grid item xs = {5}>
                                        <Typography className = {classes.AmountRecibidos}>+12,00$</Typography>
                                    </Grid>
                                </Grid>
                                
                            </div>
                        
                            <div className = {classes.ListLayout}>
                                <Grid container>
                                    <Grid item xs = {6}>
                                        <Typography className = {classes.NameList}>Juan Luis Guerra</Typography>
                                        <Typography className = {classes.UserNameList}>weplink/jguerra</Typography>
                                    </Grid>
                                    <Grid item xs = {1}>
                                        <img src="../img/mastercardicon.svg" />
                                        
                                    </Grid>
                                    <Grid item xs = {5}>
                                        <Typography className = {classes.AmountRecibidos}>+12,00$</Typography>
                                    </Grid>
                                </Grid>
                                
                            </div>
                        </Grid>
                        <Grid item xs = {6}>
                            <Typography className = {classes.Recibidos}>Enviados</Typography>
                            <Divider style={{marginTop:20, height:5}}/>
                            {/*aqui va  la iteracion de la data*/}
                            <div className = {classes.ListLayout}>
                                <Grid container>
                                    <Grid item xs = {6}>
                                        <Typography className = {classes.NameList}>Luis Ruiz</Typography>
                                        <Typography className = {classes.UserNameList}>weplink/lruiz</Typography>
                                    </Grid>
                                    <Grid item xs = {1}>
                                        <img src="../img/visa.png" />
                                        
                                    </Grid>
                                    <Grid item xs = {5}>
                                        <Typography className = {classes.AmountEnviados}>-12,00$</Typography>
                                    </Grid>
                                </Grid>
                                
                            </div>
                            <div className = {classes.ListLayout}>
                                <Grid container>
                                    <Grid item xs = {6}>
                                        <Typography className = {classes.NameList}>Luis Ruiz</Typography>
                                        <Typography className = {classes.UserNameList}>weplink/lruiz</Typography>
                                    </Grid>
                                    <Grid item xs = {1}>
                                        <img src="../img/visa.png" />
                                        
                                    </Grid>
                                    <Grid item xs = {5}>
                                        <Typography className = {classes.AmountEnviados}>-12,00$</Typography>
                                    </Grid>
                                </Grid>
                                
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Slide>
            
        
    )
}
const mapStateToProps = (state)=>{
    
    return {
          balance : state.plinkReducer.getDataUserPlink[0].balance
        }
}
export default connect(mapStateToProps)(Balance)