import React,{ useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from "@material-ui/core/Icon";
import CircularProgress from '@material-ui/core/CircularProgress';
/* import DebitDialog from '../Dialogs/DebitDialog';
import FinalizedPay from '../Dialogs/FinalizedPay'; */
import { Avatar, Box, Grid, Slide} from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import { CaptchSiteKey, ConstantsCrypto, CREDENTIALS_MASTERKEY, EndPoints, url } from '../../../../../constants/types';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CryptoJS from 'crypto-js'
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import MenuItem from '@material-ui/core/MenuItem';
import ReCAPTCHA from "react-google-recaptcha";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux';
import { changePlinkComponent, getValueAmount, changePlinkPersonasComponent } from './../../../../../actions/index';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme)=>({

    appBarCartDialog: {
      position: 'relative',
      backgroundColor:'#000'
    },
    titleDialog: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    payCardTitle:{
        width:'100%',
        paddingTop:'60px',
        fontWeight:"bold",
        textAlign:'center'
        
    },
    dividerLeft:{
      
        float:"left",
        marginTop:'8px',
        /*[theme.breakpoints.down("xs")]: {
          width:'23%',
        },*/
        width:'100%',
        borderTop: 'solid 1px #D6D6D6'

    },
    dividerRight:{
      float:"right",
        /*[theme.breakpoints.down("xs")]: {
          width:'25%',
        },*/
        marginTop:'8px',
        width:'100%',
        borderTop: 'solid 1px #D6D6D6'

    },
    
    payCardFont:{
        marginLeft:'5px',
        marginRight:'5px',
        fontSize:'15px',
        [theme.breakpoints.down('xs')]:{
          fontSize:'10px',
          
        },
        textAlign: "center",
        color:"#676767"
    },
    groupButtonContainer:{
        marginTop:'25px',
        backgroundColor: 'white',
        alignContent:'center',
        width:'100%'
    },

    GroupButton: {
      width:'100%',
      height:'40px',
      fontFamily:'SF Pro Display',
      fontSize:'13px',
      color:'#000',
      backgroundColor: 'white',
      '&:hover': {
        color:'#ffffff',
        backgroundColor:'#6730E3',
      },
      
    },

    GroupButtonActived: {
      width:'100%',
      height:'40px',
      fontSize:'13px',
      fontFamily:'SF Pro Display',
      backgroundColor: '#6730E3',
      '&:hover': {
        color:'#ffffff',
        backgroundColor:'#6730E3',
      },
    },

    formContainer:{
      width:"100%",
      marginTop:20
    },

    formContainerTitle:{
      fontSize:'15px',
      marginTop:'40px',
      marginBottom:'5px'
    },

    form:{
      "& .MuiTextField-root": {
        margin: theme.spacing(0),
        "& fieldset": {
          borderRadius: 0
        }
      },
      
    },

    InputAdornmentNumber:{
      marginRight:60
    },

    InputAdornmentTypeCards:{
      marginRight:0
    },

    InputAdornmentTypeCreditCards:{
      marginRight:0
    },
    marginTextFieldCardNumber:{
      width:'100%',
      
      '& fieldset': {
        borderRadius: 0,
        fontSize:20
      },
      
    },
    formControl:{
        width:'100%',
    },
    select: {
        height: "10vh",
        borderRadius: 0
      },
    marginTextFieldCvv:{
     
      width:'50%',
      '& fieldset': {
        borderRadius: 0,
        fontSize:20
        
      },
      
    },
    marginTextFieldCedula:{
      
      width:'50%',
      '& fieldset': {
        borderRadius: 0,
        fontSize:20
      },
     
      
    },

    totalPrice:{
      fontSize:28,
      padding:theme.spacing(2,0,0,0),
      [theme.breakpoints.down("xs")]:{
        padding:theme.spacing(0,0,0,-2),
      },
      
      fontWeight:'light',
      textAlign:'center',
    },
    avatarContainer:{
      width:'100%',
      margin:theme.spacing(2,0,3,0),
      [theme.breakpoints.down("xs")]:{
        margin:theme.spacing(2,0,3,0),
      },
      /* marginBottom:theme.spacing(2),
      marginTop:theme.spacing(1),
      marginLeft:theme.spacing(2),
      marginRight:theme.spacing(-2), */
      //border:'solid 1px red'
    },
    avatarHeader:{
        float:"left",
        width: theme.spacing(3),
        height: theme.spacing(3),
        [theme.breakpoints.down("xs")]:{
          width: theme.spacing(4),
          height: theme.spacing(4),
          marginTop: theme.spacing(-1)
        }
        //marginRight:"83%"
    },

    usernameCheckout:{
      [theme.breakpoints.down("xs")]:{
        margin: theme.spacing(-0.5,0,0,2),
      },
      margin: theme.spacing(0,0,0,1),
      fontSize: 16, 
    },

    shoppingBag:{
      float:'right',
      marginLeft:18
    },
    companyName:{
      margin:theme.spacing(0),
      fontStyle: 'italic',
      fontWeight:'bold',
      fontSize:30
    },
    boxContainerFooter:{
      width:'100%',
      textAlign:'center',
    },

    boxContainerFooterDevice:{
      width:'70%',
      margin: "auto",
      paddingTop:10,
      textAlign:'center',
    },
    footerText:{
      fontSize:12,
      [theme.breakpoints.down("xs")]: {
        fontSize:12
      },
      color:'#8000FF',
    },
    
    footerTextDialog:{
      position:'absolute',
      bottom:40,
      right:40,
      left:40,
      fontSize:10
    },
    listContainer:{
      marginTop:theme.spacing(6),
      maxHeight:'60%',
      overflow:'auto'
    },
    buttonProgress: {
      color: "#000",
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    body:{
  
        flexGrow: 1,
        height: '100%',
        marginTop:'15%',
       
        margin:theme.spacing(0,6,0,6),
        [theme.breakpoints.down("xs")]:{
            margin:theme.spacing(3,3,0,3),
        }
    },
    footer:{
      textAlign:'center',
      bottom:0,
      right:0,
      position: "absolute",
      width: "100%",
      fontSize:'10px',
      marginBottom:"2.3%",
      
  },
  recaptchaContainer:{
    width:'100%',
    display:"flex"
   
  },
  recaptcha:{
    
    display: "block",
    marginTop:10, 
    marginLeft: "auto",
    marginRight: "auto"

  }
   
    
}))

const ColorButton = withStyles((theme) => ({
    root: {
      marginTop:'40px',
      width:'100%',
      height:'40px',
      fontSize:'15px',
      backgroundColor: '#000',
      '&:hover': {
        backgroundColor:'#000',
      },

    },
  }))(Button);

const PayButton = withStyles((theme) => ({
  root: {
    marginTop:'40px',
    width:'100%',
    height:'40px',
    fontSize:'15px',
    backgroundColor: '#6730E3',
    '&:hover': {
      backgroundColor:'#6730E3',
    },

  },
}))(Button);





function CheckoutComponent({getValuesPlink,sendChangeComponent,sendAmountValue}){
  
    const isDevice = useMediaQuery({
      query: '(max-device-width: 768px)'
    })
    const classes = useStyles();
    const [cardType, setCardType] = useState("credito")
    const [openModal,setOpenModal] = useState(false)
    const [openModalSuccess,setOpenModalSuccess] = useState(false)
    const [cardNumber,setCardNumber] = useState("")
    const [cvvNumber,setCvvNumber] = useState("")
    const [cedulaNumber,setCedulaNumber] = useState("")
    const [venc,setVenc] = useState("")
    const [emptycardNumber,setEmptycardNumber] = useState(false)
    const [emptycvvNumber,setEmptycvvNumber]= useState(false)
    const [emptycedulaNumber,setEmptycedulaNumber]= useState(false)
    const [emptyselect,setemptyselect]= useState(false)
    const [emptyvenc,setemptyvenc] = useState(false)
    const [loading,setLoading]=useState(false)

    const [alertOpen,setAlertOpen]=useState(false)
    const [messageError, setMessageError]=useState()
    const [statusTrx, setStatusTrx]=useState()
    const [select, setSelect] = React.useState("");
    const [captcha, setCaptcha] =useState(false);

    const checkoutIcon = (
      <Icon style = {{paddingRight:60}}>
        <img alt="edit" src="../img/iconsrc.png" style ={{"height": "20px",marginBottom:20}} />
      </Icon>
    );
   
    const maestroIcon = (
      <Icon style = {{paddingRight:80}}>
        <img alt="edit" src="../img/footerimg.png" style ={{"height": "20px"}} />
        <img alt="edit" src="../img/maestro.png" style ={{"height": "20px"}} />
      </Icon>
    );

    const creditIcons =(
      <Icon style = {{paddingRight:50}}>
        <img alt="edit" src="../img/visa.png" style ={{"height": "20px", paddingRight:10}} />
        <img alt="edit" src="../img/mastercard.png" style ={{"height": "20px"}} />
      </Icon>
    );

    
    const mercantilIconDevice=(
      <Icon>
        <img alt="edit" src="../img/footerimg.png" style ={{"height": "40px","marginTop":6}}  />
      </Icon>
    )


    const mercantilIcon=(
      <Icon>
        <img alt="edit" src="../img/footerimg.png" style ={{"height": "20px","marginTop":2}}  />
      </Icon>
    )


    function handleClickGroup(type,event){
     
        
        let form = document.getElementById("payForm")
        if(type==="credito"){
          setCardType("credito")
          setCardNumber("")
          setCvvNumber("")
          setCedulaNumber("")
          form.reset()
        }else {
          setCardType("debito")
          setCardNumber("")
          setCvvNumber("")
          setCedulaNumber("")
          form.reset()
        }
      
    }

    function handleClikPay(){
     
      let isCardnumberEmpty = isEmpty(cardNumber,"cardnumber")  
      let isCvvNumberEmpty = isEmpty(cvvNumber,"cvv")
      let isCedulaNumberEmpty = isEmpty(cedulaNumber,"cedula")
      let isVencEmpty = isEmpty(venc,"venc")
  
      let isCardNumberCorrect = isFieldIncorrect(cardNumber,"cardnumber")
      let isCvvNumberCorrect = isFieldIncorrect(cvvNumber,"cvv")
      let isCedulaNumberCorrect = isFieldIncorrect(cedulaNumber,"cedula")
      let isVencCorrect = isFieldIncorrect(venc,"venc")
      
      
      
      if(isCardnumberEmpty|| isCvvNumberEmpty || isCedulaNumberEmpty ){
        return
      }
      if(isCardNumberCorrect|| isCvvNumberCorrect || isCedulaNumberCorrect || isVencCorrect){
        return
      }
      if(cardType === "credito"){
       if(!isVencEmpty && !isVencCorrect  ){
         
        payCreditCard (cardNumber,cvvNumber,cedulaNumber,venc)
       }else{
         return
       }
        
        //openmodalsuccess(true)
      }else{
          if(select === "" ){
              setemptyselect(true)
              return
          }
          
          payDebitCard()
        /* openmodal(true) */
      }
      
    }
   
    const payDebitCard = () =>{
      sendAmountValue(handlerFinalizedPayPlink(""))
      sendChangeComponent(handlerNextComponentFinalizedPayPlink("authenticate"))
    }

    const payCreditCard =(...prop)=>{
      const Url = url+EndPoints.crearPagoTdcBoton
      
      //return console.log()
      //setCardNumber(cardNumber.toString())
      let cvvNumber = CryptoJS.AES.encrypt(prop[1].toString(), ConstantsCrypto.MasterKeyCrypto);
      let cedulaNumber = prop[2].toString();
      setLoading(true)
      
      let icm =parseInt(CryptoJS.AES.decrypt(getValuesPlink[0].dataUserPay[0].icmm, CREDENTIALS_MASTERKEY).toString(CryptoJS.enc.Utf8))
    
      axios.post(Url, {
        CODIGO_SEGURIDAD_TARJETA:cvvNumber.toString(),
        DIRECCION_IP_CLIENTE:"200.3.1.8",
        DIRECCION_IP_CONSUMIDOR:"192.237.245.234",
        FECHA_VENCIMIENTO_TARJETA :prop[3].toString(),
        IDENTIFICACION_TARJETAHABIENTE:cedulaNumber.toString(),
        IDENTIFICADOR_COMERCIO:icm.toString(), //este dato viene x url
        LATITUDE : "10.4839983",
        LONGITUDE : "-66.8539983",
        MONTO_TRANSACCION:getValuesPlink[0].amount.toString().replace(",","."), //este dato viene x url
        NUMERO_TARJETA:cardNumber.toString(),
        RIF:getValuesPlink[0].dataUserPay[0].rif 
      },{
        timeout:30000, 
        headers: { 
         
          "Authorization":getValuesPlink[0].dataUserPay[0].token
          
        }
      })
      .then(function(data){
        setLoading(false)
        if(data.data.exito){
            sendAmountValue(handlerFinalizedPayPlink(data.data))
            sendChangeComponent(handlerNextComponentFinalizedPayPlink("finalized"))
          
        }else{
            sendAmountValue(handlerFinalizedPayPlink(data.data))
            sendChangeComponent(handlerNextComponentFinalizedPayPlink("finalized"))
            
        }

      })
      .catch(function(err){
        setLoading(false)
        let code = err.code
        if(code === "ECONNABORTED"){
          setAlertOpen(true)
          setMessageError("Error de timeout")
        }else{
          setAlertOpen(true)
          setMessageError("Hubo un problema con el servidor intente más tarde ")
        }
        
        //window.top.location.href = urlCurrent; 
        
      })
    }
    
    function isEmpty(field,type){
        
      if(field===""){
        if(type === "cardnumber"){
          setEmptycardNumber(true)
          return true
        }else if(type ==="cvv"){
          setEmptycvvNumber(true)
          return true
        }else if(type ==="cedula"){
          setEmptycedulaNumber(true)
          return true
        }else if(type==="venc"){
          setemptyvenc(true)
          return true
        }

        return true
      }else{
        if(type === "cardnumber"){
          setEmptycardNumber(false)
          return false
        }else if( type ==="cvv"){
          setEmptycvvNumber(false)
          return false
        }else if(type ==="cedula"){
          setEmptycedulaNumber(false)
          return false
        }else if(type==="venc"){
          setemptyvenc(false)
          return false
        }
        return false
      }
    }
    
    function isFieldIncorrect(field,type){
      
      if(type === "cardnumber" && cardType ==="credito"){
        let card = CryptoJS.AES.decrypt(field.toString(), ConstantsCrypto.MasterKeyCrypto ).toString(CryptoJS.enc.Utf8);
        card = card.replace(/ /g,"")
        
        if(card.length <16){
          setEmptycardNumber(true)
          return true
        }else{
          if(isNumber(card)){
            setEmptycardNumber(false)
            return false
          }else{
            setEmptycardNumber(true)
            return true
          }
  
        }
      }else if(type === "cardnumber" && cardType ==="debito"){
        if(cardNumber.length<8){
          setEmptycardNumber(true)
          return true
        }else{
          if(isNumber(cardNumber)){
            setEmptycardNumber(false)
            return false
          }else{
            setEmptycardNumber(true)
            return true
          }
        
        }
      }else if(type ==="cvv"){
        if(cvvNumber.length<3){
          setEmptycvvNumber(true)
          return true
        }else{
          if(isNumber(cvvNumber)){
            setEmptycvvNumber(false)
            return false
          }else{
            setEmptycvvNumber(true)
            return true
          }
          
               
          
        }
        
     
     
      }else if(type ==="cedula"){
        if(cedulaNumber.length<8){
          setEmptycedulaNumber(true)
          return true
        }else{
          if(isNumber(cedulaNumber)){
            setEmptycedulaNumber(false)
            return false
          }else{
            setEmptycedulaNumber(true)
            return true
          }
        }
        
      }else if(cardType ==="credito" && type ==="venc"){
        if(venc.length<7){
          setemptyvenc(true)
          return true
        }else{
          /* if(isNumber(venc)){
            setemptyvenc(false)
            return false
          }else{
            setemptyvenc(true)
            return true
          } */
          let arrayString = [...venc]
          let findit = false;
          arrayString.map((value,index)=>{
              if(index != 2){
                if(value === '/'){
                  findit = true
                  return findit
                }
              }
          })
          if(findit){
            setemptyvenc(true)
            return true
          }else{
            let vencNumber = venc.replace(/[/]/g,"")
            
            if(isNumber(vencNumber)){
              setemptyvenc(false)
              return false
            }else{
              setemptyvenc(true)
              return true
            }

            
          }
        
        }
      }
    }
    
    const isNumber=(digit)=>{
      var numbers = /^[0-9]+$/;
          if(digit.match(numbers))
            {
            return true
            }
              else
              {
                return false
              }
    }

    const handleClose = ()=>{
        /* props.updateNextComponent("amount") */
        //setOpen2(true)
    }

    const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setAlertOpen(false);
    };

    function validate(e){
      var key = window.event ? e.which : e.keyCode;
      if(cardType==="credito" && e.target.id === "cardnumber"){
        if(e.target.value.length===4 || e.target.value.length===9 ||e.target.value.length===14){
          return e.target.value += " "
        }
      }

      if(cardType ==="credito" && e.target.id==="venc"){
        if(e.target.value.length===2){
          return e.target.value += "/"
        }
      }
     
      if (key < 48 || key > 57) {
        e.preventDefault();
      }

      
    }

    function openmodal(openModal){
      setOpenModal(openModal)
    }
    
    function openmodalsuccess(openModalSuccess,status){
      setStatusTrx(status)
      setOpenModalSuccess(openModalSuccess)
    }
  
    const openMod = (e,status)=>{
        if(cardType==="credito"){
          openmodalsuccess(e)
          handleClose()
        }else{
            openmodal(e)
        }
        
        
    }

    const DebitTransactionFinish = (e) =>{
        /* props.updateNextComponent("amount") */
        //setOpen2(true)
    }

    const noPaste = (e)=>{
      e.preventDefault(); 
      return false
    }

    const CustomAlert = (props)=>{
      return(
        <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="error">
          {props.message}
        </Alert>
      </Snackbar>
      )
    }

    const handleChange = event => {
        setSelect(event.target.value);
      };
    
    const handleChangeCardnumberCredit = e =>{
        let cardnumber = CryptoJS.AES.encrypt(e.target.value.replace(/ /g,""), ConstantsCrypto.MasterKeyCrypto)
        setCardNumber(cardnumber.toString())
    }
    
    function onChange(value) {
      
      if(value!=="" && value !== undefined && value !== null){
        setCaptcha(true)
      }
      
    }

   

    const handlerFinalizedPayPlink = (statusTrx)=>{
      
        let amountValue = {
          getValuesPlink:[{
            amount:getValuesPlink[0].amount,
            currency:getValuesPlink[0].currency,
            dataUserPay:[],
            dataTrx:[],
            statusTrx:statusTrx    
          }]
            
        }
      
        return amountValue
    }

    const handlerNextComponentFinalizedPayPlink = (component)  =>{
       let dataPlink = {
            changePlinkPersonasComponentValues:[{
                nextComponent: "pagar",
                enabledBack:false,
                enabledForw:false,
                checkedBalance: false,
                checkedPagar: true,
                checkedRecibir: false,
                checkedBanco: false,
                containersComponents:component
                    
            }]
            
        }
            return dataPlink
    }

    return (
        <React.Fragment>
            <Slide direction="left" in={true} mountOnEnter unmountOnExit>
            <div className={classes.body}>
                <CustomAlert message ={messageError} />
                
                   {/*  {
                    cardType === "credito" ? 
                        <FinalizedPay  open = {openModalSuccess} updateModal = {openMod} isDebitTransactionFinish = {DebitTransactionFinish}  statusTrx = {statusTrx} trxData={[cardNumber.toString(),props.propsTrx[3].toString()]} /> :  
                        <DebitDialog open = {openModal} updateModal = {openMod} isDebitTransactionFinish = {DebitTransactionFinish} PayTrxProps={[cardNumber,cvvNumber,cedulaNumber,select,props.propsTrx[0],props.propsTrx[1].toString(),props.propsTrx[2],props.propsTrx[3]]}/>
                    } */}
                
                
                
                    
                    {/* <div className={classes.avatarContainer}>
                    <Grid container component = "main" >
                      
                      <Grid item xs={10}>
                       <Typography className={classes.usernameCheckout}>{props.companyProps[1]}</Typography>
                      </Grid>
                    </Grid>
                    
                    
                    
                    </div> */}
                    
                    
                
                  {/*   <Typography  className = {classes.totalPrice}>
                   {new Intl.NumberFormat("de-DE",{minimumFractionDigits:2}).format(props.propsTrx[3])}  Bs
                    </Typography> */}
                    
                   {/*  <ColorButton variant="contained" color="primary"> Pago móvil</ColorButton> */}
                    <Grid container component = "main" className={classes.payCardTitle}>
                        <Grid item xs={3} sm = {3} md={3}>
                            <div className = {classes.dividerLeft}></div>
                        </Grid>

                        <Grid item xs={6} sm = {6} md={6}>
                            <Typography className = {classes.payCardFont} >
                                Pagos con tarjetas
                            </Typography>
                        </Grid>

                        <Grid item xs={3} sm = {3} md={3}>
                            <div className ={classes.dividerRight}></div>
                        </Grid>
                    </Grid>
                
                
                    <div className={classes.groupButtonContainer}>
                        <ButtonGroup  variant="contained" color="primary" style={{width:'100%'}} >
                            <Button className={ 
                                                cardType === "credito" || cardType === "" ?
                                                classes.GroupButtonActived :
                                                classes.GroupButton
                                            } 
                                    style={{fontSize:'13px'}}         
                                    id="credito" 
                                    onClick={(event)=> handleClickGroup("credito",event)}>Crédito</Button>
                        
                            <Button className={
                                                cardType === "debito"  ?
                                                classes.GroupButtonActived :
                                                classes.GroupButton

                                            } 
                                            style={{fontSize:'13px'}} 
                                    id="debito"
                                    onClick={(event)=> handleClickGroup("debito",event)}>Débito</Button>
                        </ButtonGroup>
                    </div>

                    <div className= {classes.formContainer}>                        
                        <form className={classes.form} id = "payForm" autoComplete="off" component ='form'>
                        
                        { cardType==="debito"?
                            <TextField
                            error = {emptycardNumber}
                            variant="outlined"
                            margin="normal"
                            color = "primary"
                            required
                            size="small"
                            id="cardnumber"
                            name="carnumber"
                            className = {classes.marginTextFieldCardNumber}
                            type='tel'
                            onChange ={e => setCardNumber(e.target.value)}
                            disabled = {loading}
                            autoComplete="off"
                            onPaste={noPaste}
                            onKeyPress={e=>validate(e)}              
                            InputProps={{
                        
                                endAdornment:(
                                <InputAdornment position="end" className = {classes.InputAdornmentTypeCards}>
                                    {maestroIcon}
                                </InputAdornment>
                                )
                            }}
                            
                            inputProps={{
                                
                                maxLength: 8,
                                placeholder: "ÚLTIMOS 8 DÍGITOS",
                                
                            
                            }}
                            
                            
                        /> :
                        <TextField
                                error = {emptycardNumber}
                                variant="outlined"
                                margin="normal"
                                color = 'primary'
                                size="small"
                                required
                                fullWidth
                                id="cardnumber"
                                name="carnumber"
                                className = {classes.marginTextFieldCardNumber}
                                onChange ={handleChangeCardnumberCredit }
                                disabled = {loading}
                                onPaste={noPaste}
                                type='tel'
                                autoComplete="off"
                                onKeyPress={e=>validate(e)}
                                InputProps={{
                                
                                endAdornment:(
                                    <InputAdornment position="end" className = {classes.InputAdornmentTypeCreditCards}>
                                        {creditIcons}
                                    </InputAdornment>
                                )
                                }}

                                inputProps={{
                                  placeholder: "1234 1234 1234 1234",
                                  maxLength: 19,
                              }}
                        
                                
                                
                                
                            /> 
                        
                        }
                            
                            
                        {
                          cardType === "credito"?
                          <TextField
                                error = {emptyvenc}
                                variant="outlined"
                                margin="normal"
                                color = "primary"
                                required
                                id="venc"
                                name="venc"
                                size="small"
                                onKeyPress={e=>validate(e)}
                                onChange ={e => setVenc(e.target.value)}
                                disabled = {loading}
                                onPaste={noPaste}
                                type='tel'
                                autoComplete="off"
                                className = {
                                classes.marginTextFieldCvv
                                }
                                inputProps={{
                                maxLength: 7,
                                placeholder:"MM/YYYY",
                                inputMode:'tel'
                                }}
                                
                            
                            />
                          :
                          <TextField
                                error = {emptycvvNumber}
                                variant="outlined"
                                margin="normal"
                                color = "primary"
                                required
                                id="cvv"
                                name="cvv"
                                size="small"
                                onKeyPress={e=>validate(e)}
                                onChange ={e => setCvvNumber(e.target.value)}
                                disabled = {loading}
                                onPaste={noPaste}
                                type='password'
                                autoComplete="off"
                                className = {
                                classes.marginTextFieldCvv
                                }
                                inputProps={{
                                maxLength: 3,
                                placeholder:"CVV",
                                /* inputMode:'tel' */
                                }}
                                
                                
                            
                            />
                        }
                        
                        {
                          cardType==="credito"?
                          <TextField
                                error = {emptycvvNumber}
                                variant="outlined"
                                margin="normal"
                                color = "primary"
                                required
                                id="cvv"
                                name="cvv"
                                size="small"
                                onKeyPress={e=>validate(e)}
                                onChange ={e => setCvvNumber(e.target.value)}
                                disabled = {loading}
                                onPaste={noPaste}
                                type='password'
                                autoComplete="off"
                                className = {
                                classes.marginTextFieldCvv
                                }
                                inputProps={{
                                maxLength: 3,
                                placeholder:"CVV",
                                /* inputMode:'tel' */
                                }}
                                
                            
                            />:
                        <TextField
                                error = {emptycedulaNumber}
                                variant="outlined"
                                margin = 'none'
                                color = "primary"
                                required
                                id="cedula"
                                size="small"
                                name="cedula"
                                onKeyPress={e=>validate(e)}
                                onChange ={e => setCedulaNumber(e.target.value)}
                                disabled = {loading}
                                onPaste={noPaste}
                                type='tel'
                                autoComplete="off"
                                className = {
                                classes.marginTextFieldCedula
                                }
                                inputProps={{
                                maxLength: 8,
                                placeholder:"CÉDULA"
                                }}
                            
                            />
                        }
                            
                            {
                              cardType==="credito" ?
                              <TextField
                                error = {emptycedulaNumber}
                                variant="outlined"
                                margin = 'none'
                                color = "primary"
                                required
                                id="cedula"
                                size="small"
                                name="cedula"
                                onKeyPress={e=>validate(e)}
                                onChange ={e => setCedulaNumber(e.target.value)}
                                disabled = {loading}
                                onPaste={noPaste}
                                type='tel'
                                autoComplete="off"
                                className = {
                                classes.marginTextFieldCardNumber
                                }
                                inputProps={{
                                maxLength: 8,
                                placeholder:"CÉDULA O PASAPORTE"
                                }}
                            
                            />:
                              <FormControl variant="outlined" className={classes.formControl}>
                                <Select
                                value={select}
                                error = {emptyselect}
                                onChange={handleChange}
                                displayEmpty
                                className={classes.select}
                                inputProps={{ "aria-label": "Without label" }}
                                >
                                    <MenuItem value="">
                                    <Typography style={{color:'grey'}}>TIPO DE CUENTA</Typography> 
                                    </MenuItem>
                                    <MenuItem value={"CA"}><Typography style={{color:'grey'}}>AHORRO</Typography> </MenuItem>
                                    <MenuItem value={"CC"}><Typography style={{color:'grey'}}>CORRIENTE</Typography> </MenuItem>
                                
                                </Select>
                              </FormControl>
                            }
                                
                           
                              {/* <div className = {classes.recaptchaContainer}>
                                  <ReCAPTCHA
                                    sitekey= {CaptchSiteKey}
                                    onChange={onChange}
                                    className={classes.recaptcha}
                                  />  
                              </div> */}
                             
                         
                        </form>

                    <PayButton variant="contained" color="primary" startIcon ={loading?"":checkoutIcon} onClick = {handleClikPay} disabled = {loading}> {cardType==="credito" ? "Pagar" : "Continuar"} {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}</PayButton>
                    <Box mt={1} className={isDevice? classes.boxContainerFooterDevice:classes.boxContainerFooter}><Typography className={classes.footerText}>Ésta transacción será procesada por {isDevice? mercantilIconDevice:mercantilIcon}</Typography></Box>

                            
                   
                    </div>
                    </div>
                    </Slide>
        </React.Fragment>
    )
}
const mapStateToProps = (state)=>{
    
    return {
        changePlinkPersonasComponentValues : state.plinkReducer.changePlinkPersonasComponentValues,
        getValuesPlink:state.plinkReducer.getValuesPlink
        }
}

const mapDispatchToProps = (dispatch) =>({
    sendChangeComponent(component){
        dispatch(changePlinkPersonasComponent(component))
        /* dispatch(getValueAmount()) */
        
    },
    sendAmountValue(data){
        
        dispatch(getValueAmount(data))
    }
})
export default connect(mapStateToProps,mapDispatchToProps)(CheckoutComponent)