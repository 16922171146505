import React,{ useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Avatar, Slide, InputAdornment, TextField, InputLabel, Input, FormControl, Typography, Zoom, Fab, CircularProgress, Grow } from '@material-ui/core';
import DoneAllOutlinedIcon from '@material-ui/icons/DoneAllOutlined';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { changePlinkPersonasComponent, getValueAmount } from './../../../../../actions/index';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { CREDENTIALS_MASTERKEY, EndPoints, url } from '../../../../../constants/types';
import { InputBase } from '@material-ui/core';
import Axios from 'axios';

const useStyle = makeStyles((theme)=>({
    body:{
        flexGrow: 1,
        height: '100vh',
        paddingTop:"20%"   
    },
    Avatar:{
        margin:'auto',
        height:70,
        width:70,
        boxShadow: "1px 2px 3px #888888",
        backgroundColor:"#F7F7F7",
        marginTop:20
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        backgroundColor:"#F7F7F7",
        boxShadow: "1px 2px 3px #888888",
        color:"#8C07FF"
      },
  
      textField: {
        width: '30ch',

      },
      formContainer:{
          width:"100%",
          textAlign:"center",
          marginTop:"10%"
      },
      UserTitle:{
          color:"#8C07FF",
          textAlign:"center",
          fontFamily:"Comfortaa Light",
          marginTop:10,
          fontSize:20
      },
      fabNext:{
        backgroundColor:"white",
        top:"35%",
        width:60,
        height:60,
        color:"#8000FF",
        border:"1px solid #bababa"
      },
      nextButton:{
          width :"100%",
          marginTop:50,
          textAlign:"center"
      }
}))

function SearchUserPlink({changePlinkPersonasComponentValues,getValuesPlink,sendChangeComponent,sendAmountValue}){
    const classes = useStyle()
    const [loading, setLoading] = useState(false)
    const [exito, setExito]=useState(0)
    const [user,setUser]= useState("")
    const [userData, setUserData] = useState()
    
    const handleChange = (event) => {
        event.preventDefault()
       
        let dataPlink
       
                dataPlink = {
                    changePlinkPersonasComponentValues:[{
                        nextComponent: "pagar",
                        enabledBack:true,
                        enabledForw:true,
                        checkedBalance: false,
                        checkedPagar: true,
                        checkedRecibir: false,
                        checkedBanco: false,
                        containersComponents:"amount"
                            
                      }]
                        
                    }
               
          sendChangeComponent(dataPlink) 
    };
    
    const handleKeyPress = (event) =>{
        setUser(event.target.value)
        cleanFieldState()
    };

    const cleanFieldState =()=>{
            setExito(0)
    };

    function getPlinkUser (){
        
        if(user !==""){
            const Url = url+EndPoints.getPlinkUser
        
            setLoading(true)
            
            Axios.post(Url,{
              UserPlink:user
            },{
              timeout:30000, 
            
            })
            .then(function(data){
              setLoading(false)
              if(data.data.Exito){
                setExito(1)
                data.data.Datos[0].token = data.data.Token
                setUserData(data.data.Datos)
                
                
              }else{
                return setExito(2)
              }
              
              //let token = data.data.token
              //setJwt(token)
              return
            })
            .catch(function(err){
              setLoading(false)
              
              let code = err.code
              if(code === "ECONNABORTED"){
               /*  setAlertOpen(true)
                setMessageError("Error de timeout") */
              }else{
               /* setAlertOpen(true)
               setMessageError("No se pudo encontrar al usuario por favor intente de nuevo "+err) */
              }
              
              //window.top.location.href = urlCurrent; 
              
            })
        }else{
            cleanFieldState()
        }
        
    };  

    const dispatchDataUserHelper = (data) =>{
        let dispatchData = {
            getValuesPlink:[{
                amount:"",
                currency:"",
                dataTrx:[],
                dataUserPay:data
            }]
        }
        return dispatchData
    };


    return(

        <Slide direction="right" in = {true} mountOnEnter unmountOnExit >
            <div className={classes.body}>
                
                <Grid container>
                    <Grid item xs ={12}>
                        <Avatar className={classes.Avatar}><Avatar className = {classes.large}></Avatar></Avatar>
                        
                        <Typography className ={classes.UserTitle}>Usuario</Typography>
                    </Grid>

                    <Grid item xs ={12}>
                        <div className ={classes.formContainer}>
                            <FormControl className={classes.textField}>

                                <InputLabel htmlFor="standard-adornment-password" style={{fontFamily:"Comfortaa Light"}}>weplink.me/usuario</InputLabel>

                                <Input
                                    id="standard-adornment-password"
                                    type={'text'}
                                    //value={values.password}
                                    onChange={handleKeyPress}
                                  /*  onKeyPress={(e)=>setUser(e.target.value)} */
                                    onBlur ={getPlinkUser}
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <Zoom in ={loading}>
                                            <CircularProgress style = {{color:"#8000FF", height:20,width:20}}/>
                                        </Zoom>
                                        
                                        
                                        <Zoom in = {(exito ===1 && user !=="")} timeout={600}>
                                            <DoneAllOutlinedIcon style ={{color:"#07EB2D"}} />
                                        </Zoom>

                                        <Zoom in = {(exito ===2 && user !=="")} timeout={600}>
                                            <CloseIcon style ={{color:"#ff1100"}}/>
                                        </Zoom>
                                      
                                    </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>   
                    </Grid>

                    <Grid item xs={12}>
                        <div className = {classes.nextButton}>
                            <Zoom in = {exito===1}>
                                <Fab color={classes.fabGreen} aria-label="add" className={classes.fabNext} onClick ={(e)=>{handleChange(e);sendAmountValue(dispatchDataUserHelper(userData))}}>
                                    <NavigateNextIcon fontSize="large" /> 
                                    <NavigateNextIcon fontSize="large" style={{marginLeft:-25}} />
                                </Fab>
                            </Zoom>
                        </div>
                        
                    </Grid>

                    
                </Grid>
            </div>
        </Slide>
    )
}

const mapStateToProps = (state)=>{
    
    return {
        changePlinkPersonasComponentValues : state.plinkReducer.changePlinkPersonasComponentValues,
        getValuesPlink:state.plinkReducer.getValuesPlink
        }
}

const mapDispatchToProps = (dispatch) =>({
    sendChangeComponent(component){
       
        /* dispatch(changePlinkComponent(component))  */
        dispatch(changePlinkPersonasComponent(component))
        /* dispatch(getValueAmount()) */
        
    },
    sendAmountValue(data){
        dispatch(getValueAmount(data))
    }
})

export default  connect(mapStateToProps,mapDispatchToProps)(SearchUserPlink)  