import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getValueAmount, personalData } from "../../../actions/index";
import { Slide, Button, Grid, Avatar, Typography   } from "@material-ui/core";
import _data from "../../../data";
import ButtonGroup  from '@material-ui/core/ButtonGroup';
import { Context } from "react-responsive";
import CryptoJS from 'crypto-js'
import { ConstantsCrypto } from "../../../constants/types";
import { makeStyles } from '@material-ui/core/styles';

const useStyles =  makeStyles((theme) => ({


    //Containter styles
    container:{
      minHeight: "100vh",
      overflow:'auto',
  
     
    },
    //Containter styles
    body:{
      
      
        flexGrow: 1,
        height: '100vh',
        paddingTop:"5%",
        margin:theme.spacing(6)
      
    },
  
    //bar styles
    root: {
      display: 'flex',
      flexDirection: 'column',
     
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    appBar:{
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor:"transparent",
  /*     backgroundImage:  "url('../img/drawing-2.svg')",
   */    //backgroundSize:"contain",
      //backgroundRepeat:"no-repeat",
      height:120
  
      //backgroundImage: "linear-gradient(to right, rgb(102, 48, 194), #6730E3, rgba(83, 49, 177, 1), rgba(114, 48, 205, 1), rgba(150, 41, 230, 1))",
    },
  
    //CARD STYLE
    rootCard: {
      //minWidth: 275,
      width:"100%",
      /* margin: theme.spacing(0,45,0,45), */
      overflowY:'scroll',
      
     
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      '& -ms-overflow-style': "none", 
      '& scrollbar-width': 'none',
        height:"100vh",
        alignItems:'center', 
        [theme.breakpoints.up("md")]:{
          /* margin: theme.spacing(0,45,0,45), */
        },
        [theme.breakpoints.down("md")]:{
          /* margin: theme.spacing(0,30,0,30), */
        },
        [theme.breakpoints.between("sm","md")]:{
          /* margin: theme.spacing(0,30,0,30), */
        },
        [theme.breakpoints.down("xs")]:{
          /* margin: theme.spacing(0,0,0,0), */
        }
    },
    
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    titleCard: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  
    fab: {
     
      bottom: theme.spacing(4),
      right: theme.spacing(4),
      color: theme.palette.common.white,
      backgroundColor: "#25d366",
      position: 'fixed',
    },
  
    fabNext:{
      backgroundColor:"white",
      top:"35%",
      left:"-5%",
      width:50,
      height:50,
      color:"#8000FF",
      border:"1px solid #bababa"
    },
    fabBack:{
      backgroundColor:"white",
      top:"35%",
      width:50,
      zIndex: theme.zIndex.drawer + 1,
      height:50,
      left:"95%",
      color:"#8000FF",
      
      border:"1px solid #bababa"
    },
  
    contentAvatar:{
      width:'100%',
      
      textAlign:'center'
    },
    contentBodyCard:{
      width:'100%',
      
      textAlign:'center'
    },
    Avatar:{
        margin:'auto',
        height:70,
        width:70,
        boxShadow: "1px 2px 3px #888888",
        backgroundColor:"#ededed"
      },
      large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        backgroundColor:"#ededed",
        color:"#8C07FF"
      },
      ForgotPass:{
        width:"100%",
        textAlign:"end",
        color:"#8C07FF",
       
      },

      SignUpBlock:{
          marginTop:40
      },
   
    buttonGoPage:{
      margin:theme.spacing(8,0,0,0),
      borderRadius:'20px',
      backgroundImage: "linear-gradient(to right, rgb(102, 48, 194), #6730E3, rgba(83, 49, 177, 1), rgba(114, 48, 205, 1), rgba(150, 41, 230, 1))",
    },
    dividerLeft:{
        
      float:"left",
      marginTop:'8px',
      /*[theme.breakpoints.down("xs")]: {
        width:'23%',
      },*/
      width:'40%',
      borderTop: 'solid 1px #D6D6D6'
  
  },
  dividerRight:{
    float:"right",
      /*[theme.breakpoints.down("xs")]: {
        width:'25%',
      },*/
      marginTop:'8px',
      width:'40%',
      borderTop: 'solid 1px #D6D6D6'
  
  },
  marginTextFieldCardNumber:{
    width:'100%',
    textAlign: "center",
    fontSize:40,
    marginTop:20,
    '& fieldset': {
      borderRadius: 0,
      border:'solid 0px transparent',
      
    },
    "&::placeholder": {
      textAlign: "center",
      fontSize:40,
   
    },
    
    
    
  },
  
  Username:{
    margin:theme.spacing(4,0,0,0),
    fontFamily: 'Sf Pro Display',
    fontSize:23,
    color:"#8000FF"
  },
  buttonNext:{
    background:"#000",
    borderRadius:'20px',
    marginTop:80
  },
  
  toolbarIconTitle:{
    width:120,
    height:35,
    margin:theme.spacing(0,10),
    [theme.breakpoints.down("xs")]:{
      width:110,
      height:35,
      margin:theme.spacing(0,3),
    }
  },
  loadContainer:{
    //backgroundColor: "rgba(0, 0, 0, 0.1)",
    width: "100%",
    height: "100vh",
    textAlign: "center"
  },
  loader:{
    margin:'48%',
    [theme.breakpoints.down("xs")]:{
      margin:'45%'
    }
  },
  
  offset:theme.mixins.toolbar  // esto sirve para espaciar el contenido con el navbar
  
  }));


  function LoginComponent ({personal,sendPersonalData}) {
    const classes = useStyles()
    const [state,setState] = useState({
        user: "",
        email: "",
        password: "",
        image:"",
        accountType:"personal",
        Context:"",
    })

    const [isValid, setIsValid]= useState(true)

    const [formValid, setFormValid] = useState({
        userValid:0,
        emailValid:0,
        passwordValid:0,
        imageValid:0
    })

    const changeBtnText = btnText => {
        setState(prevState => ({
            ...prevState,
            btnText: btnText
        }));
       /*  setState(...state,{ btnText: btnText }); */
    };

    function handleFormValueChange(inputName, event) {
        let stateValue ;
        stateValue =
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value;
            
            setState((prevState) => ({
                ...prevState,
               [inputName] : stateValue
            }));
            
         /* setState(stateValue); */
    }
    
    const changeContext = Context =>{
        
        setState((prevState) => ({
            ...prevState,
            Context: Context
        }));
    }

    function handleSubmit(e) {
      e.preventDefault()
    
        for(const property in formValid){
          if(formValid[property] === 0){
            setFormValid((prevState) => ({
              ...prevState,
              [property]: 2
            }));
          }
        }
      
       if(formValid.userValid === 1 && formValid.emailValid === 1 && formValid.passwordValid ===1 && formValid.imageValid===1){
         return true
       } 
       else{
         return false
       }
    }
      
    function handleFileUpload(event){
        document.getElementById("file").click();
        
    }
    
    function handleClickGroup(type,event){
         
        if(type==="personal"){
          setState((prevState)=>({
                ...prevState,
                accountType:"personal"
            }))
        }else {
            setState((prevState)=>({
                ...prevState,
                accountType:"negocio"
            }))
        }
      
    }

    const handlerFormFields = (e, name) =>{
      
      let field = e.target.value
      let correct;
      let matcher;
      let match;
      switch(name){
        case "userValid":
          match = matchUser(field)
          
          correct = (
            field ==="" || field.length < 3? 2:
            match ===false ? 2:
            1 
          )
          break;
        case "emailValid":
            matcher = matchEmail(field)
            
            correct = (
              field ==="" ? 2:
              matcher ===false ? 2:
              1 
            )
          break;
        case "passwordValid" :
          
          correct = (
            field ==="" ? 2:
            field !== "" && field.length < 6 ? 2:
            1 
          )
        break;
        case "imageValid" :
          /* let fieldFile = document.getElementById("file")
          let file = fieldFile.files[0]
         
          var reader = new FileReader();
          reader.onload = function(event) {
            // El texto del archivo se mostrará por consola aquí
            
          };

          reader.readAsText(file) */
          correct = (
            field ==="" ? 2:
            1 
          )
        break;

      }
       
      setFormValid((prevState) => ({
        ...prevState,
        [name]: correct
      }));
      
    }

    const matchUser = (user)=>{
      var regex = new RegExp("^[a-zA-Z0-9]+$")
      if(regex.test(user)){
        return true
      }else{
        return false
      }
    }
    
    const matchEmail = (email) =>{
      let pattern = /^([\da-z_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/
      if(pattern.exec(email)){
        return true
      }else{
        return false
      }
    }

    const buildData = (e) =>{
      e.preventDefault()
      let personalData = {
        user: CryptoJS.AES.encrypt(state.user,ConstantsCrypto.MasterKeyAddUser).toString(),
        email :CryptoJS.AES.encrypt(state.email,ConstantsCrypto.MasterKeyAddUser).toString(),
        password :CryptoJS.AES.encrypt(state.password,ConstantsCrypto.MasterKeyAddUser).toString(),
        accountType :state.accountType,
        image:state.image,
        Context:"companyData"
      }
      

      return personalData
    } 

    useEffect(() => {
      setState(prevState => ({
          ...prevState,
          signup:_data.signup
      }));

      changeContext("personalData")
     /* setState({signup: _data.signup}) */
   }, [Context]);
    

    return (
       
        
                <Slide direction="left" in={true}  mountOnEnter unmountOnExit >                      
                 
                        <div className={classes.body}>

                        <Grid item xs={12}  >
                            <div className = {classes.contentAvatar} >
                                
                            <Avatar alt="A"  className = {classes.Avatar}><Avatar alt="A" /* src={props.companyProps[0]}  */className={classes.large}  /></Avatar>
                            
                            <Typography className ={classes.Username}> Iniciar Sesión</Typography>
                            {/*  <Button variant="contained" color="primary" className={classes.buttonGoPage}  >
                                <a href={props.companyProps[2] } style={{color:'white'}}>Ir a la web</a>
                            </Button> */}
                            
                            </div>
                        </Grid>
                       
                        <form
                                className="login-signup-form"
                                method="post"
                                style={{marginTop:40}}
                                autoComplete="off"
                            >
                                <div className="form-group">
                                    <div className="input-group input-group-merge">
                                        

                                        
                                        <div className="input-icon">
                                        <span className="ti-user color-primary"></span>
                                        </div>
            
                                        <input
                                        
                                        onChange={e =>
                                            handleFormValueChange("user", e)
                                        }
                                        type="text"
                                        name="name"
                                        className={
                                            formValid.userValid === 0 ? "form-control": 
                                            formValid.userValid === 1 ?"form-control is-valid":
                                            "form-control is-invalid"
                                            }
                                        placeholder="Usuario"
                                        onBlur ={(e)=>{handlerFormFields(e,"userValid")}}
                                        onKeyPress = {(e)=>{handlerFormFields(e,"userValid")}}
                                        required
                                        />
                                        <div className="valid-feedback">
                                            Formato Correcto!
                                        </div>
                                        <div className="invalid-feedback">
                                            Formato incorrecto !Por favor verifique el campo.
                                        </div>
                                    </div>
                                </div>
                                
                                
        
                                <div className="form-group">
                                    <div className="input-group input-group-merge">
                                        <div className="input-icon">
                                            <span className="ti-lock color-primary"></span>
                                        </div>
                                        
                                        <input
                                        value={state.password}
                                        onChange={e =>
                                            handleFormValueChange("password", e)
                                        }
                                        type="password"
                                        name="password"
                                        className={
                                                    formValid.passwordValid === 0 ? "form-control": 
                                                    formValid.passwordValid === 1 ?"form-control is-valid":
                                                    "form-control is-invalid"
                                                    }
                                        placeholder="Contraseña"
                                        onBlur ={(e)=>{handlerFormFields(e,"passwordValid")}}
                                        onKeyPress = {(e)=>{handlerFormFields(e,"passwordValid")}}
                                        required
                                        />
                                        <div className="valid-feedback">
                                            Formato Correcto!
                                        </div>
                                        <div className="invalid-feedback">
                                            Formato incorrecto !Por favor verifique el campo.
                                        </div>
                                    </div>
                                </div>
                                
                            
                                <div className={classes.ForgotPass}>
                                   <Typography >Olvidó su contraseña</Typography>
                                </div>
                                
                                <button
                                className="btn btn-lg btn-block solid-btn border-radius mt-4 mb-3"
                                id="signIn"
                                //disabled={this.state.disableBtn}
                                onClick={(e) => {
                                    if(handleSubmit(e)){
                                    sendPersonalData(buildData(e))
                                    }
                                }} 
                                >
                                {/* {this.state.btnText} */}
                                Iniciar
                                </button>

                                <Grid container className={classes.SignUpBlock}>
                                    
                                    <Grid item xs ={2} style={{marginTop:10}}>
                                        <svg height={20}>
                                            <line x1="0" y1="0" x2="20%" y2="0" style={{stroke:"rgb(213,213,213)", strokeWidth:2}}/>
                                        Sorry, your browser does not support inline SVG.
                                        </svg>

                                    </Grid>
                                    <Grid item xs ={8} style={{color:"#8C07FF", textAlign:"center"}}>Regístrate para pagar y recibir</Grid>
                                    <Grid item xs ={2} style={{marginTop:10}}>
                                        <svg height={20}>
                                            <line x1="0" y1="0" x2="20%" y2="0" style={{stroke:"rgb(213,213,213)", strokeWidth:2}}/>
                                        Sorry, your browser does not support inline SVG.
                                        </svg>
                                    </Grid>
                                </Grid>
                                
                                <button
                                className="btn btn-lg btn-block solid-btn border-radius mt-4 mb-3"
                                id="signIn"
                                //disabled={this.state.disableBtn}
                                onClick={(e) => {
                                    if(handleSubmit(e)){
                                    sendPersonalData(buildData(e))
                                    }
                                }} 
                                >
                                {/* {this.state.btnText} */}
                                Nueva Cuenta
                                </button>
                               

                            </form>
                      </div>

                </Slide  >
        
      );
}

const mapStateToProps = (state)=>{
  
  return {
    personal:state.signup.personalData
  }
} 




export default connect(mapStateToProps)(LoginComponent);

