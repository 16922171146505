import React,{ useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from "@material-ui/core/Icon";
import CircularProgress from '@material-ui/core/CircularProgress';
import DebitDialog from '../Dialogs/DebitDialog';
import FinalizedPay from '../Dialogs/FinalizedPay';
import { Avatar, Box, Grid, Slide, Tooltip, withWidth} from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import { CaptchSiteKey, ConstantsCrypto, EndPoints, url } from '../../../constants/types';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CryptoJS from 'crypto-js'
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import MenuItem from '@material-ui/core/MenuItem';
import ReCAPTCHA from "react-google-recaptcha";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextsmsIcon from '@material-ui/icons/Textsms';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme)=>({

    appBarCartDialog: {
      position: 'relative',
      backgroundColor:'#000'
    },
    titleDialog: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    payCardTitle:{
        width:'100%',
        paddingTop:'30px',
        fontWeight:"bold",
        textAlign:'center'
        
    },
    dividerLeft:{
      
        float:"left",
        marginTop:'8px',
        /*[theme.breakpoints.down("xs")]: {
          width:'23%',
        },*/
        width:'100%',
        borderTop: 'solid 1px #D6D6D6'

    },
    dividerRight:{
      float:"right",
        /*[theme.breakpoints.down("xs")]: {
          width:'25%',
        },*/
        marginTop:'8px',
        width:'100%',
        borderTop: 'solid 1px #D6D6D6'

    },
    
    payCardFont:{
        marginLeft:'5px',
        marginRight:'5px',

        fontSize:'15px',
        [theme.breakpoints.down('xs')]:{
          fontSize:'13px',
          
        },
        textAlign: "center",
        fontWeight:'bolder'
    },
    groupButtonContainer:{
        marginTop:'25px',
        backgroundColor: 'white',
        alignContent:'center',
        width:'100%'
    },

    GroupButton: {
      width:'100%',
      height:'40px',
      fontFamily:'SF Pro Display',
      fontSize:'13px',
      color:'#000',
      backgroundColor: 'white',
      '&:hover': {
        color:'#ffffff',
        backgroundColor:'#000',
      },
      
    },

    GroupButtonActived: {
      width:'100%',
      height:'40px',
      fontSize:'13px',
      fontFamily:'SF Pro Display',
      backgroundColor: '#000',
      '&:hover': {
        color:'#ffffff',
        backgroundColor:'#000',
      },
    },

    formContainer:{
      width:"100%",
      
    },

    formContainerTitle:{
      fontSize:'15px',
      marginTop:'40px',
      marginBottom:'5px'
    },

    form:{
      "& .MuiTextField-root": {
        margin: theme.spacing(0),
        "& fieldset": {
          borderRadius: 0
        }
      },
      
    },

    InputAdornmentNumber:{
      marginRight:60
    },

    InputAdornmentTypeCards:{
      marginRight:0
    },

    InputAdornmentTypeCreditCards:{
      marginRight:0
    },
    marginTextFieldCardNumber:{
      width:'100%',
      
      '& fieldset': {
        borderRadius: 0,
        fontSize:20
      },
      
    },
    formControl:{
        width:'100%',
    },
    select: {
        height: "7vh",
        borderRadius: 0
      },
    marginTextFieldCvv:{
     
      width:'50%',
      '& fieldset': {
        borderRadius: 0,
        fontSize:20
        
      },
      
    },
    marginTextFieldCedula:{
      
      width:'50%',
      '& fieldset': {
        borderRadius: 0,
        fontSize:20
      },
     
      
    },

    totalPrice:{
      fontSize:28,
      padding:theme.spacing(2,0,0,0),
      [theme.breakpoints.down("xs")]:{
        padding:theme.spacing(0,0,0,-2),
      },
      
      fontWeight:'light',
      textAlign:'center',
    },
    avatarContainer:{
      width:'100%',
      margin:theme.spacing(2,0,3,0),
      [theme.breakpoints.down("xs")]:{
        margin:theme.spacing(2,0,3,0),
      },
      /* marginBottom:theme.spacing(2),
      marginTop:theme.spacing(1),
      marginLeft:theme.spacing(2),
      marginRight:theme.spacing(-2), */
      //border:'solid 1px red'
    },
    avatarHeader:{
        float:"left",
        width: theme.spacing(3),
        height: theme.spacing(3),
        [theme.breakpoints.down("xs")]:{
          width: theme.spacing(4),
          height: theme.spacing(4),
          marginTop: theme.spacing(-1)
        }
        //marginRight:"83%"
    },

    usernameCheckout:{
      [theme.breakpoints.down("xs")]:{
        margin: theme.spacing(-0.5,0,0,2),
      },
      margin: theme.spacing(0,0,0,1),
      fontSize: 16, 
    },

    shoppingBag:{
      float:'right',
      marginLeft:18
    },
    companyName:{
      margin:theme.spacing(0),
      fontStyle: 'italic',
      fontWeight:'bold',
      fontSize:30
    },
    boxContainerFooter:{
      width:'100%',
      textAlign:'center',
    },

    boxContainerFooterDevice:{
      width:'70%',
      margin: "auto",
      paddingTop:10,
      textAlign:'center',
    },
    footerText:{
      fontSize:12,
      [theme.breakpoints.down("xs")]: {
        fontSize:12
      },
      color:'#000',
    },
    
    footerTextDialog:{
      position:'absolute',
      bottom:40,
      right:40,
      left:40,
      fontSize:10
    },
    listContainer:{
      marginTop:theme.spacing(6),
      maxHeight:'60%',
      overflow:'auto'
    },
    buttonProgress: {
      color: "#000",
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    body:{
  
        flexGrow: 1,
        height: '100%',
        marginTop:'15%',
       
        margin:theme.spacing(0,10,0,10),
        [theme.breakpoints.down("xs")]:{
            margin:theme.spacing(3,3,0,3),
        },
        [theme.breakpoints.only("xl")]:{
          margin:theme.spacing(20,20,0,20),
        }
    },
    footer:{
      textAlign:'center',
      bottom:0,
      right:0,
      position: "absolute",
      width: "100%",
      fontSize:'10px',
      marginBottom:"2.3%",
      
  },
  recaptchaContainer:{
    width:'100%',
    display:"flex"
   
  },
  recaptcha:{
    
    display: "block",
    marginTop:10, 
    marginLeft: "auto",
    marginRight: "auto"

  },
  marginTextFieldCardNumber:{
    width:'100%',
    '& fieldset': {
      borderRadius: 0,
      fontSize:17
    },
    
  },

  marginTextFieldOtp:{
    width:'100%',
    '& fieldset': {
      borderRadius: 0,
      fontSize:17
    },
    
  },
  codeConfirm:{
    fontWeight:'bolder',
    color:'gray',
    paddingTop:10
  },  
  toolT : {
    fontSize:17
  }
}))

const ColorButton = withStyles((theme) => ({
    root: {
      marginTop:'40px',
      width:'100%',
      height:'40px',
      fontSize:'15px',
      backgroundColor: '#000',
      '&:hover': {
        backgroundColor:'#000',
      },

    },
  }))(Button);

const PayButton = withStyles((theme) => ({
  root: {
    marginTop:'40px',
    width:'100%',
    height:'40px',
    fontSize:'15px',
    backgroundColor: '#000',
    '&:hover': {
      backgroundColor:'#000',
    },

  },
}))(Button);

const Accept = withStyles((theme) => ({
  root: {
    marginTop:'40px',
    marginLeft:20,
    marginRight:20,
    marginBottom:20,
    width:'100%',
    height:'40px',
    fontSize:'15px',
    backgroundColor: '#000',
    '&:hover': {
      backgroundColor:'#000',
    },

  },
}))(Button);

const Otp = withStyles((theme) => ({
  root: {
    marginTop:'45px',
    width:'100%',
    height:'40px',
    fontSize:'15px',
    backgroundColor: '#000',
    '&:hover': {
      backgroundColor:'#000',
    },

  },
}))(Button);



 function CheckoutComponent(props){
  
    const isDevice = useMediaQuery({
      query: '(max-device-width: 768px)'
    })
    const classes = useStyles();
    const [cardType, setCardType] = useState("credito")
    const [openModal,setOpenModal] = useState(false)
    const [openModalSuccess,setOpenModalSuccess] = useState(false)
    const [cardNumber,setCardNumber] = useState("")
    const [cvvNumber,setCvvNumber] = useState("")
    const [cedulaNumber,setCedulaNumber] = useState("")
    const [venc,setVenc] = useState("")
    const [emptycardNumber,setEmptycardNumber] = useState(false)
    const [emptycvvNumber,setEmptycvvNumber]= useState(false)
    const [emptycedulaNumber,setEmptycedulaNumber]= useState(false)
    const [emptyselect,setemptyselect]= useState(false)
    const [emptyvenc,setemptyvenc] = useState(false)
    const [loading,setLoading]=useState(false)

    const [alertOpen,setAlertOpen]=useState(false)
    const [messageError, setMessageError]=useState()
    const [statusTrx, setStatusTrx]=useState()
    const [select, setSelect] = React.useState("");
    const [captcha, setCaptcha] =useState(false);

    const [emptyselectpagomovil,setEmptyselectPagoMovil]=useState(false)
    const [emptyTelefonoDestino,setEmptyTelefonoDestino]=useState(false)
    const [emptyCedulaPagoMovil,setEmptyCedulaPagoMovil]=useState(false)
    const [emptyOtp, setEmptyOtp]=useState(false)


    const [modalPagoMovil, setModalPagoMovil] = useState(false)
    const [selectCuentaPagoMovil, setSelectCuentaPagoMovil] = useState("")
    const [telefonoDestino, setTelefonoDestino] = useState("")
    const [cedula, setCedula] =useState("")
    const [otp, setOtp] = useState("")
    const [loadingPagoMovil, setLoadingPagoMovil] =useState(false)
    const [ref, setRef] = useState()
    
    const checkoutIcon = (
      <Icon style = {{paddingRight:60}}>
        <img alt="edit" src="../img/iconsrc.png" style ={{"height": "20px",marginBottom:20}} />
      </Icon>
    );
   
    const maestroIcon = (
      <Icon style = {{paddingRight:100}}>
        <img alt="edit" src="../img/footerimg.png" style ={{"height": "20px"}} />
        <img alt="edit" src="../img/maestro.png" style ={{"height": "20px"}} />
        <img alt="edit" src="../img/visa.png" style ={{"height": "20px",paddingLeft:5}} />
      </Icon>
    );

    const creditIcons =(
      <Icon style = {{paddingRight:50}}>
        <img alt="edit" src="../img/visa.png" style ={{"height": "20px", paddingRight:10}} />
        <img alt="edit" src="../img/mastercard.png" style ={{"height": "20px"}} />
      </Icon>
    );

    
    const mercantilIconDevice=(
      <Icon>
        <img alt="edit" src="../img/footerimg.png" style ={{"height": "40px","marginTop":6}}  />
      </Icon>
    )


    const mercantilIcon=(
      <Icon>
        <img alt="edit" src="../img/footerimg.png" style ={{"height": "20px","marginTop":2}}  />
      </Icon>
    )

    function handleClickGroup(type,event){
     
        
        let form = document.getElementById("payForm")
        if(type==="credito"){
          setCardType("credito")
          setCardNumber("")
          setCvvNumber("")
          setCedulaNumber("")
          form.reset()
        }else {
          setCardType("debito")
          setCardNumber("")
          setCvvNumber("")
          setCedulaNumber("")
          form.reset()
        }
      
    }

    function handleClikPay(){
     
      let isCardnumberEmpty = isEmpty(cardNumber,"cardnumber")  
      let isCvvNumberEmpty = isEmpty(cvvNumber,"cvv")
      let isCedulaNumberEmpty = isEmpty(cedulaNumber,"cedula")
      let isVencEmpty = isEmpty(venc,"venc")
  
      let isCardNumberCorrect = isFieldIncorrect(cardNumber,"cardnumber")
      let isCvvNumberCorrect = isFieldIncorrect(cvvNumber,"cvv")
      let isCedulaNumberCorrect = isFieldIncorrect(cedulaNumber,"cedula")
      let isVencCorrect = isFieldIncorrect(venc,"venc")
      
      let iscaptchaClicked = captcha
    
      
      if(isCardnumberEmpty|| isCvvNumberEmpty || isCedulaNumberEmpty ){
        return
      }
      if(isCardNumberCorrect|| isCvvNumberCorrect || isCedulaNumberCorrect || isVencCorrect){
        return
      }
      if(cardType === "credito"){
       if(!isVencEmpty && !isVencCorrect && iscaptchaClicked ){
         
        payCreditCard (cardNumber,cvvNumber,cedulaNumber,venc)
       }else{
         return
       }
        
        //openmodalsuccess(true)
      }else{
          if(select === "" ){
              setemptyselect(true)
              return
          }
          if(!iscaptchaClicked){
            return
          }
          
        openmodal(true)
      }
      
    }
   
    function handleClickPayPagoMovil(){
      if(selectCuentaPagoMovil === ""){
        setEmptyselectPagoMovil(true)
        return
      }else if(telefonoDestino ==="" || telefonoDestino.replace(/[.*+\-?^${}()|[\]\\]/g,'').toString().length < 12){
        setEmptyTelefonoDestino(true)
        return
      }else if(cedula === "" || cedula.toString().length<6){
        setEmptyCedulaPagoMovil(true)
        return
      }else if(otp === "" || otp.toString().length<4 ){
        setEmptyOtp(true)
        return
      }else{
        payPagoMovil()
      }
    }
    
    const payCreditCard =(...prop)=>{
      const Url = url+EndPoints.crearPagoCheckout
     // let cardNumber = CryptoJS.AES.encrypt(props[0].toString(), ConstantsCrypto.MasterKeyCrypto);
      let cvvNumber = CryptoJS.AES.encrypt(prop[1].toString(), ConstantsCrypto.MasterKeyCrypto);
      let cedulaNumber = prop[2].toString();
      setLoading(true)
      let icm = props.propsTrx[1].toString()
      /* return console.log(cardNumber) */
      axios.post(Url, {
        cvv:cvvNumber.toString(),
        exped :prop[3].toString(),
        cardholderId:cedulaNumber.toString(),
        id_commerce:icm.toString(),
        amount:props.propsTrx[3].toString().replace(",","."), 
        cardnumber:cardNumber.toString(),
        rif:props.propsTrx[0].toString(), //este dato lo capturamos x get url 
        tipo_tx:"tdc",
        tipo_cuenta:"",
        twofactor_auth:"",
        
        browserAgent:{
          ipaddress:props.propsTrx[4].getBrowserAgent[0].ipAddress,
          agent: props.propsTrx[4].getBrowserAgent[0].agent,
          manufacturer: props.propsTrx[4].getBrowserAgent[0].manufacturer,
          model:props.propsTrx[4].getBrowserAgent[0].model,
          os_version:props.propsTrx[4].getBrowserAgent[0].os_version,
          lat:props.propsTrx[4].getBrowserAgent[0].lat.toString(),
          lng:props.propsTrx[4].getBrowserAgent[0].lng.toString(),
    
        }
      },{
        timeout:30000, 
        headers: { 
         
          "Authorization":props.propsTrx[2]
          
        }
      })
      .then(function(data){
        setLoading(false)
       
        if(data.data.exito){
         
          setRef(data.data.ref)
          openmodalsuccess(true,"success")
        }else{
        
          setRef(data.data.ref)
          openmodalsuccess(true,"rejected")
        }

      })
      .catch(function(err){
        
        setLoading(false)
        let code = err.code
        if(code === "ECONNABORTED"){
          setAlertOpen(true)
          setMessageError("Error de timeout")
        }else{
          openmodalsuccess(true,"rejected")
        }
        
        //window.top.location.href = urlCurrent; 
        
      })
    }
  
    const payPagoMovil = () =>{
      const Url =  url+EndPoints.crearPagoC2PPlink
      
      //return console.log()
      //setCardNumber(cardNumber.toString())
      /* let cvvNumber = CryptoJS.AES.encrypt(prop[1].toString(), ConstantsCrypto.MasterKeyCryptoC2P);
      let cedulaNumber = prop[2].toString(); */
      setLoadingPagoMovil(true)

      let icm =props.propsTrx[1].toString()
      
      let jsonRequest = {
        "destination_bank_id":selectCuentaPagoMovil,
        "destination_id":CryptoJS.AES.encrypt("V"+cedula,'myPassword').toString(),
        "destination_mobile_number":CryptoJS.AES.encrypt(telefonoDestino.replace(/[.*+\-?^${}()|[\]\\]/g,'').toString(),'myPassword').toString(),
        "origin_mobile_number":CryptoJS.AES.encrypt(props.companyProps[3],'myPassword').toString(),
        "invoice_number":"",
        "twofactor_auth":CryptoJS.AES.encrypt(otp,'myPassword').toString(),
        "cardholderId": CryptoJS.AES.encrypt("V"+cedula,'myPassword').toString(),
        "Rif": props.propsTrx[0].toString(),
        "Amount": props.propsTrx[3].toString().replace(",","."),
        "id_commerce":icm,
        "browserAgent":{
                  "ipaddress": props.propsTrx[4].getBrowserAgent[0].ipaddress,
                  "agent": props.propsTrx[4].getBrowserAgent[0].agent,
                  "manufacturer": props.propsTrx[4].getBrowserAgent[0].manufacturer,
                  "model":props.propsTrx[4].getBrowserAgent[0].model,
                  "os_version": props.propsTrx[4].getBrowserAgent[0].os_version,
                  "lat":props.propsTrx[4].getBrowserAgent[0].lat,
                  "lng":props.propsTrx[4].getBrowserAgent[0].lng
          }
      };
      
      axios.post(Url,jsonRequest ,{
        timeout:300000, 
        headers: { 
          "Authorization":props.propsTrx[2],
          
        }
      })
      .then(function(data){
        setLoadingPagoMovil(false)
        /* if(data.data.error_list!== undefined){ */
          if(data.data.exito){
            
            setRef(data.data.mensaje.referencia)
            openmodalsuccess(true,"success")
          }else{
            openmodalsuccess(true,"rejected")
          }
       /*  }else{
          openmodalsuccess(true,"rejected")
        } */
       /*  if(data.data.error_list!== undefined){
          if(data.data.transaction_c2p_response.trx_status === "approved"){
            openmodalsuccess(true,"success")
          }else{
            openmodalsuccess(true,"rejected")
          }
        }else{
          openmodalsuccess(true,"rejected")
        } */
        
        
      })
      .catch(function(err){
        
        setLoadingPagoMovil(false)
        let code = err.code
        if(code === "ECONNABORTED"){
          openmodalsuccess(true,"rejected")
        }else{
          openmodalsuccess(true,"rejected")
        }
        
        //window.top.location.href = urlCurrent; 
        
      })
      
    }
    
    function isEmpty(field,type){
        
      if(field===""){
        if(type === "cardnumber"){
          setEmptycardNumber(true)
          return true
        }else if(type ==="cvv"){
          setEmptycvvNumber(true)
          return true
        }else if(type ==="cedula"){
          setEmptycedulaNumber(true)
          return true
        }else if(type==="venc"){
          setemptyvenc(true)
          return true
        }

        return true
      }else{
        if(type === "cardnumber"){
          setEmptycardNumber(false)
          return false
        }else if( type ==="cvv"){
          setEmptycvvNumber(false)
          return false
        }else if(type ==="cedula"){
          setEmptycedulaNumber(false)
          return false
        }else if(type==="venc"){
          setemptyvenc(false)
          return false
        }
        return false
      }
    }
    
    function isFieldIncorrect(field,type){
      
      if(type === "cardnumber" && cardType ==="credito"){
        let card = CryptoJS.AES.decrypt(field.toString(), ConstantsCrypto.MasterKeyCrypto ).toString(CryptoJS.enc.Utf8);
        card = card.replace(/ /g,"")
        
        if(card.length <16){
          setEmptycardNumber(true)
          return true
        }else{
          if(isNumber(card)){
            setEmptycardNumber(false)
            return false
          }else{
            setEmptycardNumber(true)
            return true
          }
  
        }
      }else if(type === "cardnumber" && cardType ==="debito"){
        if(cardNumber.length<16 || cardNumber.length===17){
          setEmptycardNumber(true)
          return true
        }else{
          if(isNumber(cardNumber)){
            setEmptycardNumber(false)
            return false
          }else{
            setEmptycardNumber(true)
            return true
          }
        
        }
      }else if(type ==="cvv"){
        if(cvvNumber.length<3){
          setEmptycvvNumber(true)
          return true
        }else{
          if(isNumber(cvvNumber)){
            setEmptycvvNumber(false)
            return false
          }else{
            setEmptycvvNumber(true)
            return true
          }
          
               
          
        }
        
     
     
      }else if(type ==="cedula"){
        if(cedulaNumber.length<8){
          setEmptycedulaNumber(true)
          return true
        }else{
          if(isNumber(cedulaNumber)){
            setEmptycedulaNumber(false)
            return false
          }else{
            setEmptycedulaNumber(true)
            return true
          }
        }
        
      }else if(cardType ==="credito" && type ==="venc"){
        if(venc.length<7){
          setemptyvenc(true)
          return true
        }else{
          /* if(isNumber(venc)){
            setemptyvenc(false)
            return false
          }else{
            setemptyvenc(true)
            return true
          } */
          let arrayString = [...venc]
          let findit = false;
          arrayString.map((value,index)=>{
              if(index != 2){
                if(value === '/'){
                  findit = true
                  return findit
                }
              }
          })
          if(findit){
            setemptyvenc(true)
            return true
          }else{
            let vencNumber = venc.replace(/[/]/g,"")
            
            if(isNumber(vencNumber)){
              setemptyvenc(false)
              return false
            }else{
              setemptyvenc(true)
              return true
            }

            
          }
        
        }
      }
    }
    
    const isNumber=(digit)=>{
      var numbers = /^[0-9]+$/;
          if(digit.match(numbers))
            {
            return true
            }
              else
              {
                return false
              }
    }

    const handleClose = ()=>{
        props.updateNextComponent("amount")
        //setOpen2(true)
    }

    const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setAlertOpen(false);
    };

    function validate(e){
      var key = window.event ? e.which : e.keyCode;
      if(cardType==="credito" && e.target.id === "cardnumber"){
        if(e.target.value.length===4 || e.target.value.length===9 ||e.target.value.length===14){
          return e.target.value += " "
        }
      }

      if(cardType ==="credito" && e.target.id==="venc"){
        if(e.target.value.length===2){
          return e.target.value += "/"
        }
      }
     
      if (key < 48 || key > 57) {
        e.preventDefault();
      }

      
    }

    function openmodal(openModal){
      setOpenModal(openModal)
    }
    
    function openmodalsuccess(openModalSuccess,status){
      setStatusTrx(status)
      setOpenModalSuccess(openModalSuccess)
    }
  
    const openMod = (e,status)=>{
        if(cardType==="credito"){
          openmodalsuccess(e)
          handleClose()
        }else{
            openmodal(e)
        }
        
        
    }

    const openModalPagoMovil = (e) =>{
      e.preventDefault()
      setModalPagoMovil(true)
    }

    const closeModalPagoMovil = (e) =>{
      e.preventDefault()
      setModalPagoMovil(false)
    }
    
    const DebitTransactionFinish = (e) =>{
        props.updateNextComponent("amount")
        //setOpen2(true)
    }

    const noPaste = (e)=>{
      e.preventDefault(); 
      return false
    }

    const CustomAlert = (props)=>{
      return(
        <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="error">
          {props.message}
        </Alert>
      </Snackbar>
      )
    }

    const handleChange = event => {
        setSelect(event.target.value);
      };

    const handleChangeSelectPagoMovil = event =>{
      if(emptyselectpagomovil && event.target.value !== ""){
        setEmptyselectPagoMovil(false)
      }
      setSelectCuentaPagoMovil(event.target.value)
    }  
    
    const handleChageDestinyPhone = event =>{
      if(emptyTelefonoDestino && event.target.value !== ""){
        setEmptyTelefonoDestino(false)
      }
      setTelefonoDestino(event.target.value)
    }

    const handleChageCedulaPhone = event =>{
      if(emptyCedulaPagoMovil && event.target.value !== ""){
        setEmptyCedulaPagoMovil(false)
      }
      setCedula(event.target.value)
    }

    const handleChangeCardnumberCredit = e =>{
        let cardnumber = CryptoJS.AES.encrypt(e.target.value.replace(/ /g,""), ConstantsCrypto.MasterKeyCrypto)
        setCardNumber(cardnumber.toString())
    }

    const handleChangeOtp =event =>{
      if(emptyOtp && event.target.value !== ""){
        setEmptyOtp(false)
      }
      setOtp(event.target.value)
    }
    
    function onChange(value) {
      
      if(value!=="" && value !== undefined && value !== null){
        setCaptcha(true)
      }
      
    }
    
    function maskField(e){
      let rx_live     
      rx_live = /^[0-9]?\d*$/;
            
                if(e.target !==undefined){
                
                  let reg = rx_live.test(e.target.value.replace(/\+|\-|\(|\)/g,''))
                  if(!reg){
                    e.preventDefault()
                  }
                
                  if(e.target.value.length ===0){
                    return e.target.value+= "+58("+e.target.value
                  }
                  if(e.target.value.length ===3){
                    return e.target.value+= "("
                  }
                  if(e.target.value.length ===7){
                    return e.target.value += ")"
                  }
                  if(e.target.value.length ===11 ||e.target.value.length ===14){
                    return e.target.value+= "-"
                  }
                
                }

           
          
    }
    
    const openSmsApp = event =>{

      if (navigator.userAgent.match (/Android/i)) {

        window.open (`sms://24024/?body=SCP`)
        
      }
      if(navigator.userAgent.match(/iPhone/i)) {
  
        window.open(`sms://24024/&body=body=SCP`)
      }
    }
    return (
        <React.Fragment>
            <Slide direction="left" in={true} mountOnEnter unmountOnExit>
            <div className={classes.body}>
                <CustomAlert message ={messageError} />
                              <Dialog
                                open={modalPagoMovil}
                                onClose={closeModalPagoMovil}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                fullWidth={true}
                                maxWidth = {'xs'}
                                //TransitionComponent={Transition}
                                
                              >
                              <DialogTitle id="alert-dialog-title">
                                <Grid container component = "main" >
                                      <Grid item xs={12} sm = {12} md={12} >
                                        <Typography className={classes.titleHeader}>INFORMACIÓN</Typography>
                                    </Grid>   
                                </Grid>
                              </DialogTitle>

                              <DialogContent >
                                <DialogContentText id="alert-dialog-description" >
                                          
                                </DialogContentText>
        <form id = "payForm" autoComplete="off" component ='form'>
            <Typography className={classes.codeConfirm}>Banco:</Typography>
            
            <FormControl variant="outlined" className={classes.formControl}>
                                <Select
                                value={selectCuentaPagoMovil}
                                error = {emptyselectpagomovil}
                                onChange={handleChangeSelectPagoMovil}
                                displayEmpty
                                className={classes.select}
                                inputProps={{ "aria-label": "Without label" }}
                                >
                                    <MenuItem value="">
                                    <Typography style={{color:'grey'}}>--Selecciona--</Typography> 
                                    </MenuItem>
                                    <MenuItem value={105}><Typography style={{color:'grey'}}>Mercantil Banco S.A.</Typography> </MenuItem>
                                    <MenuItem value={108}><Typography style={{color:'grey'}}>Banco Provincial</Typography> </MenuItem>
                                    <MenuItem value={163}><Typography style={{color:'grey'}}>Banco del Tesoro</Typography> </MenuItem>
                                    <MenuItem value={134}><Typography style={{color:'grey'}}>Banesco</Typography> </MenuItem>
                                    <MenuItem value={102}><Typography style={{color:'grey'}}>Banco de Venezuela</Typography> </MenuItem>
                                    <MenuItem value={104}><Typography style={{color:'grey'}}>Venezolano de Crédito</Typography> </MenuItem>
                                    <MenuItem value={114}><Typography style={{color:'grey'}}>Bancaribe</Typography> </MenuItem>
                                    <MenuItem value={115}><Typography style={{color:'grey'}}>Banco Exterior</Typography> </MenuItem>
                                    <MenuItem value={116}><Typography style={{color:'grey'}}>BOD</Typography> </MenuItem>
                                    <MenuItem value={137}><Typography style={{color:'grey'}}>Sofitasa</Typography> </MenuItem>
                                    <MenuItem value={138}><Typography style={{color:'grey'}}>Banco Plaza</Typography> </MenuItem>
                                    <MenuItem value={172}><Typography style={{color:'grey'}}>Bancamiga</Typography> </MenuItem>
                                    <MenuItem value={191}><Typography style={{color:'grey'}}>BNC</Typography> </MenuItem>
                                    <MenuItem value={151}><Typography style={{color:'grey'}}>BFC</Typography> </MenuItem>
                                    <MenuItem value={169}><Typography style={{color:'grey'}}>Mi Banco</Typography> </MenuItem>
                                    <MenuItem value={174}><Typography style={{color:'grey'}}>Banplus</Typography> </MenuItem>
                                
                                </Select>
              </FormControl>
              
              <Typography className={classes.codeConfirm}>Teléfono :</Typography>
                <TextField
                            autoComplete="off"
                            error = {emptyTelefonoDestino}
                            variant="outlined"
                            margin="normal"
                            color = "primary"
                            required
                            size='small'
                           /*  id="otp"
                            name="otp" */
                            placeholder="+58(424)000-22-00"
                            //value={telefonoDestino}
                            className = {classes.marginTextFieldCardNumber}
                            type='tel'
                            onChange ={handleChageDestinyPhone}
                            disabled = {loading}
          
                            onPaste={noPaste}
                            onKeyPress={
                              (e)=>{
                                /* validate(e) */
                                maskField(e)
                              }
                            }
                            /*InputProps={{
                      
                              endAdornment:(
                                <InputAdornment position="end" className = {classes.InputAdornmentTypeCards}>
                                    {maestroIcon}
                                </InputAdornment>
                              )
                            }}
                            */
                            inputProps={{
                              
                              maxLength: 17,
                              inputMode:'tel'
                              
                            
                            }}
                            
                            
                        /> 
                        

                        <Typography className={classes.codeConfirm}>Cédula:</Typography>
                              <TextField
                                          autoComplete="off"
                                          error = {emptyCedulaPagoMovil}
                                          variant="outlined"
                                          margin="normal"
                                          color = "primary"
                                          required
                                          size='small'
                                        /*  id="otp"
                                          name="otp" */
                                        /*  */  /* placeholder="+58(424)000-22-00" */
                                          //value={telefonoOrigen}
                                          className = {classes.marginTextFieldCardNumber}
                                          type='tel'
                                          onChange ={handleChageCedulaPhone}
                                          disabled = {loading}
                                          
                                          onPaste={noPaste}
                                          onKeyPress={
                                            (e)=>{
                                              validate(e)
                                          
                                            }
                                          }
                                          /*InputProps={{
                                    
                                            endAdornment:(
                                              <InputAdornment position="end" className = {classes.InputAdornmentTypeCards}>
                                                  {maestroIcon}
                                              </InputAdornment>
                                            )
                                          }}
                                          */
                                          inputProps={{
                                            
                                            maxLength: 8,
                                            inputMode:'tel'
                                            
                                          
                                          }}
                                          
                                          
                                      /> 

                        <Grid container spacing={2}>
                                <Grid item xs = {6} sm={6} xl = {12} lg = {12} md ={12}>
                                <Typography className={classes.codeConfirm}>Otp:</Typography>
                                <Tooltip title="Envía la palabra SCP al Numero 24024" classes={{ tooltip: classes.toolT }} arrow>
                              <TextField
                                          autoComplete="off"
                                          error = {emptyOtp}
                                          variant="outlined"
                                          margin="normal"
                                          color = "primary"
                                          
                                          size='small'
                                        /*  id="otp"
                                          name="otp" */
                                          //placeholder="+58(424)000-22-00"
                                          //value={telefonoOrigen}
                                          className = {classes.marginTextFieldOtp}
                                          type='password'
                                          onChange ={handleChangeOtp}
                                          disabled = {loading}
                                          
                                          onPaste={noPaste}
                                          onKeyPress={
                                            (e)=>{
                                              validate(e)
                                             }
                                          }
                                          /*InputProps={{
                                    
                                            endAdornment:(
                                              <InputAdornment position="end" className = {classes.InputAdornmentTypeCards}>
                                                  {maestroIcon}
                                              </InputAdornment>
                                            )
                                          }}
                                          */
                                          inputProps={{
                                            
                                            maxLength: 17,
                                            inputMode:'password'
                                            
                                          
                                          }}
                                          
                                          
                                      /> 
                                      </Tooltip>
                                </Grid>

                                {isDevice &&
                                
                                <Grid item sm={6}>
                                    <Otp variant="contained" color="primary" onClick={openSmsApp} >Enviar Otp</Otp>
                                </Grid>
                                
                                
                                }
                                
                        </Grid>       
                        


              
            </form>
                                {/* <ConfimrCodeComponent/> */}
                              </DialogContent>
                              <DialogActions>
                                  
                                <Accept variant="contained" color="primary" onClick={handleClickPayPagoMovil} startIcon ={loadingPagoMovil?"":checkoutIcon} disabled = {loadingPagoMovil}  autoFocus>
                                {loadingPagoMovil && <CircularProgress size={24} className={classes.buttonProgress}/>}
                                        Pagar
                                </Accept>
                              </DialogActions>
                    </Dialog>
                    {
                    cardType === "credito" ? 
                        <FinalizedPay  open = {openModalSuccess} updateModal = {openMod} isDebitTransactionFinish = {DebitTransactionFinish}  statusTrx = {statusTrx} trxData={[cardNumber.toString(),props.propsTrx[3].toString(),ref]} tipo_operacion = "c2p" /> :  
                        <DebitDialog open = {openModal} updateModal = {openMod} isDebitTransactionFinish = {DebitTransactionFinish} PayTrxProps={[cardNumber,cvvNumber,cedulaNumber,select,props.propsTrx[0],props.propsTrx[1].toString(),props.propsTrx[2],props.propsTrx[3],props.propsTrx[4]]}/>
                    }
                
                
                
                    
                    <div className={classes.avatarContainer}>
                    <Grid container component = "main" >
                      <Grid item xs={1} >
                        <ArrowBackIosRoundedIcon fontSize ="small" onClick={handleClose}/>
                      </Grid>
                      <Grid item xs={1} >
                        <Avatar alt="A" src={props.companyProps[0]} className={classes.avatarHeader} ></Avatar>
                        
                      </Grid>
                      <Grid item xs={10}>
                       <Typography className={classes.usernameCheckout}>{props.companyProps[1]}</Typography>
                      </Grid>
                    </Grid>
                    
                    
                    
                    </div>
                    
                    
                
                    <Typography  className = {classes.totalPrice}>
                    {new Intl.NumberFormat("de-DE",{minimumFractionDigits:2}).format(props.propsTrx[3])} Bs
                    </Typography>
                    
                    <ColorButton variant="contained" color="primary" onClick = {openModalPagoMovil}> Pago móvil</ColorButton>
                    <Grid container component = "main" className={classes.payCardTitle}>
                    <Grid item xs={3} sm = {3} md={3}>
                        <div className = {classes.dividerLeft}></div>
                    </Grid>

                    <Grid item xs={6} sm = {6} md={6}>
                        <Typography className = {classes.payCardFont} >
                        Ó paga con tu tarjeta
                        </Typography>
                    </Grid>

                    <Grid item xs={3} sm = {3} md={3}>
                        <div className ={classes.dividerRight}></div>
                    </Grid>
                    </Grid>
                
                
                    <div className={classes.groupButtonContainer}>
                        <ButtonGroup  variant="contained" color="primary" style={{width:'100%'}} >
                            <Button className={ 
                                                cardType === "credito" || cardType === "" ?
                                                classes.GroupButtonActived :
                                                classes.GroupButton
                                            } 
                                    style={{fontSize:'13px'}}         
                                    id="credito" 
                                    onClick={(event)=> handleClickGroup("credito",event)}>Crédito</Button>
                        
                            <Button className={
                                                cardType === "debito"  ?
                                                classes.GroupButtonActived :
                                                classes.GroupButton

                                            } 
                                            style={{fontSize:'13px'}} 
                                    id="debito"
                                    onClick={(event)=> handleClickGroup("debito",event)}>Débito</Button>
                        </ButtonGroup>
                    </div>

                    <div className= {classes.formContainer}>
                    <div className={classes.formContainerTitle}><Typography>DATOS DE TARJETA</Typography></div>
                        
                        <form className={classes.form} id = "payForm" autoComplete="off" component ='form'>
                        
                        { cardType==="debito"?
                            <TextField
                            error = {emptycardNumber}
                            variant="outlined"
                            margin="normal"
                            color = "primary"
                            required
                            size="small"
                            id="cardnumber"
                            name="carnumber"
                            className = {classes.marginTextFieldCardNumber}
                            type='tel'
                            onChange ={e => setCardNumber(e.target.value)}
                            disabled = {loading}
                            autoComplete="off"
                            onPaste={noPaste}
                            onKeyPress={e=>validate(e)}              
                            InputProps={{
                        
                                endAdornment:(
                                <InputAdornment position="end" className = {classes.InputAdornmentTypeCards}>
                                    {maestroIcon}
                                </InputAdornment>
                                )
                            }}
                            
                            inputProps={{
                                
                                maxLength: 18,
                                placeholder: "NÚMERO DE TARJETA",
                                
                            
                            }}
                            
                            
                        /> :
                        <TextField
                                error = {emptycardNumber}
                                variant="outlined"
                                margin="normal"
                                color = 'primary'
                                size="small"
                                required
                                fullWidth
                                id="cardnumber"
                                name="carnumber"
                                className = {classes.marginTextFieldCardNumber}
                                onChange ={handleChangeCardnumberCredit }
                                disabled = {loading}
                                onPaste={noPaste}
                                type='tel'
                                autoComplete="off"
                                onKeyPress={e=>validate(e)}
                                InputProps={{
                                
                                endAdornment:(
                                    <InputAdornment position="end" className = {classes.InputAdornmentTypeCreditCards}>
                                        {creditIcons}
                                    </InputAdornment>
                                )
                                }}

                                inputProps={{
                                  placeholder: "1234 1234 1234 1234",
                                  maxLength: 19,
                              }}
                        
                                
                                
                                
                            /> 
                        
                        }
                            
                            
                        {
                          cardType === "credito"?
                          <TextField
                                error = {emptyvenc}
                                variant="outlined"
                                margin="normal"
                                color = "primary"
                                required
                                id="venc"
                                name="venc"
                                size="small"
                                onKeyPress={e=>validate(e)}
                                onChange ={e => setVenc(e.target.value)}
                                disabled = {loading}
                                onPaste={noPaste}
                                type='tel'
                                autoComplete="off"
                                className = {
                                classes.marginTextFieldCvv
                                }
                                inputProps={{
                                maxLength: 7,
                                placeholder:"MM/YYYY",
                                inputMode:'tel'
                                }}
                                
                            
                            />
                          :
                          <TextField
                                error = {emptycvvNumber}
                                variant="outlined"
                                margin="normal"
                                color = "primary"
                                required
                                id="cvv"
                                name="cvv"
                                size="small"
                                onKeyPress={e=>validate(e)}
                                onChange ={e => setCvvNumber(e.target.value)}
                                disabled = {loading}
                                onPaste={noPaste}
                                type='password'
                                autoComplete="off"
                                className = {
                                classes.marginTextFieldCvv
                                }
                                inputProps={{
                                maxLength: 3,
                                placeholder:"CVV",
                                /* inputMode:'tel' */
                                }}
                                
                                
                            
                            />
                        }
                        
                        {
                          cardType==="credito"?
                          <TextField
                                error = {emptycvvNumber}
                                variant="outlined"
                                margin="normal"
                                color = "primary"
                                required
                                id="cvv"
                                name="cvv"
                                size="small"
                                onKeyPress={e=>validate(e)}
                                onChange ={e => setCvvNumber(e.target.value)}
                                disabled = {loading}
                                onPaste={noPaste}
                                type='password'
                                autoComplete="off"
                                className = {
                                classes.marginTextFieldCvv
                                }
                                inputProps={{
                                maxLength: 3,
                                placeholder:"CVV",
                                /* inputMode:'tel' */
                                }}
                                
                            
                            />:
                        <TextField
                                error = {emptycedulaNumber}
                                variant="outlined"
                                margin = 'none'
                                color = "primary"
                                required
                                id="cedula"
                                size="small"
                                name="cedula"
                                onKeyPress={e=>validate(e)}
                                onChange ={e => setCedulaNumber(e.target.value)}
                                disabled = {loading}
                                onPaste={noPaste}
                                type='tel'
                                autoComplete="off"
                                className = {
                                classes.marginTextFieldCedula
                                }
                                inputProps={{
                                maxLength: 8,
                                placeholder:"CÉDULA"
                                }}
                            
                            />
                        }
                            
                            {
                              cardType==="credito" ?
                              <TextField
                                error = {emptycedulaNumber}
                                variant="outlined"
                                margin = 'none'
                                color = "primary"
                                required
                                id="cedula"
                                size="small"
                                name="cedula"
                                onKeyPress={e=>validate(e)}
                                onChange ={e => setCedulaNumber(e.target.value)}
                                disabled = {loading}
                                onPaste={noPaste}
                                type='tel'
                                autoComplete="off"
                                className = {
                                classes.marginTextFieldCardNumber
                                }
                                inputProps={{
                                maxLength: 8,
                                placeholder:"CÉDULA O PASAPORTE"
                                }}
                            
                            />:
                              <FormControl variant="outlined" className={classes.formControl}>
                                <Select
                                value={select}
                                error = {emptyselect}
                                onChange={handleChange}
                                displayEmpty
                                className={classes.select}
                                inputProps={{ "aria-label": "Without label" }}
                                >
                                    <MenuItem value="">
                                    <Typography style={{color:'grey'}}>TIPO DE CUENTA</Typography> 
                                    </MenuItem>
                                    <MenuItem value={"CA"}><Typography style={{color:'grey'}}>AHORRO</Typography> </MenuItem>
                                    <MenuItem value={"CC"}><Typography style={{color:'grey'}}>CORRIENTE</Typography> </MenuItem>
                                
                                </Select>
                              </FormControl>
                            }
                                
                           
                              <div className = {classes.recaptchaContainer}>
                                  <ReCAPTCHA
                                    sitekey= {CaptchSiteKey}
                                    onChange={onChange}
                                    className={classes.recaptcha}
                                  />  
                              </div>
                             
                         
                        </form>

                    <PayButton variant="contained" color="primary" startIcon ={loading?"":checkoutIcon} onClick = {handleClikPay} disabled = {loading}> {cardType==="credito" ? "Pagar" : "Continuar"} {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}</PayButton>
                    <Box mt={1} className={isDevice? classes.boxContainerFooterDevice:classes.boxContainerFooter}><Typography className={classes.footerText}>Ésta transacción será procesada por {isDevice? mercantilIconDevice:mercantilIcon}</Typography></Box>

                            
                   
                    </div>
                    </div>
                    </Slide>
        </React.Fragment>
    )
}


export default CheckoutComponent
